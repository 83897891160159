export const animate = (from: number, to: number, duration: number = 500, done?: () => void) => (
    perform: (value: number) => void,
) => {
    if (!window.requestAnimationFrame || !performance.now) {
        // on some browsers (Android 4.4) performance.now() does not exist
        perform(to);
        if (done) {
            done();
        }
        return;
    }

    const cosParameter = (from - to) / 2;
    let scrollCount = 0;
    let oldTimestamp = performance.now();

    const step = (newTimestamp: number) => {
        scrollCount += Math.PI / (duration / (newTimestamp - oldTimestamp));
        if (scrollCount >= Math.PI) {
            perform(to);
            if (done) {
                done();
            }
            return;
        }
        perform(Math.round(to + cosParameter + cosParameter * Math.cos(scrollCount)));
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    };

    window.requestAnimationFrame(step);
};
