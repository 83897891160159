import flatten from 'lodash-es/flatten';
import { reactFilter, TextLike } from 'mk/bazaar/common/filters/utils';
import { ACTIVE_DOMAIN_NAME } from 'mk2/helpers/urls';
import React from 'react';

const ALPHA_NUM = {};
'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('').forEach((c) => {
    ALPHA_NUM[c] = true;
});

const SIMPLE_EMAIL_RE = /^\S+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+$/;
const PUNCTUATION_RE = /^((?:\(|<|&lt;)*)(.*?)((?:\.|,|\)|>|\n|&gt;)*)$/;

export function isUrlWithProtocol(x: string): boolean {
    return (/^(?:h|H)ttp(s?):\/\//).test(x);
}

function isUrlLazy(x: string): boolean {
    return /www./.test(x) || (
        (x.length > 0) &&
        (x.indexOf('@') === -1) &&
        ALPHA_NUM[x.charAt(0)] &&
        /(\.org|\.net|\.com)$/.test(x)
    );
}

function isMailAddr(x: string): boolean {
    return (x.indexOf('@') !== -1) && (x.indexOf(':') === -1) && SIMPLE_EMAIL_RE.test(x);
}

function trimUrl(x: string, trimLimit: number) {
    if (trimLimit && (x.length > trimLimit)) {
        return x.substr(0, Math.max(0, trimLimit - 3)) + '...';
    } else {
        return x;
    }
}

interface UrlizeOpts {
    nofollow?: boolean;
    target?: string | boolean;
}

export const urlize = (text: TextLike, trimUrlLimit: number, opts?: UrlizeOpts) =>
    reactFilter(text, (s: string): React.ReactChild[] => {
    const {nofollow = true, target = true} = opts || {};

    if (!s) {
        // Skip ""
        return [];
    }

    const parts = s.match(/(\s+|\S+)/g);
    return flatten(parts.map((word) => {
        let match = null;

        let currentNofollow = nofollow;
        let currentTarget = target;
        let currentNoopener = true;

        if (/\.|@|:/.test(word)) {
            match = PUNCTUATION_RE.exec(word);
        }

        if (match) {
            const lead = match[1];
            let middle = match[2];
            const trail = match[3];

            let url = null;
            if (isUrlWithProtocol(middle) || isUrlLazy(middle)) {
                url = !isUrlWithProtocol(middle) ? 'http://' + middle : middle;
                let decodedUrl = null;
                try {
                    decodedUrl = decodeURI(url);
                    if (url === decodedUrl) {
                        url = encodeURI(url);
                    } else {
                        middle = decodeURI(middle);
                    }
                } catch (e) {
                    url = middle;
                }
            } else if (isMailAddr(middle)) {
                url = 'mailto:' + middle;
                currentNofollow = false;
            }

            if (url) {

                // check if internal url
                const domainRegExp = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/img;
                const activeDomainUrl = ACTIVE_DOMAIN_NAME;
                match = domainRegExp.exec(url);
                if (match && match[1].toLowerCase() === activeDomainUrl.toLowerCase()) {
                    currentNoopener = false;
                    currentTarget = false;
                }

                // make link
                const trimmed = trimUrl(middle, trimUrlLimit);
                middle = (
                    <a
                        href={url}
                        rel={`${currentNoopener ? 'noopener ' : ''}${currentNofollow ? 'nofollow ' : ''}`.trim()}
                        target={currentTarget === true ? '_blank' : (currentTarget ? currentTarget : null)}
                    >
                        {trimmed}
                    </a>
                );
                return [lead, middle, trail];
            }
        }

        return word;
    }));
});
