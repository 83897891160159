import { common_api_basic_redirect_to_eshop_url } from 'mk/urls/functions';
import { EshopProductWidgetSlot } from 'mk2/apps/groups/containers/EshopProducts/EshopProductWidget';
import { strollersAddReviewUrl } from 'mk2/apps/strollers/urls';
import { Link } from 'mk2/components/Link';
import { url } from 'mk2/helpers/urls';
import { StrollerBoxWidgetData } from 'mk2/schemas';
import React from 'react';
import { StrollerBox } from './StrollerBox';
import styles from './StrollerBoxWidget.mscss';


interface OwnProps {
    eshopSaleorProduct: StrollerBoxWidgetData;

    widgetPosition: number;
    slot: EshopProductWidgetSlot;
    pageId: number;
}

type Props = OwnProps;

const StrollerBoxWidget: React.FunctionComponent<Props> = ({
    eshopSaleorProduct,
    widgetPosition,
    slot,
    pageId,
}) => {

    if (!eshopSaleorProduct) {
        return null;
    }

    return (
        <StrollerBox
            className={styles.StrollerBoxWidget}
            title={
                <Link to={common_api_basic_redirect_to_eshop_url(
                    eshopSaleorProduct.slug, {
                        slot,
                        utm_camp: null,
                        is_eshop_product: eshopSaleorProduct.isEshopProduct,
                        epid: eshopSaleorProduct.productId,
                        epos: 0,
                        wpos: widgetPosition,
                        pid: pageId,
                        ptype: eshopSaleorProduct.productTypeId,
                    },
                )}>
                    {eshopSaleorProduct.title}
                </Link>
            }

            image={
                <Link to={
                    common_api_basic_redirect_to_eshop_url(
                        eshopSaleorProduct.slug, {
                            slot,
                            utm_camp: null,
                            // utm_camp: eshopSaleorProduct.utmCampaign,
                            is_eshop_product: eshopSaleorProduct.isEshopProduct,
                            epid: eshopSaleorProduct.productId,
                            epos: 0,
                            wpos: widgetPosition,
                            pid: pageId,
                            ptype: eshopSaleorProduct.productTypeId,
                        })}>
                    <div
                        className={styles.StrollerBoxWidget__imageCounselling}
                        style={{backgroundImage: `url(${eshopSaleorProduct.photoUrl})`}}
                    />
                </Link>
            }
            strollerSlug={eshopSaleorProduct.slug}
            reviewsCount={eshopSaleorProduct.reviewsCount}
            score={eshopSaleorProduct.reviewsStars}
            isEshopProduct={eshopSaleorProduct.isEshopProduct}
            productsTo={
                common_api_basic_redirect_to_eshop_url(
                    eshopSaleorProduct.slug, {
                        slot,
                        utm_camp: null,
                        // utm_camp: eshopSaleorProduct.utmCampaign,
                        is_eshop_product: eshopSaleorProduct.isEshopProduct,
                        epid: eshopSaleorProduct.productId,
                        epos: 0,
                        wpos: widgetPosition,
                        pid: pageId,
                        ptype: eshopSaleorProduct.productTypeId,
                    })
                }
            addReviewTo={url(strollersAddReviewUrl, { slug: eshopSaleorProduct.slug })}
        />
    );

};

StrollerBoxWidget.displayName = 'StrollerBoxWidget';

export default React.memo<Props>(StrollerBoxWidget);
