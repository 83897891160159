import cx from 'classnames';
import { photoUrl } from 'mk/photo/photoUrl';
import { Img } from 'mk2/components/Img';
import { PhotoEntity } from 'mk2/schemas';
import React from 'react';
import styles from './ImgVarWidth.mscss';

interface OwnPropsShared {
    className?: string;

    // height the image, should be scaled to.
    height: number;

    // if true, image can be upscaled
    // when props.photo.height is smaller than props.height
    upscale?: boolean;

    alt?: string;
}

type OwnPropsWithPhoto = OwnPropsShared & {
    photo: PhotoEntity;
    photoVersion: string;

    src?: never;
    imgWidth?: never;
    imgHeight?: never;
};

type OwnPropsWithSrc = OwnPropsShared & {
    src: string;

    // dimension of the image (jpg) passed as src
    imgWidth: number;
    imgHeight: number;

    photo?: never;
    photoVersion?: never;
};

/**
 * Scales images into the box of size width x height. Height is given as parameter. Keeping aspect ratio, width
 * is computed from image dimensions.
 */
export class ImgVarWidth extends React.Component<OwnPropsWithPhoto | OwnPropsWithSrc> {
    public render() {
        const { className, upscale, alt } = this.props;

        let src: string;
        let height: number;
        let width: number;

        if ('photo' in this.props) {
            src = photoUrl(this.props.photo, this.props.photoVersion);
            height = upscale ? this.props.height : Math.min(this.props.height, this.props.photo.height);
            width = (this.props.photo.width * height) / this.props.photo.height;
        } else {
            src = this.props.src;
            height = upscale ? this.props.height : Math.min(this.props.height, this.props.imgHeight);
            width = (this.props.imgWidth * height) / this.props.imgHeight;
        }

        return (
            <Img
                src={src}
                height={height}
                width={width}
                className={cx(styles.ImgVarWidth, className)}
                alt={alt}
            />
        );
    }
}
