import React from 'react';
import styles from './WidgetPlaceholder.mscss';

interface OwnProps {}

type Props = OwnProps;

const WidgetPlaceholder: React.FunctionComponent<Props> = ({

}) => (
    <div className={styles.WidgetPlaceholder}/>
);


WidgetPlaceholder.displayName = 'WidgetPlaceholder';

export default React.memo<Props>(WidgetPlaceholder);
