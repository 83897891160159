import {
    COMPLAINT_USERS_PROFILE_AVATAR_NOT_ALONE,
    COMPLAINT_USERS_PROFILE_AVATAR_NOT_VISIBLE_FACE,
    COMPLAINT_USERS_PROFILE_AVATAR_NOT_YOU,
    SINCE,
    THE_AVATAR_HAS_BEEN_BLOCKED_BY_ADMINS_IT_BREACHES_THE_RULES,
} from 'mk/autogenerated/translations/AvatarBlockedBox.e080a892938a838eebdd1d97df489472'
import { ProfileEntity } from 'mk2/apps/settings/schemas';
import { dateAt } from 'mk2/helpers/timeformat';
import React from 'react';
import styles from './AvatarBlockedBox.mscss';

interface OwnProps {
    profile: ProfileEntity;
}

const blockReasonDetail = {
    58: COMPLAINT_USERS_PROFILE_AVATAR_NOT_YOU,
    59: COMPLAINT_USERS_PROFILE_AVATAR_NOT_VISIBLE_FACE,
    60: COMPLAINT_USERS_PROFILE_AVATAR_NOT_ALONE,
};

type Props = OwnProps;

const AvatarBlockedBox = React.memo<Props>(
    ({ profile }) => (
        <div className={styles.AvatarBlockedBox}>
            <div className={styles.AvatarBlockedBox__header}>
                <span className={styles.AvatarBlockedBox__title}>{THE_AVATAR_HAS_BEEN_BLOCKED_BY_ADMINS_IT_BREACHES_THE_RULES}</span>
                <span className={styles.AvatarBlockedBox__time}>{SINCE + ' ' + dateAt(profile.blockedAvatarTime)}</span>
            </div>
            <div className={styles.AvatarBlockedBox__explanation}>
                <span className={styles.AvatarBlockedBox__reason}>{blockReasonDetail[profile.blockedAvatarReason]}</span>
                <span className={styles.AvatarBlockedBox__comment}>{profile.blockedAvatarComment}</span>
            </div>
        </div>
    ),
);

AvatarBlockedBox.displayName = 'AvatarBlockedBox';

export default AvatarBlockedBox;
