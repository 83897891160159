import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import Breadcrumb from 'mk/bazaar/common/components/Breadcrumb';
import React from 'react';
import styles from './BreadcrumbSiteStructure.mscss';

interface OwnProps {
    className?: string;
    items: React.ReactNode[];
}

export class BreadcrumbSiteStructure extends React.Component<OwnProps> {

    public render() {
        const { className, items } = this.props;

        return (
            <Breadcrumb
                className={cx(styles.BreadcrumbSiteStructure, className)}
                separator={(
                    <FontAwesomeIcon icon={faAngleRight} className={styles.BreadcrumbSiteStructure__separator} />
                )}
                items={items}
            />
        );
    }
}
