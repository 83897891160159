import { _404_TITLE } from 'mk/autogenerated/translations/NotFoundError.d5ab1a12336d87927cabd593d26102d2'
import assetUrl from 'mk/common/assetUrl';
import { Img } from 'mk2/components/Img';
import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './NotFoundError.mscss';

interface OwnProps {
    message?: string;
    button?: React.ReactNode;
}

export const NotFoundError: React.StatelessComponent<OwnProps> = ({ message, button }) => {
    const title = message || _404_TITLE;

    return (
        <div className={styles.NotFoundError}>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
            </Helmet>

            <div className={styles.NotFoundError__icon}>
                <Img className={styles.NotFoundError__icon__image} src={assetUrl + 'img/miky/shrug-miky@2x.png'} width={268} height={271} />
            </div>
            <div className={styles.NotFoundError__message}>
                {title}
            </div>
            {button && (
                <div className={styles.NotFoundError__button}>
                    {button}
                </div>
            )}
        </div>
    );
};
