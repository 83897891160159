import upperFirst from 'lodash-es/upperFirst';
import { reactFilter, TextLike } from 'mk/bazaar/common/filters/utils';
import React from 'react';

export { boldize } from 'mk/bazaar/common/filters/boldize';
export { mentionize } from 'mk/bazaar/common/filters/mentionize';
export { urlize } from 'mk/bazaar/common/filters/urlize';
export { wpLinkize } from 'mk/bazaar/common/filters/wpLinkize';

type ReactChild = React.ReactChild;

/**
 * Convert all \n chars to <br/> elements
 *
 * @param text
 */
export const linebreaksbr = (text: TextLike) => reactFilter(text, (s: string): ReactChild[] => {
    const lines = s.split(/\r\n|\n|\r/);
    // add <br> between lines
    return lines.reduce((ret, line, idx) => {
        if (idx > 0) {
            ret.push(<br />);
        }
        ret.push(line);
        return ret;
    }, []);
});

const TRIM_RE = /^\s+|\s+$/g;

/**
 * Trims whitespace at beginning and end.
 *
 * @param text
 */
export const trim = (text: TextLike) => reactFilter(text, (s: string): string[] => {
    return [s.replace(TRIM_RE, '')];
});

export const upperCaseFirst = (text: TextLike) => reactFilter(text, (s: string): ReactChild[] => {
    return [upperFirst(s)];
});
