// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const EMAIL = "Email";
export const FORMS_DEFAULT_REQUIRED_FIELD_ERROR = "Toto pole je povinn\u00e9.";
export const NEWSLETTER_SUBSCRIBE = "P\u0159ihla\u0161uji se k odb\u011bru";
export const number_WEEKS = ["%(number)s t\u00fdden", "%(number)s t\u00fddny", "%(number)s t\u00fddn\u016f"];
export const PREGNANCY_NEWSLETTER_NOTE_FIRST_WEEKS = "Pokud jsi v 1. \u2013 4. t\u00fddnu, ulo\u017e\u00edme si t\u011b a prvn\u00ed e-mail ti p\u0159ijde a\u017e v 5. t\u00fddnu. Na\u0161e t\u011bhotensk\u00e9 newslettery za\u010d\u00ednaj\u00ed toti\u017e a\u017e 5. t\u00fddnem. ";
export const SIGNUP_DUE_DATE = "Term\u00edn porodu";
export const WHAT_WEEK_OF_PREGNANCY_ARE_YOU = "V jak\u00e9m jsi t\u00fddnu t\u011bhotenstv\u00ed?";
export const YOUR_REGISTRATION_EMAIL = "Tv\u016fj registra\u010dn\u00ed e-mail";
