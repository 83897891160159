import cx from 'classnames';
import { FormControl } from 'mk2/components/forms/FormControl';
import { Radio } from 'mk2/components/forms/Radio';
import { isSameValue, valueStr } from 'mk2/components/forms/SelectField';
import React from 'react';
import { Field, WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';
import styles from './RadioField.mscss';

export interface RadioOption {
    value: string | number;
    label: React.ReactNode;
}

interface RadioInputPublicProps {
    options: RadioOption[];
    horizontal?: boolean;
    disabled?: boolean;
}

type RadioInputProps = RadioInputPublicProps & WrappedFieldProps & {
    hasLabel: boolean;
};

const RadioInput: React.FC<RadioInputProps> = ({
    options, input, meta, hasLabel, horizontal, disabled,
}) => (
    <div className={cx(styles.RadioInput, horizontal && styles['RadioInput--horizontal'])}>
        {options.map((option, idx) => {
            const htmlId = `${meta.form}-${input.name}-${valueStr(option.value)}`;
            return (
                <Radio
                    className={
                        !horizontal
                            ? cx(
                                  styles.RadioInput__option,
                                  idx === 0 && hasLabel && styles['RadioInput__option--isFirst'],
                                  idx === 0 && !hasLabel && styles['RadioInput__option--isFirstNoLabel'],
                                  idx === options.length - 1 && styles['RadioInput__option--isLast'],
                              )
                            : cx(styles.RadioInput__option, styles['RadioInput__option--horizontal'])
                    }
                    key={htmlId}
                    id={htmlId}
                    label={option.label}
                    disabled={disabled}
                    {...input}
                    checked={isSameValue(input.value, option.value)}
                    value={valueStr(option.value)}
                />
            );
        })}
    </div>
);

RadioInput.displayName = 'RadioInput';

interface RadioControlPublicProps {
    label?: string;
    bottomBorder?: boolean;
    marginLeft?: boolean;
    transparent?: boolean;
}

type RadioControlProps = RadioInputPublicProps & RadioControlPublicProps & WrappedFieldProps;

const RadioOptionsControl: React.StatelessComponent<RadioControlProps> = ({
    input,
    label,
    meta, // WrappedFieldProps
    bottomBorder,
    marginLeft,
    transparent, // RadioControlPublicProps
    ...radioInputProps // RadioInputPublicProps
}) => (
    <FormControl
        label={label}
        bottomBorder={bottomBorder}
        marginLeft={marginLeft}
        input={input}
        meta={meta}
        minimizedLabel={true /* label is always minimized */ /* tslint:disable-line */}
        transparent={transparent}
    >
        <RadioInput {...radioInputProps} hasLabel={!!label} input={input} meta={meta} />
    </FormControl>
);

type RadioFieldProps = RadioInputPublicProps &
    RadioControlPublicProps & {
        // see https://redux-form.com/7.3.0/docs/api/field.md/#props-you-can-pass-to-code-field-code-
        name: string;
    };

export class RadioField extends React.Component<RadioFieldProps> {
    public render() {
        return <Field {...this.props} component={RadioOptionsControl} normalize={this.handleNormalize} />;
    }

    private handleNormalize = (value) => {
        const { options } = this.props;

        if (!options || !options.length) {
            return value;
        }

        // radio <input> ma hodnoty iba ako string. Najdi preto RadioOption, ktora je vybrata
        // a vrat jej value (moze to byt napr. cislo)
        const selected: RadioOption = options.find((o) => isSameValue(o.value, value));
        return selected ? selected.value : value;
    };
}
