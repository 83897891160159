import cx from 'classnames';
import { Location } from 'history';
import {
    ABOUT_HEADER_ABOUT_PRIVACY_PAGE,
    PREGNANCY_NEWSLETTER_LINK_TO_PREGNANCY_CALCULATOR,
    PREGNANCY_NEWSLETTER_LINK_TO_PREGNANCY_CALCULATOR_01,
    PREGNANCY_NEWSLETTER_LINK_TO_PREGNANCY_CALCULATOR_AUTH,
    PREGNANCY_NEWSLETTER_LINK_TO_PREGNANCY_CALCULATOR_AUTH_01,
    VIBER_PREGNANCY_WEEKS_MENU_ALREADY_SUBSCRIBED_MESSAGE,
    YOU_SUBSCRIBED_TO_PREGNANCY_BY_WEEKS,
} from 'mk/autogenerated/translations/PregnancyNewsletter.36ebadc40b409bfc0a3953a5d5f60a2b'
import { canEditWiki, isAuthenticated } from 'mk/bazaar/common/userUtils';
import { ACTIVE_SERVER_ID, BASE_URL } from 'mk/settings';
import { analyticsStoreEvent } from 'mk2/actions';
import { aboutPrivacyPolicyUrl } from 'mk2/apps/about/urls';
import { groupsGroupUrl } from 'mk2/apps/groups/urls';
import { profileFetchTrigger } from 'mk2/apps/settings/containers/Profile/Profile.actions';
import profilesReducer from 'mk2/apps/settings/containers/Profile/Profile.reducers';
import profilesSaga from 'mk2/apps/settings/containers/Profile/Profile.sagas';
import { getProfileState } from 'mk2/apps/settings/containers/Profile/Profile.selectors';
import { signupUrl } from 'mk2/apps/users/urls';
import { getPregnancyNewsletterSubscribedCookie, gtmEvent } from 'mk2/apps/users/utils';
import { pregnancyCalculatorUrl } from 'mk2/apps/wiki/urls';
import { Link } from 'mk2/components/Link';
import {
    generateFormName,
    PregnancyNewsletterFormData,
    PregnancyNewsletterFormFactory,
} from 'mk2/components/PregnancyNewsletterForm';
import { LoadingSwitch } from 'mk2/containers/LoadingSwitch/LoadingSwitch';
import { newsletterSubscriberCreateTrigger } from 'mk2/containers/PregnancyNewsletter/PregnancyNewsletter.actions';
import pregnancyNewsletterReducer, { getSubmitted } from 'mk2/containers/PregnancyNewsletter/PregnancyNewsletter.reducers';
import pregnancyNewsletterSaga from 'mk2/containers/PregnancyNewsletter/PregnancyNewsletter.sagas';
import registerInStore from 'mk2/decorators/registerInStore';
import { cachedInitialValues } from 'mk2/helpers/form';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { removeEmpty, url } from 'mk2/helpers/urls';
import { getRequestPermissions, getRequestUser, AppState } from 'mk2/reducers';
import { LoadingState } from 'mk2/reducers/loadingState';
import { ProfileEntity, ProfileSchema, UserEntity, UtmQuery } from 'mk2/schemas';
import { getDenormalizedEntity, getRoutingLocation } from 'mk2/selectors';
import { HistoryLocationState } from 'mk2/services/browserHistory';
import { parse, ParsedQuery } from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import styles from './PregnancyNewsletter.mscss';

interface OwnProps {
    initialPregnancyDueDate?: string;
    initialPregnancyWeek?: number;
    formNameKey: string;
    noBorder?: boolean;
    noPregnancyCalculatorLink?: boolean;
    signupProps?: ParsedQuery;
    extraEventProps?: ParsedQuery;
}

interface StateProps {
    requestUser: UserEntity;
    requestUserPermissions: string[];
    query: UtmQuery;
    profile: ProfileEntity;
    location: Location;
    initialValues: PregnancyNewsletterFormData;
    currentValues: PregnancyNewsletterFormData;
    loadingState: LoadingState;
    subscribed: boolean; // true till pregnancy due date + 4 months
    justSubmitted: boolean;
}

interface DispatchProps {
    onLoad(username: string);
    onSave(formName: string, values: PregnancyNewsletterFormData, historyLocationState: HistoryLocationState, signupProps: ParsedQuery, extraEventProps: ParsedQuery);
    onStoreEvent(name: string, props: any);
}

type Props = OwnProps & StateProps & DispatchProps;

class PregnancyNewsletter extends React.Component<Props> {

    private PregnancyNewsletterForm = PregnancyNewsletterFormFactory(generateFormName(this.props.formNameKey));

    public componentDidMount() {
        this.trackView();
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.formNameKey !== this.props.formNameKey) {
            this.trackView();
        }
    }

    public render() {
        const { loadingState, profile, requestUser, justSubmitted, subscribed, noBorder, noPregnancyCalculatorLink } = this.props;

        const linkToPregnancyCalculator01: string = isAuthenticated(requestUser)
            ? PREGNANCY_NEWSLETTER_LINK_TO_PREGNANCY_CALCULATOR_AUTH_01
            : PREGNANCY_NEWSLETTER_LINK_TO_PREGNANCY_CALCULATOR_01;
        const linkToPregnancyCalculator02: string = isAuthenticated(requestUser)
            ? PREGNANCY_NEWSLETTER_LINK_TO_PREGNANCY_CALCULATOR_AUTH
            : PREGNANCY_NEWSLETTER_LINK_TO_PREGNANCY_CALCULATOR;

        return (
            <div className={cx(styles.PregnancyNewsletter, !noBorder && styles.PregnancyNewsletter__withBorder)}>
                {!subscribed && !justSubmitted ? (
                    <div className={styles.PregnancyNewsletter__form}>
                        {isAuthenticated(requestUser) ? (
                            <LoadingSwitch
                                loadingState={loadingState}
                                onLoad={this.handleLoadProfile}
                                onRenderSuccess={this.renderSuccess}
                                hasData={Boolean(profile)}
                            />
                        ) : this.renderSuccess()}
                    </div>
                ) : justSubmitted ? (
                    <p className={styles.PregnancyNewsletter__submitted}>
                        {YOU_SUBSCRIBED_TO_PREGNANCY_BY_WEEKS}
                    </p>
                ) : subscribed /*
                    TODO: what if user is subscribed, but childbirth is already done, and user is pregnant again?
                */ ? (
                    <>
                        <p className={styles.PregnancyNewsletter__subscribed}>
                            {VIBER_PREGNANCY_WEEKS_MENU_ALREADY_SUBSCRIBED_MESSAGE}
                        </p>
                        {!noPregnancyCalculatorLink && (
                            <div className={styles.PregnancyNewsletter__linkToPregCalc}>
                                {linkToPregnancyCalculator01}
                                {' '}
                                <Link to={pregnancyCalculatorUrl}>
                                    {linkToPregnancyCalculator02}
                                </Link>
                            </div>
                        )}
                    </>
                ) : null}
            </div>
        );
    }

    private handleLoadProfile = () => {
        const { onLoad, requestUser, profile } = this.props;
        if (isAuthenticated(requestUser) && !profile) {
            onLoad(requestUser.username);
        }
    };

    private renderSuccess = () => {
        const {
            initialValues,
            currentValues,
            requestUser,
            requestUserPermissions,
            noPregnancyCalculatorLink,
            signupProps,
        } = this.props;

        const PregnancyNewsletterForm = this.PregnancyNewsletterForm;

        const linkToPregnancyCalculator01: string = isAuthenticated(requestUser)
            ? PREGNANCY_NEWSLETTER_LINK_TO_PREGNANCY_CALCULATOR_AUTH_01
            : PREGNANCY_NEWSLETTER_LINK_TO_PREGNANCY_CALCULATOR_01;
        const linkToPregnancyCalculator02: string = isAuthenticated(requestUser)
            ? PREGNANCY_NEWSLETTER_LINK_TO_PREGNANCY_CALCULATOR_AUTH
            : PREGNANCY_NEWSLETTER_LINK_TO_PREGNANCY_CALCULATOR;

        return (
            <>
                <PregnancyNewsletterForm
                    user={requestUser}
                    onSave={this.handleOnSave}
                    initialValues={initialValues}
                    currentValues={currentValues}
                    enableReinitialize
                />
                {!noPregnancyCalculatorLink && (
                    <div className={styles.PregnancyNewsletter__linkToPregCalc}>
                        <div>
                            <Link className={styles.PregnancyNewsletter__linkToDataPrivacy}
                                  to={aboutPrivacyPolicyUrl}>{ABOUT_HEADER_ABOUT_PRIVACY_PAGE}
                            </Link>
                        </div>
                        {linkToPregnancyCalculator01}
                        {' '}
                        <Link to={pregnancyCalculatorUrl}>
                            {linkToPregnancyCalculator02}
                        </Link>
                    </div>
                )}
                {canEditWiki(requestUserPermissions) && (
                    <div className={styles.PregnancyNewsletter__linkAdmin}>
                        <Link
                            className={styles.PregnancyNewsletter__linkAdmin}
                            to={BASE_URL + url(signupUrl, {}, {
                                ci: 'def', // is used to load content items with the 'def'(ault) key (registration-def-top / registration-def-bottom)
                                ...removeEmpty(signupProps),
                                next: url(groupsGroupUrl, { groupId: ACTIVE_SERVER_ID === 201 ? 12151 : 8880 }),
                                group_name: ACTIVE_SERVER_ID === 201 ? 'Decembrovky 2022' : 'Prosincovky 2022',
                                group_active_members_count: 27,
                                email: 'testuser@test.com',
                                dueDate: new Date(Date.now() + 7776000000).toISOString().slice(0, 10), // +90 days
                                pregnancy_week: '12',
                            })}
                        >
                            {'Test signup url (with dummy data). Open Link in Incognito Window'}
                        </Link>
                    </div>
                )}
            </>
        );
    };

    private handleOnSave = (values: PregnancyNewsletterFormData) => {
        const { location, query, formNameKey, signupProps, extraEventProps, onSave, onStoreEvent } = this.props;
        onStoreEvent(
            'pregnancy_newsletter_subscribe_form_submitted',
            {
                url: location.pathname,
                url_full: `${location.pathname}${location.search}${location.hash}`,
                utm_campaign: query.utm_campaign ?? query.mkutm_campaign,
                utm_medium: query.utm_medium ?? query.mkutm_medium,
                utm_source: query.utm_source ?? query.mkutm_source,
                utm_content: query.utm_content ?? query.mkutm_content,
            },
        );
        onSave(generateFormName(formNameKey), values, location.state, signupProps, extraEventProps);
    };

    private trackView = () => {
        const { location, query, subscribed, onStoreEvent } = this.props;
        gtmEvent('event_pregnancy_newsletter_subscribe_form_displayed');
        onStoreEvent(
            'pregnancy_newsletter_subscribe_form_displayed',
            {
                subscribed,
                url: location.pathname,
                url_full: `${location.pathname}${location.search}${location.hash}`,
                utm_campaign: query.utm_campaign ?? query.mkutm_campaign,
                utm_medium: query.utm_medium ?? query.mkutm_medium,
                utm_source: query.utm_source ?? query.mkutm_source,
                utm_content: query.utm_content ?? query.mkutm_content,
            },
        );
    };
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    const { initialPregnancyDueDate, initialPregnancyWeek, formNameKey } = ownProps;
    const requestUser = getRequestUser(state);
    let profileState;
    let profile: ProfileEntity;
    if (isAuthenticated(requestUser)) {
        profileState = getProfileState(state, requestUser.username);
        profile = getDenormalizedEntity<ProfileEntity>(state, ProfileSchema, profileState.id);
    }

    const pregnancyNewsletterIsHiddenBasedOnCookies = getPregnancyNewsletterSubscribedCookie();
    const pregnancyNewsletterIsHiddenBasedOnProfile = profile?.pregnancyNewsletterIsHidden;
    const justSubmitted = getSubmitted(state.containers.global.pregnancyNewsletter);
    const formName = generateFormName(formNameKey);
    const location = getRoutingLocation(state);
    return {
        requestUser,
        requestUserPermissions: getRequestPermissions(state),
        profile,
        location,
        query: parse(location.search),
        loadingState: profileState?.loadingState,
        initialValues: cachedInitialValues(
            formName,
            {
                pregnancyDueDate: initialPregnancyDueDate || profile?.dueDate,
                pregnancyWeek: initialPregnancyWeek || (!profile ? 5 : null),
                email: profile?.email,
            },
            profile, initialPregnancyDueDate, initialPregnancyWeek,
        ),
        currentValues: getFormValues(formName)(state) as PregnancyNewsletterFormData,
        subscribed: isAuthenticated(requestUser)
            ? pregnancyNewsletterIsHiddenBasedOnProfile
            : pregnancyNewsletterIsHiddenBasedOnCookies,
        justSubmitted,
    };
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
    onLoad: profileFetchTrigger,
    onSave: newsletterSubscriberCreateTrigger,
    onStoreEvent: analyticsStoreEvent,
};

export default registerInStore<OwnProps>((store) => {
    store.injectReducer('global.pregnancyNewsletter', pregnancyNewsletterReducer);
    store.injectSaga('global.pregnancyNewsletter', pregnancyNewsletterSaga);
    store.injectReducer('containers.settings.settingsProfile', profilesReducer);
    store.injectSaga('containers.settings.settingsProfile', profilesSaga);
})(connect(mapStateToProps, mapDispatchToProps)(PregnancyNewsletter));
