import { faCommentDots } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import range from 'lodash-es/range';
import truncate from 'lodash-es/truncate';
import { upperCaseFirst } from 'mk/bazaar/common/filters';
import { postDetailUrl } from 'mk2/apps/forum/helpers';
import { PostEntity } from 'mk2/apps/forum/schemas';
import { GrowPriority, LinkListItem, LinkListItemPlaceholder } from 'mk2/components/LinkListItem';
import { Panel } from 'mk2/components/Panel';
import { PanelHeading } from 'mk2/components/PanelHeading';
import { TrackClick } from 'mk2/components/TrackClick';
import React from 'react';
import styles from './MorePosts.mscss';

interface OwnProps {
    className?: string;
    posts: PostEntity[];
    title: string;
    showComments?: boolean;
    trackEventProps?: Record<string, string>;
}

type Props = OwnProps;

const MorePosts: React.FunctionComponent<Props> = ({
    className,
    posts,
    title,
    showComments = true,
    trackEventProps = null,
}) =>
    posts && posts.length > 0 ? (
        <Panel className={className}>
            <PanelHeading title={title} />
            <ul className={styles.MorePosts__posts}>
                {posts.map((post, idx) => eventualTrackClick(trackEventProps, post.id, postDetailUrl(post),
                    <LinkListItem
                        key={post.id}
                        to={postDetailUrl(post)}
                        title={upperCaseFirst(truncate(post.heading, { length: 80 }))}
                        priority={GrowPriority.subtitle}
                    >
                        {showComments && (
                            <>
                                <FontAwesomeIcon icon={faCommentDots} />
                                {post.comments}
                            </>
                        )}
                    </LinkListItem>,
                ))}
            </ul>
        </Panel>
    ) : null;

const eventualTrackClick = (trackEvent, key, link, children) => {
    return trackEvent ? (
        <TrackClick
            name={trackEvent.name}
            props={{
                ...trackEvent.props,
                link,
            }}
            key={key}
        >
            {children}
        </TrackClick>
    ) : (
        <React.Fragment key={key}>{children}</React.Fragment>
    );
};

MorePosts.displayName = 'MorePosts';

export { MorePosts };

interface PlaceholderOwnProps {
    className?: string;
    title: string | React.ReactNode;
}

export const MorePostsPlaceholder = React.memo<PlaceholderOwnProps>(({ className, title }) => (
    <Panel className={className}>
        <PanelHeading title={title} />

        <ul className={styles.MorePosts__posts}>
            {range(6).map((idx) => (
                <LinkListItemPlaceholder key={idx} width={`${[90, 85, 80, 70, 75, 85][idx % 6]}%`} />
            ))}
        </ul>
    </Panel>
));
