import React from 'react';

const bull = '\u2022';
const nbsp = '\u00A0';

interface OwnProps {
    className?: string;
    spaces?: number;
}

type Props = OwnProps;

export const Dot: React.FunctionComponent<Props> = ({ className, spaces = 2 }) => (
    <span className={className}>
        {nbsp.repeat(spaces)}{bull}{nbsp.repeat(spaces)}
    </span>
);

Dot.displayName = 'Dot';
