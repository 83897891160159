import { ChartFormData } from 'mk2/apps/dash/containers/SeoPerformance/PerformanceChart';
import { Query } from 'mk2/apps/dash/containers/SeoPerformance/SeoPerformancePage';
import { CheckboxField } from 'mk2/components/forms/CheckboxField';
import { InputField } from 'mk2/components/forms/InputField';
import { RadioField, RadioOption } from 'mk2/components/forms/RadioField';
import { Option, SelectField } from 'mk2/components/forms/SelectField';
import { SubmitButton } from 'mk2/components/forms/SubmitButton';
import { TextareaField } from 'mk2/components/forms/TextareaField';
import { Form } from 'mk2/components/Form';
import { Panel } from 'mk2/components/Panel';
import { mkReduxForm, MKReduxFormInjectedProps } from 'mk2/decorators/mkReduxForm';
import { YMDLocal } from 'mk2/helpers/timeformat';
import React from 'react';
import styles from './FilterForm.mscss';

export const SEO_PERFORMANCE_FILTER_FORM_NAME = 'SEO_PERFORMANCE_FILTER_FORM_NAME';

export interface FilterFormData {
    range1: DateRange1;
    dfrom1: string;
    dto1: string;

    range2: DateRange2;
    dfrom2: string;
    dto2: string;

    filterUrls: boolean;
    urlFilterType: UrlFilterType;
    urls: string;

    filterKeywords: boolean;
    keywordFilterType: KeywordFilterType;
    keywords: string;
}

export enum DateRange1 {
    Custom,
    Past28Days,
    Past12Weeks,
    Past26Weeks,
    Past52Weeks,
    LastMonth,
    Last6Months,
    Last12Months,
}

export enum DateRange2 {
    Custom,
    PreviousPeriod,
    PreviousYear,
}

export enum UrlFilterType {
    Custom,
    Wiki,
    Forum,
    Strollers,
    Market,
    Counselling,
}

export enum KeywordFilterType {
    Custom,
    Branded,
    NonBranded,
}

interface OwnProps {
    initialValues: FilterFormData;
    disabledDates1: boolean;
    disabledDates2: boolean;
    hideUrlsFilter: boolean;
    hideUrlsList: boolean;
    hideKeywordsFilter: boolean;
    hideKeywordsList: boolean;

    onApply(values: FilterFormData, cd: ChartFormData);
}

type Props = OwnProps;

export class FilterFormComponent extends React.Component<Props & MKReduxFormInjectedProps<FilterFormData, Props>> {

    public static initialValues(query: Query): FilterFormData {
        // ensure date ranges are correct
        const range1 = DateRange1[query.range1];
        const today = new Date();
        let dfrom1: Date;
        let dto1: Date;
        switch (range1) {
            case DateRange1.Last12Months:
                dfrom1 = new Date(today.getFullYear(), today.getMonth() - 12, 1);
                dto1 = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            case DateRange1.Last6Months:
                dfrom1 = new Date(today.getFullYear(), today.getMonth() - 6, 1);
                dto1 = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            case DateRange1.LastMonth:
                dfrom1 = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                dto1 = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            case DateRange1.Past28Days:
                dfrom1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 28);
                dto1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
                break;
            case DateRange1.Past12Weeks:
                dfrom1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 12 * 7);
                dto1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
                break;
            case DateRange1.Past26Weeks:
                dfrom1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 26 * 7);
                dto1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
                break;
            case DateRange1.Past52Weeks:
                dfrom1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 52 * 7);
                dto1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
                break;
            case DateRange1.Custom:
                dfrom1 = query.dfrom1 ? new Date(Date.parse(query.dfrom1)) : null;
                dto1 = query.dto1 ? new Date(Date.parse(query.dto1)) : null;
                break;
        }

        const range2 = DateRange2[query.range2];
        let dfrom2: Date;
        let dto2: Date;
        if (dfrom1 && dto1) {
            switch (range2) {
                case DateRange2.PreviousPeriod: {
                    const diffms = dto1.valueOf() - dfrom1.valueOf();
                    dfrom2 = new Date(dfrom1.getFullYear(), dfrom1.getMonth(), dfrom1.getDate() - 1, 0, 0, 0, -diffms);
                    dto2 = new Date(dfrom1.getFullYear(), dfrom1.getMonth(), dfrom1.getDate() - 1);
                    break;
                }
                case DateRange2.PreviousYear: {
                    const diffms = dto1.valueOf() - dfrom1.valueOf();
                    dfrom2 = new Date(dfrom1.getFullYear() - 1, dfrom1.getMonth(), dfrom1.getDate());
                    dto2 = new Date(dfrom2.getFullYear(), dfrom2.getMonth(), dfrom1.getDate(), 0, 0, 0, diffms);
                    break;
                }
                case DateRange2.Custom:
                    dfrom2 = query.dfrom2 ? new Date(Date.parse(query.dfrom2)) : null;
                    dto2 = query.dto2 ? new Date(Date.parse(query.dto2)) : null;
                    break;
            }
        }

        let filterUrls: boolean;
        let urlFilterType: UrlFilterType;
        let urls: string;
        if (query.urls === UrlFilterType[UrlFilterType.Forum]
            || query.urls === UrlFilterType[UrlFilterType.Wiki]
            || query.urls === UrlFilterType[UrlFilterType.Strollers]
            || query.urls === UrlFilterType[UrlFilterType.Market]
            || query.urls === UrlFilterType[UrlFilterType.Counselling]
        ) {
            filterUrls = true;
            urlFilterType = +UrlFilterType[query.urls];
            urls = null;
        } else if (query.urls) {
            filterUrls = true;
            urlFilterType = UrlFilterType.Custom;
            urls = query.urls;
        } else {
            filterUrls = false;
            urlFilterType = UrlFilterType.Wiki;
            urls = null;
        }

        let filterKeywords: boolean;
        let keywordFilterType: KeywordFilterType;
        let keywords: string;
        if (query.keywords === KeywordFilterType[KeywordFilterType.NonBranded]
            || query.keywords === KeywordFilterType[KeywordFilterType.Branded]
        ) {
            filterKeywords = true;
            keywordFilterType = +KeywordFilterType[query.keywords];
            keywords = null;
        } else if (query.keywords) {
            filterKeywords = true;
            keywordFilterType = KeywordFilterType.Custom;
            keywords = query.keywords;
        } else {
            filterKeywords = false;
            keywordFilterType = KeywordFilterType.NonBranded;
            keywords = null;
        }

        return {
            range1,
            dfrom1: dfrom1 ? YMDLocal(dfrom1) : null,
            dto1: dto1 ? YMDLocal(dto1) : null,

            range2,
            dfrom2: dfrom2 ? YMDLocal(dfrom2) : null,
            dto2: dto2 ? YMDLocal(dto2) : null,

            filterUrls,
            urlFilterType,
            urls,

            filterKeywords,
            keywordFilterType,
            keywords,
        };
    }

    private date1Ranges: Option[] = [
        { value: DateRange1.Past28Days, label: 'Past 28 days' },
        { value: DateRange1.Past12Weeks, label: 'Past 12 weeks' },
        { value: DateRange1.Past26Weeks, label: 'Past 26 weeks' },
        { value: DateRange1.Past52Weeks, label: 'Past 52 weeks' },
        { value: DateRange1.LastMonth, label: 'Last month' },
        { value: DateRange1.Last6Months, label: 'Last 6 months' },
        { value: DateRange1.Last12Months, label: 'Last 12 months' },
        { value: DateRange1.Custom, label: 'Custom' },
    ];

    private date2Ranges: Option[] = [
        { value: DateRange2.PreviousPeriod, label: 'Previous period' },
        { value: DateRange2.PreviousYear, label: 'Previous year' },
        { value: DateRange2.Custom, label: 'Custom' },
    ];

    private urlsOptions: RadioOption[] = [
        { value: UrlFilterType.Wiki, label: 'Wiki' },
        { value: UrlFilterType.Forum, label: 'Forum' },
        { value: UrlFilterType.Strollers, label: 'Strollers' },
        { value: UrlFilterType.Market, label: 'Market' },
        { value: UrlFilterType.Counselling, label: 'Counselling' },
        { value: UrlFilterType.Custom, label: 'Custom' },
    ];

    private keywordsOptions: RadioOption[] = [
        { value: KeywordFilterType.NonBranded, label: 'All non branded' },
        { value: KeywordFilterType.Branded, label: 'Only branded' },
        { value: KeywordFilterType.Custom, label: 'Custom' },
    ];

    public render() {
        const {
            handleSubmit,
            disabledDates1, disabledDates2,
            hideUrlsFilter, hideUrlsList, hideKeywordsFilter, hideKeywordsList,
        } = this.props;

        return (
            <Panel>
                <Form className={styles.FilterForm} onSubmit={handleSubmit}>
                    <div className={styles.FilterForm__hgroup}>
                        <div style={{width: 200}}>
                            <SelectField
                                name="range1"
                                label="Date Range:"
                                options={this.date1Ranges}
                            />
                        </div>

                        <InputField
                            name="dfrom1"
                            type="date"
                            disabled={disabledDates1}
                        />
                        <InputField
                            name="dto1"
                            type="date"
                            disabled={disabledDates1}
                        />
                    </div>

                    <div className={styles.FilterForm__hgroup}>
                        <div style={{width: 200}}>
                            <SelectField
                                name="range2"
                                label="Compare to:"
                                options={this.date2Ranges}
                            />
                        </div>

                        <InputField
                            name="dfrom2"
                            type="date"
                            disabled={disabledDates2}
                        />
                        <InputField
                            name="dto2"
                            type="date"
                            disabled={disabledDates2}
                        />
                    </div>

                    <div className={styles.FilterForm__vgroup}>
                        <CheckboxField
                            name="filterUrls"
                            label="Filter Pages"
                            bottomBorder={false}
                        />
                        {!hideUrlsFilter && (
                            <div style={{marginLeft: 40}}>
                                <RadioField
                                    name="urlFilterType"
                                    options={this.urlsOptions}
                                    bottomBorder={false}
                                />
                                {!hideUrlsList && (
                                    <div style={{marginLeft: 30}}>
                                        <TextareaField
                                            name="urls"
                                            placeholder={'/forum/topics/poradne/\n^/forum/kocikova-poradna/'}
                                            rows={2}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <div className={styles.FilterForm__vgroup}>
                        <CheckboxField
                            name="filterKeywords"
                            label="Filter Keywords"
                            bottomBorder={false}
                        />
                        {!hideKeywordsFilter && (
                            <div style={{marginLeft: 40}}>
                                <RadioField
                                    name="keywordFilterType"
                                    options={this.keywordsOptions}
                                    bottomBorder={false}
                                />
                                {!hideKeywordsList && (
                                    <div style={{marginLeft: 30}}>
                                        <TextareaField
                                            name="keywords"
                                            placeholder={'duphaston\nhelikobakter'}
                                            rows={2}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <div style={{marginTop: 20}}>
                        <SubmitButton
                            label="Show"
                            formName={SEO_PERFORMANCE_FILTER_FORM_NAME}
                        />
                    </div>
                </Form>
            </Panel>
        );
    }
}

export const FilterForm = mkReduxForm<FilterFormData, Props>({
    formName: SEO_PERFORMANCE_FILTER_FORM_NAME,
    onValidate: () => ({/* nothing to do, always valid */}),
    onSave: (values, props) => props.onApply(values, null),
    disableDirtyCheck: true,
})(FilterFormComponent);
