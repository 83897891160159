import cx from 'classnames';
import { OverlayLoadable } from 'mk2/components/OverlayLoadable';
import React, { CSSProperties, LegacyRef } from 'react';
import styles from './MenuDropdown.mscss';

interface DropdownOwnProps {
    className: string;
    placement?: string;
    containerRef?: LegacyRef<HTMLDivElement>;
    show: boolean;
    style?: CSSProperties;

    onClick();
}

const Dropdown: React.FunctionComponent<DropdownOwnProps> = ({ containerRef, children, show, style, onClick }) => (
    <div
        className={cx(styles.MenuDropdown__Dropdown, { [styles['MenuDropdown__Dropdown--show']]: show })}
        style={style}
        onClick={onClick}
        ref={containerRef}
    >
        {children}
    </div>
);

interface OwnProps {
    className?: string;
    show: boolean;
    target: React.ReactInstance | (() => React.ReactInstance);
    onClose: () => void;
    container: React.ReactNode | (() => React.ReactNode);
    offsetTop?: number | string;
    offsetRight?: number | string;
}

interface ChildProps {
    align: 'left' | 'center' | 'right';
    className: string;
    itemClassName?: string;
}

export class MenuDropdown extends React.Component<OwnProps> {

    public render() {
        const { className, show, onClose, children, offsetRight, offsetTop, ...rest } = this.props;

        if (!show) {
            return null;
        }

        return (
            <OverlayLoadable
                {...rest}
                popperConfig={{
                    modifiers: {
                        offset: {
                            enabled: true,
                            offset: `${offsetRight || 0}, ${offsetTop || 3}`, // Add default 3px offset top
                        },
                    },
                }}
                show={show}
                onHide={onClose}
                placement={'bottom-end' as any/* react-overlays has missing Placements */}
            >
                {({ props }) => (
                    <Dropdown
                        className={cx(styles.MenuDropdown__items, className, show && styles['MenuDropdown--show'])}
                        show={show}
                        onClick={onClose}
                        style={props.style}
                        containerRef={props.ref}
                    >
                        {React.Children.map(children, (child) => {
                            if (React.isValidElement<ChildProps>(child)) {
                                const displayName = typeof child.type === 'string' ? child.type : (child.type as any).displayName;
                                const newProps: ChildProps = {
                                    align: child.props.align || 'left',
                                    className: cx(child.props.className, styles[`MenuDropdown__${displayName}`]),
                                };

                                if (displayName === 'MenuGroup' /* child.type === MenuGroup */) { // @see https://github.com/gaearon/react-hot-loader/issues/304
                                    newProps.itemClassName = styles[`MenuDropdown__MenuGroup__MenuItem`];
                                }

                                return React.cloneElement(child, newProps);
                            } else {
                                return child;
                            }
                        })}
                    </Dropdown>
                )}
            </OverlayLoadable>
        );
    }

}
