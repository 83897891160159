import cx from 'classnames';
import { LocationDescriptor } from 'history';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import React from 'react';
import styles from './LinkListItem.mscss';

export enum GrowPriority {
    title = 'title',
    subtitle = 'subtitle',
}

export enum LinkListItemColor {
    Blue,
    Pink,
}

interface OwnProps {
    className?: string;
    title: React.ReactNode;
    to: LocationDescriptor;
    priority?: GrowPriority;
    icon?: React.ReactNode;
    color?: LinkListItemColor;
    trimLongTitle?: boolean;
    fontSize?: 13 | 16;

    onClick?(event: React.MouseEvent<HTMLAnchorElement>);
}

type Props = OwnProps;

export class LinkListItem extends React.PureComponent<Props> {
    public render() {
        const {
            className, to, title, children, priority = GrowPriority.title, onClick, icon,
            color = LinkListItemColor.Blue, trimLongTitle = true, fontSize = 13,
        } = this.props;

        return (
            <li className={cx(styles.LinkListItem, className)}>
                <Link
                    className={cx(
                        styles.LinkListItem__link,
                        icon && styles['LinkListItem__link--customIcon'],
                        color === LinkListItemColor.Pink && styles['LinkListItem__link--pinkColor'],
                        // ak je text na viac riadkov, bullet ma byt hore, nie vycentrovany
                        !trimLongTitle && styles['LinkListItem__link--bulletTop'],
                        !!fontSize && styles[`LinkListItem__link--fontSize${fontSize}`],
                    )}
                    to={to}
                    onClick={onClick}
                >
                    {icon && (
                        <div className={styles.LinkListItem__link__icon}>
                            {icon}
                        </div>
                    )}
                    <span
                        className={cx(
                            styles.LinkListItem__link__title,
                            styles[`LinkListItem__link__title--${priority}Priority`],
                            icon && styles['LinkListItem__link__title--customIcon'],
                            trimLongTitle && styles['LinkListItem__link__title--trimLong'],
                        )}
                    >
                        {title}
                    </span>

                    {React.Children.count(children) > 0 && (
                        <span className={cx(styles.LinkListItem__link__subtitle, styles[`LinkListItem__link__subtitle--${priority}Priority`])}>
                            {children}
                        </span>
                    )}
                </Link>
            </li>
        );
    }
}

interface PlaceholderOwnProps {
    className?: string;
    width?: number | string;
}

let LinkListItemPlaceholder: React.FunctionComponent<PlaceholderOwnProps> = ({ className, width }) => (
    <li className={cx(styles.LinkListItem, className)}>
        <span className={cx(styles.LinkListItem__link, styles['LinkListItem__link--placeholder'])}>
            <LoadingPlaceholder
                className={cx(styles.LinkListItem__link__title, styles[`LinkListItem__link__title--titlePriority`])}
                width={width}
                height={13}
            />
        </span>
    </li>
);

LinkListItemPlaceholder.displayName = 'LinkListItemPlaceholder';

LinkListItemPlaceholder = React.memo<PlaceholderOwnProps>(LinkListItemPlaceholder);

export { LinkListItemPlaceholder };
