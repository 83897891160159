import { push } from 'connected-react-router';
import { isAuthenticated } from 'mk/bazaar/common/userUtils';
import { users_api_pregnancy_newsletter_subscribe_url } from 'mk/urls/functions';
import { GroupEntity } from 'mk2/apps/blogs/schemas';
import { groupsGroupUrl } from 'mk2/apps/groups/urls';
import { signupUrl } from 'mk2/apps/users/urls';
import { gtmEvent } from 'mk2/apps/users/utils';
import {
    newsletterSubscriberCreateApi,
    NewsletterSubscriberCreateTriggerAction,
    NEWSLETTER_SUBSCRIBER_CREATE_TRIGGER,
} from 'mk2/containers/PregnancyNewsletter/PregnancyNewsletter.actions';
import { normalizeError, XHRAction, XHRFormError } from 'mk2/helpers/api';
import { handleXHRFormErrorSaga, handleXHRFormSuccessSaga } from 'mk2/helpers/form';
import { removeEmpty, url } from 'mk2/helpers/urls';
import { getLogger } from 'mk2/logger';
import { getRequestUser } from 'mk2/reducers';
import { normalize } from 'normalizr';
import { stringify } from 'query-string';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

const logger = getLogger('PregnancyNewsletter.sagas');

interface ApiResponseSubscriberCreate {
    body: {
        hasProfile: boolean;
        pregnancyDueDate: string;
        pregnancyWeek: string;
        group: GroupEntity;
    };
}
function* createSubscriber({ formName, subscriberFd, historyLocationState, signupProps, extraEventProps, xhr }: NewsletterSubscriberCreateTriggerAction & XHRAction) {
    yield put(newsletterSubscriberCreateApi.request(formName, subscriberFd));
    try {
        const response: ApiResponseSubscriberCreate = yield call(
            () => xhr.post(users_api_pregnancy_newsletter_subscribe_url(), {
                subscriberFd,

                // previous url (from standard window.document.referrer)
                pageRef: (typeof window !== 'undefined') ? window.document.referrer : '',

                // previous url (within pwa from react router)
                pwaRef: historyLocationState?.UNSAFE_mkGoBackUrl || '',

                // further info e.g.: about the position of PregnancyNewsletter widget
                extraEventProps,
            }),
        );
        const normalizedResponse = normalize(response.body, {});
        yield handleXHRFormSuccessSaga(formName);
        yield put(newsletterSubscriberCreateApi.success(formName, subscriberFd, normalizedResponse));
        gtmEvent('event_pregnancy_newsletter_subscribed');

        const requestUser = yield select(getRequestUser);
        if (!isAuthenticated(requestUser) && !response.body.hasProfile) {
            const groupParams = response.body.group
                ? {
                    next: url(groupsGroupUrl, { groupId: response.body.group.id }),
                    group_name: response.body.group.name, // will replace %group_name% in signup's content item
                    group_active_members_count: response.body.group.activeMembersCount, // will replace %group_active_members_count% in signup's content item
                }
                : {};
            yield put(push({
                pathname: signupUrl,
                search: stringify({
                    ci: 'def', // is used to load content items with key 'def' (registration-def-top/bottom)
                    ...removeEmpty(signupProps),
                    ...groupParams,
                    email: subscriberFd.email, // is used as initial form value of email
                    dueDate: response.body.pregnancyDueDate, // is used as initial form value of dueDate
                    pregnancy_week: response.body.pregnancyWeek, // will replace %pregnancy_week% in signup's content item
                }),
            }));
        }
    } catch (error) {
        yield handleXHRFormErrorSaga(formName, error as XHRFormError, logger);
        yield put(newsletterSubscriberCreateApi.failure(formName, subscriberFd, normalizeError(error)));
    }
}

export default function* root() {
    yield all([
        takeLatest(NEWSLETTER_SUBSCRIBER_CREATE_TRIGGER, createSubscriber),
    ]);
}
