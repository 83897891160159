import cx from 'classnames';
import {
    // BUY_BUTTON_SETS,
    BUY_BUTTON_USED_STROLLERS,
    BUY_IN_ESHOP,
    GREAT_SET,
    ORIGINAL_PRICE,
    SHOPPING_WITH_MK,
    YOU_SAVE,
} from 'mk/autogenerated/translations/EshopProductWidget.929bef723a28295bdcf2a922d101cb63'
import assetUrl from 'mk/common/assetUrl';
import { ESHOP_URL } from 'mk/settings';
import { common_api_redirect_to_eshop_url } from 'mk/urls/functions';
import { EshopProductEntity } from 'mk2/apps/blogs/schemas';
import { moneyPretty } from 'mk2/apps/bo/utils';
import styles from 'mk2/apps/groups/containers/EshopProducts/EshopProduct.mscss';
import { Btn, BtnSidePadding, BtnType } from 'mk2/components/Btn';
import { Clamp } from 'mk2/components/Clamp';
import { Img } from 'mk2/components/Img';
import { Link } from 'mk2/components/Link';
import { getDomain } from 'mk2/helpers/urls';
import React from 'react';

export enum EshopProductWidgetSlot {
    GROUP_FEED_HEADER = 'group_feed_header',
    GROUP_FEED_POSTS = 'group_feed_posts',
    GROUP_FEED_FOOTER = 'group_feed_footer',

    FORUM_THREAD_HEADER = 'forum_thread_header',
    FORUM_THREAD_POSTS = 'forum_thread_posts',
    FORUM_THREAD_FOOTER = 'forum_thread_footer',

    COUNSELLING_FOOTER = 'counselling_footer',

    WIKI_ARTICLE = 'wiki_article',
    BAZAAR_GRID = 'bazaar_grid',

    STROLLEROPEDIA = 'strolleropedia',
    STROLLER_COUNSELLING = 'stroller_counselling',
}

export enum ProductTypeIds {
    SET = 'UHJvZHVjdFR5cGU6Nzk=',
    DIAPER = 'UHJvZHVjdFR5cGU6Mg==',
    USED_STROLLER = 'UHJvZHVjdFR5cGU6MTA0',
}

// export enum SetProductTypes {
//     SET_NAME_1 = 'XXXX',
//     SET_NAME_2 = 'XXXX',
// }

interface OwnProps {
    eshopProducts: EshopProductEntity[];
    slot: EshopProductWidgetSlot;
    pageId: number;
    widgetPosition: number;
    small: boolean;
    header: boolean;
}

type Props = OwnProps;

export default class EshopProductWidget extends React.PureComponent<Props> {

    public render() {
        const { eshopProducts, slot, pageId, widgetPosition, small, header } = this.props;

        if (!eshopProducts) {
            return null;
        }

        const avatarSize = small ? 24 : 36;
        const style = small ? 'small' : 'big';
        const headerText = small ? getDomain(ESHOP_URL) : SHOPPING_WITH_MK;

        const widgetStyle = slot === EshopProductWidgetSlot.STROLLER_COUNSELLING
            ? cx(styles.EshopProductWidget, styles[`EshopProductWidget__${style}Counsell`])
            : slot === EshopProductWidgetSlot.STROLLEROPEDIA
                ? cx(styles.EshopProductWidget, styles[`EshopProductWidget__${style}Stroller`])
                : cx(styles.EshopProductWidget, styles[`EshopProductWidget__${style}`]);

        const productsStyle = slot === EshopProductWidgetSlot.STROLLER_COUNSELLING
            ? styles[`EshopProductWidget__products${style}Counsell`]
            : slot === EshopProductWidgetSlot.STROLLEROPEDIA
                ? styles[`EshopProductWidget__products${style}Stroller`]
                : styles[`EshopProductWidget__products${style}`];

        return (
                <div className={widgetStyle}>
                {header &&
                    <>
                        <div
                            className={styles[`EshopProductWidget__container${style}`]}
                        >
                            <Img
                                className={styles.EshopProductWidget__containerImg}
                                src={assetUrl + 'img/miky/sitting-miky.png'}
                                width={avatarSize}
                                height={avatarSize}
                            />
                            <Link to={ESHOP_URL}>
                                <span className={styles.EshopProductWidget__headerMKInfo}>
                                    <div className={styles.EshopProductWidget__profileName}
                                    >
                                        {headerText}

                                    </div>
                                    {!small &&
                                        <div className={styles.EshopProductWidget__eshopUrl}
                                        >
                                            {getDomain(ESHOP_URL)}
                                        </div>
                                    }
                                </span>
                            </Link>
                        </div>
                        {small &&
                            <div className={styles.EshopProductWidget__line}/>
                        }
                    </>
                }
                <div className={productsStyle}>
                    {eshopProducts.slice(0, small ? 2 : 3) .map((eshopProduct, index) => {
                        return (
                            <React.Fragment key={index}>
                                <EshopProduct
                                    eshopProduct={eshopProduct}
                                    eshopProductPosition={index}
                                    widgetPosition={widgetPosition}
                                    slot={slot}
                                    pageId={pageId}
                                    key={index}
                                    small={small}
                                    style={style}
                                    index={index}
                                />
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        );
    }
}

interface EshopProductProps {
    eshopProduct: EshopProductEntity;
    eshopProductPosition: number;
    widgetPosition: number;
    slot: EshopProductWidgetSlot;
    pageId: number;
    small: boolean;
    style: string;
    index: number;
}

const EshopProduct: React.FunctionComponent<EshopProductProps> = ({
    eshopProduct,
    eshopProductPosition,
    pageId,
    slot,
    widgetPosition,
    small,
    index,
}) => {

    if (eshopProduct === undefined) {
        return <></>;
    }

    const setsCategoryId = 'Q2F0ZWdvcnk6MjMx';
    const pampersCategoryId = 'Q2F0ZWdvcnk6ODY=';


    const imageUrl = eshopProduct.media?.url;

    const eshopProductName = eshopProduct.name !== null
        ? eshopProduct.name
        : '';

    const isPampers = eshopProduct.productType === ProductTypeIds.DIAPER;
    const isSet = eshopProduct.productType === ProductTypeIds.SET;
    const isUsedStroller = eshopProduct.productType === ProductTypeIds.USED_STROLLER;

    const catid = isPampers
        ? pampersCategoryId
        : isSet
        ? setsCategoryId
        : eshopProduct.categoryId;

    let buyButton = '';

    if (isSet && (
            slot !== EshopProductWidgetSlot.STROLLEROPEDIA && slot !== EshopProductWidgetSlot.STROLLER_COUNSELLING
        )) {
        // buyButton = BUY_BUTTON_SETS;
        buyButton = BUY_IN_ESHOP;
    } else if (isUsedStroller) {
        buyButton = BUY_BUTTON_USED_STROLLERS;
    } else {
        buyButton = BUY_IN_ESHOP;
    }

    const priceAndCurrency = isPampers
        ? ''
        : moneyPretty(eshopProduct.price, true, true, true);

    let discount: number = 0;
    let savedAmountCurrency: string = null;
    let membersPriceSum: string = null;
    if (!!eshopProduct.membersPriceSum) {
        membersPriceSum = moneyPretty(eshopProduct.membersPriceSum, true, true, true);
        let discountPercentage = 1 - eshopProduct.price.value / eshopProduct.membersPriceSum.value;
        discountPercentage = Math.max(0, Math.min(1, discountPercentage));  // ensure 0 <= discountPercentage <= 1
        discount = Math.round(100 * discountPercentage);

        const savedAmount = Math.round(eshopProduct.membersPriceSum.value - eshopProduct.price.value);
        savedAmountCurrency = moneyPretty({ value: savedAmount, currency: eshopProduct.price.currency }, false, true, true);
    }

    if (slot === EshopProductWidgetSlot.STROLLER_COUNSELLING && !small) {
        return (
            <Link
                className={styles.EshopProduct__boxStrollerCounselling}
                to={
                    common_api_redirect_to_eshop_url(
                        eshopProduct.slug, {
                            epid: eshopProduct.idOriginal,
                            epos: eshopProductPosition,
                            wpos: widgetPosition,
                            slot,
                            pid: pageId,
                            vid: eshopProduct.variantId,
                            catid,
                            utm_camp: eshopProduct.utmCampaign,
                            prod_type: eshopProduct.productType,
                        },
                    )
            }>
                <div className={styles.EshopProduct__imageContainer}>
                    <div
                        className={styles.EshopProduct__imageCounselling}
                        style={{backgroundImage: `url(${imageUrl})`}}
                    />
                </div>
                <div className={styles.EshopProduct__productInfo}>
                    <Clamp clamp={2} useNativeClamp={false}>
                        <div>{eshopProductName}</div>
                    </Clamp>
                    {(discount <= 0 || eshopProduct.productType !== ProductTypeIds.SET) && (
                        <div className={styles.EshopProduct__priceStyleCounselling}>
                            {priceAndCurrency}
                        </div>
                    )}
                    <SetDiscountBlock
                         discount={discount}
                         priceAndCurrency={priceAndCurrency}
                         savedAmountCurrency={savedAmountCurrency}
                         membersPriceSum={membersPriceSum}
                         productType={eshopProduct.productType}
                    />
                    <div className={styles.EshopProduct__boldText}>
                        <object>
                            <Btn
                                asAnchor
                                sidePadding={BtnSidePadding.None}
                                type={BtnType.BlueText}
                                label={buyButton}
                                link={common_api_redirect_to_eshop_url(eshopProduct.slug, {
                                    epid: eshopProduct.idOriginal,
                                    epos: eshopProductPosition,
                                    wpos: widgetPosition,
                                    slot,
                                    pid: pageId,
                                    vid: eshopProduct.variantId,
                                    catid,
                                    utm_camp: eshopProduct.utmCampaign,
                                    prod_type: eshopProduct.productType,
                                })}
                            />
                        </object>
                    </div>
                </div>
            </Link>
        );
    } else if (small) {

        const boxStyle =  index === 1 || slot === EshopProductWidgetSlot.STROLLER_COUNSELLING
            ? styles.EshopProduct__boxSmall
            : styles.EshopProduct__boxSmallBorder;


        return (
            <Link
                className={boxStyle}
                to={
                    common_api_redirect_to_eshop_url(
                        eshopProduct.slug, {
                            epid: eshopProduct.idOriginal,
                            epos: eshopProductPosition,
                            wpos: widgetPosition,
                            slot,
                            pid: pageId,
                            vid: eshopProduct.variantId,
                            catid,
                            utm_camp: eshopProduct.utmCampaign,
                            prod_type: eshopProduct.productType,
                        })
                    }
            >
                <div
                    className={styles.EshopProduct__containerImageSizeSmall}
                    style={{backgroundImage: `url(${imageUrl})`, height: '50px'}}
                />
                <div className={styles.EshopProduct__bodySmall}>
                    <Clamp clamp={2} useNativeClamp={false}>
                        <div>{eshopProductName}</div>
                    </Clamp>
                    <div className={
                        cx(
                            styles.EshopProduct__priceStyleSmall,
                            discount <= 0 && styles.EshopProduct__priceColorBasic,
                            discount > 0 && styles.EshopProduct__priceColorDiscount,
                            )}>
                        {priceAndCurrency}
                        {discount > 0 && (
                            <div className={styles.EshopProduct__oldPrice}>
                               <div className={styles.EshopProduct__discountBadge}>
                                   {`-${discount}%`}
                               </div>
                                <div className={styles.EshopProduct__originalPrice}>
                                    {`${ORIGINAL_PRICE}${membersPriceSum}`}
                                </div>
                            </div>

                        )}
                    </div>
                    <div className={styles.EshopProduct__buttonSmallMobile}>
                        <object>
                            <Btn
                                asAnchor
                                sidePadding={BtnSidePadding.None}
                                type={BtnType.BlueText}
                                label={buyButton}
                                link={common_api_redirect_to_eshop_url(eshopProduct.slug, {
                                    epid: eshopProduct.idOriginal,
                                    epos: eshopProductPosition,
                                    wpos: widgetPosition,
                                    slot,
                                    pid: pageId,
                                    vid: eshopProduct.variantId,
                                    catid,
                                    utm_camp: eshopProduct.utmCampaign,
                                    prod_type: eshopProduct.productType,
                                })}
                            />
                        </object>
                    </div>
                </div>
                <div className={cx(
                    styles.EshopProduct__buttonSmallDesktop,
                    (isSet || isUsedStroller) && styles.EshopProduct__buttonMaxWidth,
                )}>
                    <object>
                        <Btn
                            asAnchor
                            sidePadding={BtnSidePadding.None}
                            type={BtnType.BlueText}
                            label={buyButton}
                            link={common_api_redirect_to_eshop_url(eshopProduct.slug, {
                                epid: eshopProduct.idOriginal,
                                epos: eshopProductPosition,
                                wpos: widgetPosition,
                                slot,
                                pid: pageId,
                                vid: eshopProduct.variantId,
                                catid,
                                utm_camp: eshopProduct.utmCampaign,
                                prod_type: eshopProduct.productType,
                            })}
                        />
                    </object>
                </div>
            </Link>
        );
    } else {
        let boxStyle;

        if (slot === EshopProductWidgetSlot.STROLLEROPEDIA) {
            boxStyle = index === 2
            ? styles.EshopProduct__boxBigLastS
            : index === 0
                ? styles.EshopProduct__boxBigFirstS
                : styles.EshopProduct__boxBigS;
        } else {
            boxStyle = index === 2
            ? styles.EshopProduct__boxBigLast
            : index === 0
                ? styles.EshopProduct__boxBigFirst
                : styles.EshopProduct__boxBig;
        }

        return (
            <Link
                to={
                    common_api_redirect_to_eshop_url(
                        eshopProduct.slug, {
                            epid: eshopProduct.idOriginal,
                            epos: eshopProductPosition,
                            wpos: widgetPosition,
                            slot,
                            pid: pageId,
                            vid: eshopProduct.variantId,
                            catid,
                            utm_camp: eshopProduct.utmCampaign,
                            prod_type: eshopProduct.productType,
                        })}
                className={boxStyle}
            >
                <div
                    className={styles.EshopProduct__containerImageSizeBig}
                    style={{backgroundImage: `url(${imageUrl})`}}
                />
                <div className={styles.EshopProduct__bodyBig}>
                    <div className={styles.EshopProduct__bodyBigLine}>
                        <Clamp clamp={2} useNativeClamp={false}>
                            <div>{eshopProductName}</div>
                        </Clamp>
                    </div>
                    {(discount <= 0 || eshopProduct.productType !== ProductTypeIds.SET) && (
                        <div className={styles.EshopProduct__priceStyleBig}>
                            {priceAndCurrency}
                        </div>
                    )}
                    <SetDiscountBlock
                         discount={discount}
                         priceAndCurrency={priceAndCurrency}
                         savedAmountCurrency={savedAmountCurrency}
                         membersPriceSum={membersPriceSum}
                         productType={eshopProduct.productType}
                    />
                    <div className={styles.EshopProduct__buttonBig}>
                        <object>
                            <Btn
                                asAnchor
                                sidePadding={BtnSidePadding.None}
                                type={BtnType.BlueText}
                                label={buyButton}
                                link={common_api_redirect_to_eshop_url(eshopProduct.slug, {
                                    epid: eshopProduct.idOriginal,
                                    epos: eshopProductPosition,
                                    wpos: widgetPosition,
                                    slot,
                                    pid: pageId,
                                    vid: eshopProduct.variantId,
                                    catid,
                                    utm_camp: eshopProduct.utmCampaign,
                                    prod_type: eshopProduct.productType,
                                })}
                            />
                        </object>
                    </div>
                </div>
            </Link>
        );
    }
};

interface SetDiscountInterface {
    discount: number;
    priceAndCurrency: any;
    membersPriceSum: any;
    savedAmountCurrency: any;
    productType: string;
}

const SetDiscountBlock: React.FunctionComponent<SetDiscountInterface> = ({
    discount,
    priceAndCurrency,
    membersPriceSum,
    savedAmountCurrency,
    productType,
}) => {
    if (discount <= 0 || productType !== ProductTypeIds.SET) {
        return <></>;
    }
    return(
        <div className={styles.EshopProduct__setPriceBlock}>
            <div className={styles.EshopProduct__greatSet}>
                {`${GREAT_SET} -${discount}%`}
            </div>
            <div className={styles.EshopProduct__newPrice}>
                <div>{priceAndCurrency}</div>
                <div className={styles.EshopProduct__smaller}>{`${membersPriceSum}`}</div>
            </div>
            <div className={styles.EshopProduct__youSave}>
                {`${YOU_SAVE} ${savedAmountCurrency}`}
            </div>
        </div>
    );
};
