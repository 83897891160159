import { Location } from 'history';
import { reactFilter, TextLike } from 'mk/bazaar/common/filters/utils';
import { ACTIVE_SERVER_ID } from 'mk/settings';
import {
    COMMENTS_PER_POST_DETAIL_PAGE,
    CONTESTS_TOPIC_ID,
    COUNSELLING_CATEGORY_ID,
    UNCATEGORIZED_TOPIC_ID,
} from 'mk2/apps/forum/constants';
import { PageRef, PostEntity } from 'mk2/apps/forum/schemas';
import { memoizeLRU } from 'mk2/helpers/cache';
import { CommentEntity, PhotoEntity } from 'mk2/schemas';
import { parse } from 'query-string';
import React from 'react';

export function getPageNumberInPost(comment: CommentEntity): number {
    return (
        Math.floor(comment.positionInPost / COMMENTS_PER_POST_DETAIL_PAGE) +
        (comment.positionInPost % COMMENTS_PER_POST_DETAIL_PAGE !== 0 ? 1 : 0)
    );
}

export function parsePageNumber(location: Location): PageRef {
    const query = parse(location.search);

    let pageRef: PageRef = null;
    if (query.page === 'last') {
        pageRef = 'last';
    } else if (!query.page) { // not in query string, that means page=1
        pageRef = 1;
    } else {
        const pageNumOrNan = parseInt(query.page as string, 10);
        if (!isNaN(pageNumOrNan) && pageNumOrNan >= 1) {
            pageRef = pageNumOrNan;
        }
    }

    return pageRef;
}

// Photo versions' largest-first order (from apps/system/utils.py formats)
const photoVersionsOrder = [
    's1600x1600',
    's742xn',
    's738x738',
    's720x720',
    's600x600',
    's560x560',
    's492x492',
    's460xn',
    's369x369',
    's305x150',
    's305x90',
    'c300x300',
    's270x100',
    's246x246',
    'snx224',
    's221x221',
    's200x200',
    'c200x200',
    'c192x192',
    's170x170',
    's165x165',
    's150x150',
    'c120x120',
    'snx100',
    's90x90',
    's96x96',
    'c80x80',
    'snx80',
    'snx64',
    'c60x60',
    'c50x50',
    'snx50',
    'c32x32',
];

function photoVersionsSortFn(v1, v2) {
  return photoVersionsOrder.indexOf(v1) - photoVersionsOrder.indexOf(v2);
}

// sort photo versions in largest-first order
export const sortPhotoVersions = memoizeLRU(50, (versions: string[]): string[] => versions.sort(photoVersionsSortFn));


export function largestAvailablePhotoVersionOfPost(photo: PhotoEntity, wishedVersion: 's600x600' | 's1600x1600'): string {
    // pre MessagePhoto su tieto kombinacie velkosti obrazkov:
    // '320,s150x150', '600,s150x150', '1600,600,s150x150'
    if (photo.id < {201: 13727898, 202: 13728130}[ACTIVE_SERVER_ID]) {
        // pre id-cka [0, 13728130) sa ukladala max velkost s320xn
        return 's320xn';
    } else if (photo.id < {201: 167737681, 202: 217293940}[ACTIVE_SERVER_ID]) {
        // pre id-cka [13728130, 217293940) sa vytvarala velkost s1600x1600 ak aspon jeden
        // z rozmerov obrazka bol 800px, inak velkost s600x600
        if (photo.height < 800 && photo.width < 800) {
            return 's600x600';
        } else {
            return wishedVersion === 's1600x1600' ? 's1600x1600' : 's600x600';
        }
    } else {
        // pre id-cka [217293940, ...) sa vzdy ukladal format s1600x1600
        return wishedVersion === 's1600x1600' ? 's1600x1600' : 's600x600';
    }
}

const linkProcessor = (lnk, visibleText): React.ReactChild => {
    return (
        <a href={lnk}>
            {visibleText}
        </a>
    );
};

type LnkProcessorFunction = (srcLnk: string, visibleText: string) => React.ReactChild;

const FORUM_LINK_RE = /\[\[\s*(.*?)\s*\|\s*(.*?)\s*\]\]/;

// process mediawiki format links in the form of [[easygo-virage|EasyGo Virage]]
// using provided function which takes srcLnk and visibleText parameters and
// is expected to return React elements, e.g.:
// <Link to="/kocikopedia/easygo-virage/">EasyGo Virage</Link>
// this filter is used for our INTERNAL links
const forumLinkize = (text: TextLike, lnkProcessor: LnkProcessorFunction) => reactFilter(text, (s: string): React.ReactChild[] => {
    const ret = [];
    const matcher = new RegExp(FORUM_LINK_RE, 'g');
    let from = 0;
    while (true) {
        const match = matcher.exec(s);
        if (match) {
            const matchLen = match[0].length;
            const lnk = match[1];
            const visibleText = match[2];

            const to = matcher.lastIndex - matchLen;

            // copy text before this link
            ret.push(s.substring(from, to));

            const finalLnk = lnkProcessor(lnk, visibleText);
            ret.push(finalLnk);

            from = matcher.lastIndex;
        } else {
            // copy text after last link
            ret.push(s.substring(from));
            break;
        }
    }
    return ret;
});

export const isContestPost = (post: PostEntity): boolean =>
    post?.topic?.id === CONTESTS_TOPIC_ID;

export const isUncategorizedPost = (post: PostEntity): boolean =>
    post?.topic?.id === UNCATEGORIZED_TOPIC_ID;

export const isCounsellingPost = (post: PostEntity): boolean =>
    post?.topic?.category?.id === COUNSELLING_CATEGORY_ID;
