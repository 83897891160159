import {
    faChartLine,
    faCog,
    faCopyright,
    faExclamationTriangle,
    faLifeRing,
    faPencil,
    faPlusCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import {
    FORUM_POST_ADMINCLUB_MENU,
    PREGNANCY_NEWSLETTER,
    PREGNANCY_NEWSLETTER_DESCRIPTION,
    RELATED_DISCUSSIONS_IN_FORUM,
    SEO_TITLE_WIKI_article,
    WIKI_ARTICLE_ADD_NEW,
    WIKI_ARTICLE_AMBASSADOR_BRAND_CLEAR,
    WIKI_ARTICLE_AMBASSADOR_BRAND_SET,
    WIKI_ARTICLE_EDIT,
    WIKI_REPORT_MISTAKE_IN_ARTICLE,
    WIKI_WRITE_EXPERIENCE,
} from 'mk/autogenerated/translations/ArticlePage.cfd93c89561d4bfce59167f551d2fd02'
import { isAuthenticated, isAuthenticatedAsAmbassador, isAuthenticatedAsAmbassadorOfBrand } from 'mk/bazaar/common/userUtils';
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { adminclub_wiki_article_url } from 'mk/urls/functions';
import {
    adminclubArticlesAndVendors,
    adminclubReviewCategoryCreate,
    adminclubReviewCategoryEdit,
} from 'mk2/apps/adminclub/urls';
import { DateRange1, KeywordFilterType } from 'mk2/apps/dash/containers/SeoPerformance/FilterForm';
import { dashSeoPerformaceUrl } from 'mk2/apps/dash/urls';
import { faqAskQuestionUrl, faqIndexUrl } from 'mk2/apps/faq/urls';
import { MorePosts } from 'mk2/apps/forum/components/MorePosts';
import { loginUrl } from 'mk2/apps/users/urls';
import Article from 'mk2/apps/wiki/containers/Article/Article';
import {
    articleFetchTrigger,
    articlePageViewTrigger,
    articleSetAmbassadorBrandTrigger,
} from 'mk2/apps/wiki/containers/Article/Article.actions';
import { hasPregnancyNewsletter, WIKI_MENU_MORE } from 'mk2/apps/wiki/containers/Article/Article.constants';
import styles from 'mk2/apps/wiki/containers/Article/Article.mscss';
import articlesReducer, { ReviewCategoriesStats } from 'mk2/apps/wiki/containers/Article/Article.reducers';
import articlesSaga from 'mk2/apps/wiki/containers/Article/Article.sagas';
import { getWikiArticleState } from 'mk2/apps/wiki/containers/Article/Article.selectors';
import {
    isArticleOfReviewsCategory,
    isFaq,
    isNoAdsCategory,
    isReviewHomepageArticle,
    WIKI_EXPERIENCE_MENU_MORE,
} from 'mk2/apps/wiki/helpers';
import { articleWiki, articleWikiOG, conceptsBreadcrumbsWiki } from 'mk2/apps/wiki/structuredData';
import { wikiAddExperienceUrl, wikiArticleCreateUrl, wikiArticleEditUrl } from 'mk2/apps/wiki/urls';
import { wikiArticleUrl } from 'mk2/apps/wiki/utils';
import { MenuItem } from 'mk2/components/menu/MenuItem';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Panel } from 'mk2/components/Panel';
import { PanelHeading } from 'mk2/components/PanelHeading';
import { TrackClick } from 'mk2/components/TrackClick';
import { AppName } from 'mk2/containers/Application/Application';
import { Menu } from 'mk2/containers/Menu/Menu';
import { menuToggle } from 'mk2/containers/Menu/Menu.actions';
import PregnancyNewsletter from 'mk2/containers/PregnancyNewsletter/PregnancyNewsletter';
import { getFirstParagraphsPlaintext } from 'mk2/helpers/article_ast/utils';
import { cacheLast } from 'mk2/helpers/cache';
import { hasVal } from 'mk2/helpers/sanitize';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { url } from 'mk2/helpers/urls';
import Page, { BrandingProps, HalfpageProps } from 'mk2/pages/Page';
import {
    getRequestBaseUrl,
    getRequestDeviceMobile,
    getRequestPermissions,
    getRequestUser,
    AppState,
} from 'mk2/reducers';
import { LoadingState } from 'mk2/reducers/loadingState';
import {
    ArticleConceptPath,
    ArticleLinkDataEntity,
    ArticleLinkDataSchema,
    EshopProductEntity,
    EshopProductSchema,
    ExperienceEntity,
    MenuSelection,
    PostEntity,
    PostSchema,
    ReviewCategoryEntity,
    UserEntity,
    WikiArticleEntity,
    WikiArticleSchema,
} from 'mk2/schemas';
import {
    getDenormalizedEntities,
    getDenormalizedEntitiesMapList,
    getDenormalizedEntity,
} from 'mk2/selectors';
import { interpolate } from 'mk2/services/i18n';
import { Store } from 'mk2/store/configureStore';
import { parse } from 'query-string';
import React from 'react';
import { MapStateToPropsParam } from 'react-redux';
import { RouteComponentProps } from 'react-router';

const fasCogLg: FontAwesomeIconProps = { icon: faCog, size: 'lg' };

export interface OwnProps {}

export interface StateProps {
    article: WikiArticleEntity;
    articleConceptPath: ArticleConceptPath[];
    articleSlug: string;
    baseUrl: string;
    categorySlug: string;
    errorMessage: string;
    experienceIdRef: string;
    isMobile: boolean;
    loadingState: LoadingState;
    moreReviewCategoryArticlesLoadingState: LoadingState;
    relatedArticles: ArticleLinkDataEntity[];
    requestUser: UserEntity;
    requestUserPermissions: string[];
    reviewCategoryArticles: WikiArticleEntity[];
    reviewCategoryArticlesCount: number;
    reviewCategoryExperiences: ExperienceEntity[];
    reviewCategories: ReviewCategoryEntity[];
    reviewCategoriesStats: ReviewCategoriesStats;
    similarPosts: PostEntity[];
    viewsCountTotal: number;
    viewsCountUnique: number;
    eshopProductsOfEshopNodes: Record<string, EshopProductEntity[]>;
}

export interface DispatchProps {
    onLoad(articleSlug: string, categorySlug: string);
    onToggleMenuMore(id: number);
    onWikiToggleMenuMore();
    onPageView(categorySlug: string, articleSlug: string);
    onSetAmbassadorBrand(articleId: number, setAmbassadorBrand: boolean);
}

export type RouteProps = RouteComponentProps<{
    articleSlug: string;
    categorySlug?: string;
}>;
export type Props = OwnProps & StateProps & DispatchProps & RouteProps;

export default class ArticlePage extends Page<OwnProps & RouteProps, StateProps, DispatchProps> {

    private onSetAmbassadorBrandCache = cacheLast();
    private trackEventPropsCache = cacheLast();

    public mapStateToProps(): MapStateToPropsParam<StateProps, OwnProps & RouteProps, AppState> {
        return (state: AppState, ownProps: OwnProps & RouteProps): StateProps => {
            let experienceIdRef = ownProps.location.hash;
            if (!experienceIdRef) {
                const qp = parse(ownProps.location.search) || {};
                const expIds = Object.keys(qp).filter((k) => k.startsWith('experience-'));
                experienceIdRef = expIds?.length > 0 ? `#${expIds[0]}` : null;
            }
            const articleSlug = ownProps.match.params.articleSlug;
            const categorySlug = ownProps.match.params.categorySlug;
            const articleState = getWikiArticleState(state, categorySlug, articleSlug);
            const article: WikiArticleEntity = getDenormalizedEntity<WikiArticleEntity>(
                state,
                WikiArticleSchema,
                articleState.articleId,
            );

            const relatedArticles: ArticleLinkDataEntity[] = getDenormalizedEntities<ArticleLinkDataEntity>(
                state,
                ArticleLinkDataSchema,
                articleState.relatedArticles,
            );

            const reviewCategoryArticles: WikiArticleEntity[] = [];
            const reviewCategoryArticlesCount = articleState.reviewCategoryArticlesCount;
            const reviewCategoryExperiences: ExperienceEntity[] = [];
            const reviewCategories: ReviewCategoryEntity[] = [];
            const reviewCategoriesStats: ReviewCategoriesStats = null;

            const similarPosts = getDenormalizedEntities<PostEntity>(
                state,
                PostSchema,
                articleState.similarPostIds,
            );
            const viewsCountTotal = articleState.viewsCountTotal;
            const viewsCountUnique = articleState.viewsCountUnique;

            const eshopProductsOfEshopNodes = getDenormalizedEntitiesMapList<EshopProductEntity>(
                state,
                EshopProductSchema,
                articleState.eshopProductsOfEshopNodes,
            );

            return {
                article,
                articleConceptPath: articleState.articleConceptPath,
                articleSlug,
                baseUrl: getRequestBaseUrl(state),
                categorySlug,
                errorMessage: articleState.errorMessage,
                experienceIdRef,
                isMobile: getRequestDeviceMobile(state),
                loadingState: articleState.loadingState,
                relatedArticles,
                requestUser: getRequestUser(state),
                requestUserPermissions: getRequestPermissions(state),
                reviewCategoryArticles,
                reviewCategoryArticlesCount,
                reviewCategoryExperiences,
                reviewCategories,
                reviewCategoriesStats,
                similarPosts,
                viewsCountTotal,
                viewsCountUnique,
                moreReviewCategoryArticlesLoadingState: articleState.moreReviewCategoryArticlesLoadingState,
                eshopProductsOfEshopNodes,
            };
        };
    }

    public mapDispatchToProps(): MapDispatchToPropsObject<DispatchProps> {
        return {
            onToggleMenuMore: (id: number) => menuToggle(WIKI_EXPERIENCE_MENU_MORE(id)),
            onLoad: articleFetchTrigger,
            onWikiToggleMenuMore: () => menuToggle(WIKI_MENU_MORE),
            onPageView: articlePageViewTrigger,
            onSetAmbassadorBrand: articleSetAmbassadorBrandTrigger,
        };
    }

    public getMetaTitle(props: Props): string {
        const { article } = props;
        if (article) {
            // custom napisany SEO title, alebo to iste ako nadpis clanku
            return article.seoTitle || interpolate(SEO_TITLE_WIKI_article, { article: article.title });
        } else {
            return null;
        }
    }

    public getMetaDescription(props: Props): string {
        const { article } = props;
        if (article) {
            // custom napisany SEO description, alebo to iste ako prvy odstavec clanku
            return article.seoDescription || getFirstParagraphsPlaintext(article.bodyAST);
        } else {
            // pouzi defaultny description v Page
            return null;
        }
    }

    public getMetaFaqRichSnippet(props: Props): string {
        const { article } = props;
        return article?.seoFaqRichSnippet;
    }

    public getStructuredData(props: Props): object[] {
        const { article, articleConceptPath, baseUrl } = props;
        if (article) {
            return [conceptsBreadcrumbsWiki(baseUrl, articleConceptPath), articleWiki(baseUrl, article)];
        }
    }

    public getOpenGraphData(props: Props, baseUrl: string, currentUrl: string): { [key: string]: string } {
        const { article } = props;
        if (article) {
            return {
                ...super.getOpenGraphData(props, baseUrl, currentUrl),
                ...articleWikiOG(baseUrl, article),
            };
        }
    }

    public getCanonicalUrl(baseUrl: string, props: Props): string {
        const { article } = props;
        if (article) {
            return baseUrl + wikiArticleUrl(article);
        }
    }

    public render(props: Props): false | JSX.Element {
        return <Article {...props} />;
    }

    public renderRight(props: Props): JSX.Element {
        const { article, articleSlug, categorySlug, isMobile, similarPosts } = props;
        const OTHER_ARTICLES_CATEGORY_ID = 5133;

        return !isMobile ? (
            <>
                {article && hasPregnancyNewsletter(article.category.slug) && (
                    <Panel className={styles.Article__pregnancyNewsletter}>
                        <PanelHeading title={PREGNANCY_NEWSLETTER} />
                        <p>{PREGNANCY_NEWSLETTER_DESCRIPTION}</p>
                        <div
                            className={cx(
                                styles.Article__pregnancyNewsletter__image,
                                styles[`Article__pregnancyNewsletter__image--${ACTIVE_SERVER_ID}`],
                            )}
                        />
                        <PregnancyNewsletter
                            formNameKey={`article-${article.slug}-right`}
                            noBorder
                            extraEventProps={{
                                subscribe_source: 'tehu_nl_widget_auto',
                                subscribe_source_app: 'wiki',
                                subscribe_source_detail: `wiki/${article.category.slug}/${article.slug}`,
                            }}
                        />
                    </Panel>
                )}
                {Boolean(similarPosts?.length > 0) && article?.category.categoryArticleId !== OTHER_ARTICLES_CATEGORY_ID && (
                    <MorePosts
                        className={styles.Article__similarPosts}
                        posts={similarPosts}
                        title={RELATED_DISCUSSIONS_IN_FORUM}
                        trackEventProps={this.trackEventPropsCache({
                            name: 'wiki_review_more_forum_posts_click',
                            props: {
                                article_slug: articleSlug,
                                category_slug: categorySlug,
                            },
                        }, articleSlug, categorySlug)}
                    />
                )}
            </>
        ) : null;
    }

    public hasHeaderBackButton(props: Props): boolean {
        // po diskusi v SEO agenture: je lepsie ked wiki clanok stale zobrazuje
        // hamburger menu. Najcastejsi usecase je, ze user landne na clanok z Google
        // preklikne sa mozno este na jeden, dva dalsie wiki clanky a potom chce odist
        //
        // Mal by preto hlavne vidiet ze je na MK a dostat sa rychlo na ine sekcie stranky.
        // Back button ho nema kam rozumne vracat, lebo medzi wiki clankami nie je silna
        // parent-child hierarchia.

        const {
            match: {
                params: { categorySlug },
            },
        } = props;

        return isFaq(categorySlug);
    }

    public getDefaultBackPath(props: Props): string {
        const {
            match: {
                params: { categorySlug },
            },
        } = props;
        return categorySlug === 'faq' ? url(faqIndexUrl, { articleSlug: 'faq' }) : null;
    }

    public getBrandingProps(props: Props): BrandingProps {
        const { article } = props;
        if (article && !article.disabledAds && !isNoAdsCategory(article.category.slug)) {
            return {
                zone: 'Wiki - Branding',
            };
        }
        return null;
    }

    public getHalfpageProps(props: Props): HalfpageProps {
        const { article } = props;
        if (article && !article.disabledAds && !isNoAdsCategory(article.category.slug)) {
            return {
                zone: 'Wiki - Halfpage Sticky (Desktop)',
            };
        }
        return null;
    }

    public getHeaderRight(props: Props): React.ReactElement {
        const {
            requestUser, requestUserPermissions, article, onWikiToggleMenuMore, baseUrl, isMobile,
            reviewCategoryArticlesCount, onSetAmbassadorBrand,
        } = props;

        const isDeleted = article && article.deletedAt;
        if (isDeleted) {
            return null;
        }

        // na mobile pre neprihlasenych nezobrazujeme ziadne action buttons, ani menu
        // aby tam kvoli brandingu mohlo byt velke logo
        const isOptimizedForGoogleLands = isMobile && !isAuthenticated(requestUser);
        if (isOptimizedForGoogleLands) {
            return null;
        }

        const isAdmin = requestUserPermissions.indexOf('wiki.can_edit_wiki') >= 0;

        let addBtn;
        if (article) {
            addBtn = this.getAddExperienceBtn(props);
        }

        const hasCreate = article && (isAdmin ||
            (isArticleOfReviewsCategory(article.category.slug) &&
                isAuthenticatedAsAmbassador(requestUser)));

        const hasAmbassadorBrandOption = article &&
            isArticleOfReviewsCategory(article.category.slug)
                ? !article.editableByAmbassadorOfBrandId
                    ? 'canSet'
                    : isAuthenticatedAsAmbassadorOfBrand(requestUser, article.editableByAmbassadorOfBrandId)
                        ? 'canClear'
                        : null
                : null;

        const hasEdit = article && (isAdmin ||
            (isArticleOfReviewsCategory(article.category.slug)
                && isAuthenticatedAsAmbassadorOfBrand(requestUser, article.editableByAmbassadorOfBrandId)));
        const hasAdminclub = article && isAdmin;
        const hasSeoStats = article && requestUserPermissions.indexOf('users.can_access_dash') >= 0;
        const hasReport = !!article;
        const isReviewCategory = hasVal(reviewCategoryArticlesCount);

        const menu =
            hasCreate || hasAmbassadorBrandOption || hasEdit || hasAdminclub || hasSeoStats || hasReport ? (
                <Menu
                    key="menu"
                    menuId={WIKI_MENU_MORE}
                    trigger={<Btn type={BtnType.BlueOutline} icon={fasCogLg} onClick={onWikiToggleMenuMore} />}
                >
                    {hasCreate && (
                        <MenuItem
                            label={WIKI_ARTICLE_ADD_NEW}
                            icon={faPlusCircle}
                            link={wikiArticleCreateUrl}
                            isAdminUI
                        />
                    )}
                    {hasAmbassadorBrandOption === 'canSet' && (
                        <MenuItem
                            label={WIKI_ARTICLE_AMBASSADOR_BRAND_SET}
                            icon={faCopyright}
                            onClick={this.onSetAmbassadorBrandCache(() => onSetAmbassadorBrand(article.id, true))}
                            isAdminUI
                        />
                    )}
                    {hasAmbassadorBrandOption === 'canClear' && (
                        <MenuItem
                            label={WIKI_ARTICLE_AMBASSADOR_BRAND_CLEAR}
                            icon={faCopyright}
                            onClick={this.onSetAmbassadorBrandCache(() => onSetAmbassadorBrand(article.id, false))}
                            isAdminUI
                        />
                    )}
                    {hasReport && (
                        <MenuItem
                            label={WIKI_REPORT_MISTAKE_IN_ARTICLE}
                            icon={faExclamationTriangle}
                            link={url(
                                faqAskQuestionUrl,
                                {},
                                {
                                    faq: 'wiki-suggestion',
                                    next: baseUrl + wikiArticleUrl(article),
                                },
                            )}
                            isAdminUI={isAdmin}
                            asAnchor
                        />
                    )}

                    {hasEdit && (
                        <MenuItem
                            label={WIKI_ARTICLE_EDIT}
                            icon={faPencil}
                            link={url(wikiArticleEditUrl, { articleId: article.id })}
                            isAdminUI
                        />
                    )}

                    {hasAdminclub && (
                        <MenuItem
                            label={FORUM_POST_ADMINCLUB_MENU}
                            icon={faLifeRing}
                            link={adminclub_wiki_article_url(article.id)}
                            asAnchor
                            isAdminUI
                        />
                    )}

                    {hasAdminclub && isArticleOfReviewsCategory(article.category.slug) && (
                        <MenuItem
                            label={`${FORUM_POST_ADMINCLUB_MENU}: Article/Vendor relations`}
                            icon={faLifeRing}
                            link={adminclubArticlesAndVendors}
                            isAdminUI
                        />
                    )}

                    {hasAdminclub && isArticleOfReviewsCategory(article.category.slug) && (
                        isReviewHomepageArticle(article.category.slug, article.slug) ? (
                            <MenuItem
                                label={`${FORUM_POST_ADMINCLUB_MENU}: Create review category`}
                                icon={faLifeRing}
                                link={adminclubReviewCategoryCreate}
                                isAdminUI
                            />
                        ) : isReviewCategory && (
                            <MenuItem
                                label={`${FORUM_POST_ADMINCLUB_MENU}: Edit review category`}
                                icon={faLifeRing}
                                link={url(adminclubReviewCategoryEdit, { articleId: article.id })}
                                isAdminUI
                            />
                        )
                    )}

                    {hasSeoStats && (
                        <MenuItem
                            label="SEO traffic"
                            icon={faChartLine}
                            link={url(
                                dashSeoPerformaceUrl,
                                {},
                                {
                                    range1: DateRange1[DateRange1.Past12Weeks],
                                    urls: `pageid:wiki-article-${article.id}`,
                                    keywords: KeywordFilterType[KeywordFilterType.NonBranded],
                                },
                            )}
                            asAnchor
                            linkTarget="_blank"
                            isAdminUI
                        />
                    )}
                </Menu>
            ) : null;

        // Vrat null ak nie je ani menu ani add button
        if (!menu && !addBtn) {
            return null;
        }

        return (
            <>
                {menu}
                {!!menu && !!addBtn && '\u00A0\u00A0'}
                {addBtn}
            </>
        );
    }

    public getHeaderMenuSelection(app: AppName, props: Props): MenuSelection {
        return {
            activeSection: app,
            activeItem: props.article ? props.article.slug : null,
        };
    }

    public registerInStore(store: Store<AppState>) {
        store.injectReducer('containers.wiki.article', articlesReducer);
        store.injectSaga('containers.wiki.article', articlesSaga);
    }

    public getAddExperienceBtn(props: Props): React.ReactElement {
        const { article, requestUser } = props;
        const addExpUrl = url(wikiAddExperienceUrl, {
            articleSlug: article.slug,
            categorySlug: article.category.slug,
        });
        if (article.experiencesAllowed) {
            return (
                <TrackClick
                    name="wiki_experience_add_clicked"
                    key="addBtn"
                    props={{
                        article_id: article.id,
                        category_slug: article.category.slug,
                        article_slug: article.slug,
                    }}
                >
                    <Btn
                        key="addBtn"
                        type={BtnType.Blue}
                        icon={faPencil}
                        label={WIKI_WRITE_EXPERIENCE}
                        link={
                            isAuthenticated(requestUser)
                                ? addExpUrl
                                : url(loginUrl, {},  {next: addExpUrl})
                        }
                    />
                </TrackClick>
            );
        }
        return null;
    }
}
