// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const GROUPS_JOIN_SAME_DATE_DOMAIN_GROUP_name = "P\u0159idej se do term\u00ednov\u00e9 skupiny %(name)s";
export const lo_TO_up = "%(lo)s a\u017e %(up)s";
export const number_DAYS = ["%(number)s den", "%(number)s dny", "%(number)s dn\u016f"];
export const number_WEEKS = ["%(number)s t\u00fdden", "%(number)s t\u00fddny", "%(number)s t\u00fddn\u016f"];
export const PREGNANCY_AVERAGE_FETAL_LENGTH = "Pr\u016fm\u011brn\u00e1 d\u00e9lka plodu";
export const PREGNANCY_AVERAGE_FETAL_WEIGHT = "Pr\u016fm\u011brn\u00e1 hmotnost plodu";
export const PREGNANCY_CALCULATOR_READ_MORE_ABOUT_week_IN_WIKI = "P\u0159e\u010dti si v\u00edce o %(week)s. t\u00fddnu t\u011bhotenstv\u00ed";
export const PREGNANCY_CALCULATOR_SUBSCRIBE_PREGNANCY_NEWSLETTER = "P\u0159ihlas se do newsletteru: T\u011bhotenstv\u00ed t\u00fdden po t\u00fddnu";
export const PREGNANCY_CALCULATOR_SUBSCRIBE_PREGNANCY_NEWSLETTER_TEXT = "Zaregistruj se na tento t\u011bhotensk\u00fd newsletter a ka\u017ed\u00fd t\u00fdden tv\u00e9ho t\u011bhotenstv\u00ed dostane\u0161 emailem praktick\u00e9 a l\u00e9ka\u0159em ov\u011b\u0159en\u00e9 informace o tom, co t\u011b \u010dek\u00e1 a jak se vyv\u00edj\u00ed tv\u00e9 d\u011b\u0165\u00e1tko.";
export const PREGNANCY_DAYS_TILL_DUE_DATE = "Po\u010det dn\u00ed do porodu";
export const PREGNANCY_DUE_DATE_RANGE = "\u010casov\u00e9 rozmez\u00ed pro porod (38+0 a\u017e 42+0)";
export const PREGNANCY_NEWSLETTER_EXPERIMENT_TEXT_ENDING = "Zjisti, jak\u00e9 l\u00e9ka\u0159sk\u00e9 prohl\u00eddky je vhodn\u00e9 v tomto t\u00fddnu absolvovat v na\u0161em bezplatn\u00e9m t\u011bhotensk\u00e9m newsletteru!";
export const PREGNANCY_NEWSLETTER_week_NEXT_WEEK = "V\u00ed\u0161, co t\u011b \u010dek\u00e1 v p\u0159\u00ed\u0161t\u00edm (%(week)s.) t\u00fddnu?";
export const PREGNANCY_WEEK = "T\u00fdden t\u011bhotenstv\u00ed";
export const PREGNANCY_WEEK_DAY_MEDICAL = "L\u00e9ka\u0159sk\u00e9 ozna\u010den\u00ed doby t\u011bhotenstv\u00ed";
export const SIGNUP_AND_JOIN_GROUP = "Registruj se a p\u0159idej se do skupiny";
export const SIGNUP_DUE_DATE = "Term\u00edn porodu";
export const TRIMESTER = "Trimestr";
