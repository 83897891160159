import { ANONYMOUS_USERNAME } from 'mk/autogenerated/translations/mentionize.dbe4263eedc0aeba4c53c8d0fde5d83e'
import { reactFilter, TextLike } from 'mk/bazaar/common/filters/utils';
import { isAnonymousUsername } from 'mk/bazaar/common/userUtils';
import { blogsProfileUrl } from 'mk2/apps/blogs/urls';
import { Link } from 'mk2/components/Link';
import { url } from 'mk2/helpers/urls';
import React from 'react';

const MENTION_RE = /(?:\s|^)@([a-zA-Z0-9.][a-zA-Z0-9._]+)/;

export const mentionize = (text: TextLike) => reactFilter(text, (s: string): React.ReactChild[] => {
    const ret = [];

    const matcher = new RegExp(MENTION_RE, 'g');
    let from = 0;
    while (true) {
        // calling matcher repeatadly moves it through the whole string 's'
        const match = matcher.exec(s);
        if (match) {
            const username = match[1].toLowerCase();
            const to = matcher.lastIndex - username.length - 1;

            // copy text before current mention (empty string are removed by reactFilter)
            ret.push(s.substring(from, to));

            if (isAnonymousUsername(username)) {
                ret.push(`@${ANONYMOUS_USERNAME}`);
            } else {
                // replace @username with link
                ret.push(<span className="mention">
                    @<Link to={url(blogsProfileUrl, { username })}>{username}</Link>
                </span>);
            }

            from = matcher.lastIndex;
        } else {
            // copy text after last mention
            ret.push(s.substring(from));
            break;
        }
    }

    return ret;
});
