import Loadable from 'mk2/helpers/Loadable';
import { Overlay } from 'react-overlays';

export const OverlayLoadable = Loadable<Overlay['props'], any>({
    loader: () =>
        import('react-overlays' /* webpackChunkName: "react-overlays" */).then((module) => {
            return module.Overlay;
        }),
    modules: ['react-overlays'],
    webpack: () => [require.resolveWeak('react-overlays')],
    loading: () => null,
});
