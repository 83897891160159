import cx from 'classnames';
import React from 'react';
import styles from './Panel.mscss';

interface OwnProps {
    id?: string;
    className?: string;
    style?: React.CSSProperties;
    tag?: string;
    innerPadding?: number;
    children?: React.ReactNode;
}

type Props = OwnProps;

export const Panel = React.forwardRef<HTMLElement, Props>((
    { id, className, style, children, innerPadding, tag = 'div' },
    ref,
) => {
    const Tag: any = tag;

    return (
        <Tag
            className={cx(styles.Panel, className)}
            style={innerPadding ? {padding: innerPadding, ...style} : style}
            ref={ref}
            id={id}
        >
            {children}
        </Tag>
    );
});

Panel.displayName = 'Panel';
