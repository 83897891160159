import { faChevronDown, faLifeRing } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import { FORUM_POST_ADMINCLUB_MENU } from 'mk/autogenerated/translations/ExperienceMenu.f1dfff4cb6d2051ec3452d2408dac8a5'
import { adminclub_wiki_experiences_detail_url } from 'mk/urls/functions';
import { WIKI_EXPERIENCE_MENU_MORE } from 'mk2/apps/wiki/helpers';
import { MenuItem } from 'mk2/components/menu/MenuItem';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Menu } from 'mk2/containers/Menu/Menu';
import { ExperienceEntity } from 'mk2/schemas';
import React from 'react';
import styles from './ExperienceMenu.mscss';

interface OwnProps {
    className?: string;
    experience: ExperienceEntity;

    onToggleMenuMore(experienceId: number);
}

type Props = OwnProps;

export default class ExperienceMenu extends React.Component<Props> {

    public render() {
        const {className, experience} = this.props;

        return (
            <Menu
                className={cx(styles.ExperienceMenu, className)}
                menuId={WIKI_EXPERIENCE_MENU_MORE(experience.id)}
                trigger={
                    <Btn
                        type={BtnType.GreyText}
                        icon={faChevronDown}
                        onClick={this.handleOnToggleMenuMore}
                    />
                }
            >
                <MenuItem
                    className={styles.ExperienceMenu__item}
                    label={FORUM_POST_ADMINCLUB_MENU}
                    icon={faLifeRing}
                    link={adminclub_wiki_experiences_detail_url(experience.id)}
                    asAnchor
                    isAdminUI
                />
            </Menu>
        );
    }

    private handleOnToggleMenuMore = () => {
        const { experience, onToggleMenuMore } = this.props;
        onToggleMenuMore(experience.id);
    };
}
