import { FormValues } from 'mk2/containers/PregnancyCalculator/PregnancyCalculatorForm';
import { errorMessage } from 'mk2/reducers/errorMessage';
import { loadingState, LoadingState } from 'mk2/reducers/loadingState';
import { Reducer } from 'redux';
import {
    PregnancyCalculatorLoadApiFailureAction,
    PregnancyCalculatorLoadApiRequestAction,
    PregnancyCalculatorLoadApiSuccessAction,
    PregnancyCalculatorSubmitApiFailureAction,
    PregnancyCalculatorSubmitApiRequestAction,
    PregnancyCalculatorSubmitApiSuccessAction,
    PREGNANCY_CALCULATOR_LOAD_API_FAILURE,
    PREGNANCY_CALCULATOR_LOAD_API_REQUEST,
    PREGNANCY_CALCULATOR_LOAD_API_SUCCESS,
    PREGNANCY_CALCULATOR_SUBMIT_API_FAILURE,
    PREGNANCY_CALCULATOR_SUBMIT_API_REQUEST,
    PREGNANCY_CALCULATOR_SUBMIT_API_SUCCESS,
} from './PregnancyCalculator.actions';

export interface State {
    loadingState: LoadingState;
    errorMessage: string;
    csvContentId: number;
    week: number;
    day: number;
    dueDate: string;
    groupId: number;
    groupMemberId: number;
    initialValues: FormValues;
    nextWeek: number;
}

export const initialState: State = {
    loadingState: LoadingState.INIT,
    errorMessage: null,
    csvContentId: null,
    week: null,
    day: null,
    dueDate: null,
    groupId: null,
    groupMemberId: null,
    initialValues: {
        inputDateType: 'last-period-start',
        inputDate: null,
    },
    nextWeek: null,
};

type PregnancyCalculatorActions =
    | PregnancyCalculatorLoadApiRequestAction
    | PregnancyCalculatorLoadApiSuccessAction
    | PregnancyCalculatorLoadApiFailureAction
    | PregnancyCalculatorSubmitApiRequestAction
    | PregnancyCalculatorSubmitApiSuccessAction
    | PregnancyCalculatorSubmitApiFailureAction;

export const reducer: Reducer<State, PregnancyCalculatorActions> = (state = initialState, action) => {
    switch (action.type) {
        case PREGNANCY_CALCULATOR_LOAD_API_REQUEST:
        case PREGNANCY_CALCULATOR_LOAD_API_FAILURE:
        case PREGNANCY_CALCULATOR_SUBMIT_API_REQUEST:
        case PREGNANCY_CALCULATOR_SUBMIT_API_FAILURE: {
            return {
                ...state,
                loadingState: loadingState(state.loadingState, action),
                errorMessage: errorMessage(state.errorMessage, action),
            };
        }
        case PREGNANCY_CALCULATOR_LOAD_API_SUCCESS: {
            return {
                ...state,
                loadingState: loadingState(state.loadingState, action),
                errorMessage: errorMessage(state.errorMessage, action),
                csvContentId: action.response.result.csvContent,
            };
        }
        case PREGNANCY_CALCULATOR_SUBMIT_API_SUCCESS: {
            return {
                ...state,
                loadingState: loadingState(state.loadingState, action),
                errorMessage: errorMessage(state.errorMessage, action),
                week: action.week,
                day: action.day,
                dueDate: action.dueDate,
                groupId: action.response.result.group,
                groupMemberId: action.response.result.groupMember,
                initialValues: { ...action.values },
                nextWeek: action.response.result.nextWeek,
            };
        }

        default:
            return state;
    }
};

export default reducer;
