import cx from 'classnames';
import { MenuDropdown } from 'mk2/components/menu/MenuDropdown';
import { MenuModal } from 'mk2/components/menu/MenuModal';
import { menuToggle } from 'mk2/containers/Menu/Menu.actions';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { getMenuShow, getRequestDeviceMobile, AppState } from 'mk2/reducers';
import React from 'react';
import { connect } from 'react-redux';
import styles from './Menu.mscss';

interface ContainerOwnProps extends OwnProps {
    show: boolean;
    isMobile: boolean;
    target: React.ReactInstance | (() => React.ReactInstance);

    onClose();
}

class Container extends React.Component<ContainerOwnProps> {

    public render() {

        const { isMobile, dropdownPositionTop, dropdownPositionRight, target, trigger, ...rest } = this.props;

        return (
            <div className={styles.Menu__menu}>
                {!isMobile ? (
                    <MenuDropdown
                        container={this}
                        target={target}
                        offsetTop={dropdownPositionTop}
                        offsetRight={dropdownPositionRight}
                        {...rest}
                    />
                ) : (
                    <MenuModal {...rest} />
                )}
            </div>
        );
    }
}

interface OwnProps {
    className?: string;
    menuId: string;
    trigger: React.ReactNode; // Menu trigger

    dropdownPositionTop?: number | string;
    dropdownPositionRight?: number | string;
    triggerShadow?: boolean;
    children: React.ReactNode;
}

interface StateProps {
    show: boolean;
    isMobile: boolean;
}

interface DispatchProps {
    onClose(menuId: string, show: boolean);
}

type Props = OwnProps & StateProps & DispatchProps;

class MenuComponent extends React.PureComponent<Props> {

    public static defaultProps = {
        triggerShadow: true,
    };

    private trigger: HTMLElement;
    private container: HTMLDivElement;

    private dispose: () => void;

    public componentDidMount() {
        this.attachClickOutsideHandler();
    }

    public componentDidUpdate() {
        this.attachClickOutsideHandler();
    }

    public componentWillUnmount() {
        if (this.dispose) {
            this.dispose();
        }
    }

    public render() {
        const { className, isMobile, menuId, onClose, show, trigger, triggerShadow, children } = this.props;

        if (!trigger) {
            throw new Error('You must specify trigger element');
        }
        if (trigger && !React.isValidElement(trigger)) {
            throw new Error('Trigger must be a valid element');
        }

        // Check if we have some children
        if (!React.Children.toArray(children).some(Boolean)) {
            return null;
        }

        const close = () => onClose(menuId, false);

        return (
            <div
                className={cx(styles.Menu, className)}
                ref={this.handleContainerRef}
            >
                <div className={styles.Menu__offsetParent}>
                    {trigger && (
                        <div className={cx(styles.Menu__trigger, !isMobile && show && triggerShadow && styles['Menu__trigger--shadowed'])}>
                            {React.isValidElement<{ ref: any }>(trigger)
                                ? React.cloneElement(trigger, { ref: this.setTrigger })
                                : trigger}
                        </div>
                    )}

                    <Container {...this.props} onClose={close} target={this.getTrigger} />
                </div>
            </div>
        );
    }

    private attachClickOutsideHandler() {
        if (this.dispose) {
            this.dispose();
            this.dispose = null;
        }

        const { isMobile, show, onClose, menuId } = this.props;

        const listener = (e) => {
            if (!isDescendant(e.target, this.container)) {
                onClose(menuId, false);
            }
        };

        // For modal we don't need global click outside handler
        if (!isMobile && show) {
            window.addEventListener('click', listener, true);

            this.dispose = () => {
                window.removeEventListener('click', listener, true);
            };
        }
    }

    private handleContainerRef = (ref: HTMLDivElement) => {
        this.container = ref;
    };

    private getTrigger = () => this.trigger;
    private setTrigger = (ref: HTMLElement) => {
        this.trigger = ref;
    };
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
    show: getMenuShow(state, ownProps.menuId),
    isMobile: getRequestDeviceMobile(state),
});

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
    onClose: menuToggle,
};

export const Menu = connect(mapStateToProps, mapDispatchToProps)(MenuComponent);

function isDescendant(child, parent) {
    let node = child.parentNode;
    while (node !== null) {
        if (node === parent) {
            return true;
        }
        node = node.parentNode;
    }

    return false;
}
