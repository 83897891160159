import cx from 'classnames';
import { ModalLoadable } from 'mk2/components/ModalLoadable';
import React from 'react';
import styles from './Modal.mscss';

interface OwnProps {
    className?: string;
    show: boolean;
    onHide?: () => void;
    onShow?: () => void;
    container?: React.ReactNode | (() => HTMLElement);
    backdrop?: boolean | 'static';
    style?: React.CSSProperties;
}

export class Modal extends React.Component<OwnProps> {
    public render() {
        const { className, children, ...rest } = this.props;

        return (
            <ModalLoadable className={cx(styles.Modal, className)} {...rest} renderBackdrop={this.renderBackdrop}>
                {children}
            </ModalLoadable>
        );
    }

    private renderBackdrop = (props) => <div {...props} className={styles.Modal__backdrop} />;
}
