import {
    PROFILE_WAS_SAVED,
} from 'mk/autogenerated/translations/Profile.sagas.5affbc425b607b761e8848d8a897cbe9'
import {
    settings_api_profile_save_url,
    settings_api_profile_url,
} from 'mk/urls/functions';
import { ProfileFormComponent, SETTINGS_PROFILE_FORM_NAME } from 'mk2/apps/settings/components/ProfileForm';
import {
    profileFetchApi,
    profileSaveApi,
    ProfileFetchNormalizedResponse,
    ProfileFetchTriggerAction,
    ProfileSaveNormalizedResponse,
    ProfileSaveTriggerAction,
    PROFILE_FETCH_TRIGGER,
    PROFILE_SAVE_TRIGGER,
} from 'mk2/apps/settings/containers/Profile/Profile.actions';
import { ProfileEntity, ProfileSchema } from 'mk2/apps/settings/schemas';
import { handleXHRGetErrorSaga, normalizeError, XHRAction, XHRFormError, XHRGetError } from 'mk2/helpers/api';
import { handleXHRFormErrorSaga, handleXHRFormSuccessSaga } from 'mk2/helpers/form';
import { getLogger } from 'mk2/logger';
import { UserEntity, UserSchema } from 'mk2/schemas';
import { normalize } from 'normalizr';
import { all, call, put, takeLatest } from 'redux-saga/effects';

const logger = getLogger('settings/Profile.sagas');

interface ApiResponseFetch {
    body: {
        profile: ProfileEntity;
    };
}

function* fetch({ username, xhr }: ProfileFetchTriggerAction & XHRAction) {
    yield put(profileFetchApi.request(username));

    try {
        const response: ApiResponseFetch = yield call(
            () => xhr.get(settings_api_profile_url(username)),
        );
        const normalizedResponse: ProfileFetchNormalizedResponse = normalize(response.body, {
            profile: ProfileSchema,
        });

        yield put(profileFetchApi.success(username, normalizedResponse));
    } catch (error) {
        yield handleXHRGetErrorSaga(error as XHRGetError, logger);
        yield put(profileFetchApi.failure(username, normalizeError(error)));
    }
}

interface ApiResponseSave {
    body: {
        profile: ProfileEntity;
        requestUser: UserEntity;
    };
}

function* save({ username, profileData, xhr }: ProfileSaveTriggerAction & XHRAction) {
    yield put(profileSaveApi.request(username));

    try {
        const response: ApiResponseSave = yield call(
            () => xhr.post(settings_api_profile_save_url(username), { profileData }),
        );
        const normalizedResponse: ProfileSaveNormalizedResponse = normalize(response.body, {
            profile: ProfileSchema,
            requestUser: UserSchema,
        });

        const reinitValues = ProfileFormComponent.initialValues(response.body.profile);
        yield handleXHRFormSuccessSaga(SETTINGS_PROFILE_FORM_NAME, null, PROFILE_WAS_SAVED, reinitValues);
        yield put(profileSaveApi.success(username, response.body.requestUser, normalizedResponse));
    } catch (error) {
        yield handleXHRFormErrorSaga(SETTINGS_PROFILE_FORM_NAME, error as XHRFormError, logger);
        yield put(profileSaveApi.failure(username, normalizeError(error)));
    }
}

export default function* root() {
    yield all([
        takeLatest(PROFILE_FETCH_TRIGGER, fetch),
        takeLatest(PROFILE_SAVE_TRIGGER, save),
    ]);
}
