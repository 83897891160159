import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastAdd } from 'mk2/actions';
import { LoadError } from 'mk2/components/errors/LoadError';
import { ServerErrorToast } from 'mk2/components/toasts/ServerErrorToast';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { getResponseRenderedAt, AppState } from 'mk2/reducers';
import { isFailure, isSuccess, LoadingState } from 'mk2/reducers/loadingState';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import styles from './LoadingSwitch.mscss';

interface OwnProps {
    // current state of loading
    loadingState: LoadingState;

    hasData: boolean;

    rerender?: number;

    // called when loading should be initiated
    onLoad();

    // called to render init/success/failure state
    onRenderInit?(): React.ReactNode;
    onRenderSuccess?(): React.ReactNode;
    onRenderFailure?(): React.ReactNode;

    onFailureToast?();
}

interface StateProps {
    renderedAt: number;
}

interface DispatchProps {
    onFailureToastDefault();
}

interface DispatchProps {}

type RouteProps = RouteComponentProps<{}>;

type Props = OwnProps & StateProps & DispatchProps & RouteProps;

class LoadingSwitchComponent extends React.Component<Props> {

    public UNSAFE_componentWillMount() {
        const { loadingState, renderedAt, hasData, history } = this.props;

        // load() ma zmysel zavolat iba ked ideme na nove url. Ak robime goBack() tak nechceme
        // nacitat novy obsah, ale ukazat povodny obsah a obnovit povodnu scrollPosition kde uzivatelka bola.
        const goingBack = history.action === 'POP';
        if (goingBack && hasData) {
            return;
        }

        // Load only if not loaded on server
        //
        // CeMi: co bolo presne zamyslane touto podmienkou? lebo teraz sa deje:
        // 1) nacitat prvu url zo ssr, tym sa nastavi renderedAt
        // 2) ak potom chodim po stranke a nacitavam si rozne stranky, ich stav bude LoadingState.SUCCESS
        // 3) to znamena, ze po 30sec od nacitania prvej url, sa budu okamzite reloadovat vsetky stranky co
        //    uz mam nacitane z clienta (su v stave LoadingState.SUCCESS)
        if ((loadingState !== LoadingState.SUCCESS_ON_SERVER && loadingState !== LoadingState.FAILURE_ON_SERVER) || renderedAt < Date.now() - 30 * 1000) {
            this.props.onLoad();
        }
    }

    public componentDidUpdate(prevProps: OwnProps) {
        if (prevProps.loadingState !== LoadingState.INIT &&
            this.props.loadingState === LoadingState.INIT) {
                this.props.onLoad();
            }
        // State change LOADING -> FAILURE and we have some posts
        if (prevProps.loadingState === LoadingState.LOADING
            && this.props.loadingState === LoadingState.FAILURE
            && this.props.hasData) {
                this.props.onFailureToast
                    ? this.props.onFailureToast()
                    : this.props.onFailureToastDefault();
        }
    }

    public render(): React.ReactNode {
        const { loadingState, onRenderInit, onRenderSuccess, onRenderFailure, hasData } = this.props;

        if (loadingState === null || loadingState === LoadingState.INIT || (loadingState === LoadingState.LOADING && !hasData)) {
            return onRenderInit
                ? onRenderInit()
                : (
                    <div className={styles.LoadingSwitch__init}>
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                );
        } else if (isFailure(loadingState) && !hasData) {
            return onRenderFailure
                ? onRenderFailure()
                : (
                    <LoadError onRetry={this.props.onLoad} />
                );
        } else if (isSuccess(loadingState) || hasData) {
            return onRenderSuccess ? onRenderSuccess() : null;
        } else {
            // should not happen
            return null;
        }
    }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    return {
        renderedAt: getResponseRenderedAt(state),
    };
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
    onFailureToastDefault: () => toastAdd('error', {
        component: ServerErrorToast,
    }),
};

const ConnectedLoadingSwitch = connect(mapStateToProps, mapDispatchToProps)(withRouter(LoadingSwitchComponent));

export const LoadingSwitch: React.FunctionComponent<Pick<OwnProps, Exclude<keyof OwnProps, 'rerender'>>> = (props) => {
    return (
        // Force rerender when this component renders so that we always call onRenderSuccess when parent is rendering
        <ConnectedLoadingSwitch rerender={Math.random()} {...props} />
    );
};
