import { animate } from 'mk2/helpers/animations';

type ElementScrollDestination = 'window-top' | 'window-bottom';

/**
 * Scrolls element to a given position on screen
 *
 * @param {string} elementSelector element to be scrolled
 * @param {ElementScrollDestination} to - where the element should be scrolled to
 * @param {number} distanceFromEdge - what distance should the element keep from top or bottom
 *  * @param animateScroll - if true, animates scrolls through document towards final scroll destination
 */
export function scrollElementTo(
    elementSelector: string,
    to: ElementScrollDestination,
    distanceFromEdge: number = 0,
    animateScroll: boolean = false,
) {
    if (typeof window === 'undefined') {
        return;
    }

    const scrollEl = document.querySelector(elementSelector);
    if (!scrollEl) {
        throw new Error('Scroll element not found!');
    }

    let y;
    if (to === 'window-top') {
        y = window.pageYOffset + scrollEl.getBoundingClientRect().top - distanceFromEdge;
    } else if (to === 'window-bottom') {
        y = window.pageYOffset + scrollEl.getBoundingClientRect().bottom - window.innerHeight + distanceFromEdge;
    }

    animateScroll ? animateScrollTo(y) : window.scrollTo(0, y);
}

type DocumentScrollDestination = 'top' | 'bottom';

/**
 * Scrolls document (whole page) to a given position on screen
 *
 * @param {DocumentScrollDestination} to - where the document should be scrolled to
 * @param {number} distanceFromEdge - what distance should the element keep from top or bottom
 * @param animateScroll - if true, animates scrolls through document towards final scroll destination
 */
export function scrollDocumentTo(
    to: DocumentScrollDestination,
    distanceFromEdge: number = 0,
    animateScroll: boolean = false,
) {
    if (typeof window === 'undefined') {
        return;
    }

    let y;
    if (to === 'top') {
        y = distanceFromEdge;
    } else if (to === 'bottom') {
        y = document.body.clientHeight - distanceFromEdge;
    }

    animateScroll ? animateScrollTo(y) : window.scrollTo(0, y);
}

export function animateScrollTo(y: number, scrollDuration: number = 500, done?: () => void) {
    animate(window.pageYOffset, y, scrollDuration, done)((value: number) => {
        window.scrollTo(0, value);
    });
}
