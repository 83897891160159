import { VendorReplyOnExperienceFormData } from 'mk2/apps/wiki/components/VendorReplyOnExperienceForm';
import { ReviewCategoriesStats } from 'mk2/apps/wiki/containers/Article/Article.reducers';
import {
    APIFailureAction,
    APIRequestAction,
    APISuccessAction,
} from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { ArticleConceptPath } from 'mk2/schemas';

///////////////////////////////////////////////////////////////////
// Article load

export const ARTICLE_FETCH_TRIGGER = 'WIKI_ARTICLE_FETCH_TRIGGER';  // WIKI_ prefix aby sa nemiesalo s akciami pre Article v blogoch
export interface ArticleFetchTriggerAction {
    readonly type: typeof ARTICLE_FETCH_TRIGGER;
    readonly articleSlug: string;
    readonly categorySlug: string;
}
export const ARTICLE_FETCH_REQUEST = 'WIKI_ARTICLE_FETCH_REQUEST';
export interface ArticleFetchRequestAction extends APIRequestAction {
    readonly type: typeof ARTICLE_FETCH_REQUEST;
    readonly articleSlug: string;
    readonly categorySlug: string;
}
export interface ArticleFetchNormalizedResponse extends NormalizedResponse {
    readonly result: {
        article: number;
        relatedArticles: number[];
        reviewCategoryArticlesCount: number;
        reviewCategoryArticles: number[];
        reviewCategoryArticlesExperiences: number[];
        reviewCategories: number[];
        reviewCategoriesStats: ReviewCategoriesStats;
        similarPosts: number[];
        eshopProductsOfEshopNodes: Record<string, number[]>;
    };
}
export const ARTICLE_FETCH_SUCCESS = 'WIKI_ARTICLE_FETCH_SUCCESS';
export interface ArticleFetchSuccessAction extends APISuccessAction<ArticleFetchNormalizedResponse> {
    readonly type: typeof ARTICLE_FETCH_SUCCESS;
    readonly articleSlug: string;
    readonly categorySlug: string;
    readonly articleConceptPath: ArticleConceptPath[];
    readonly viewsCountUnique: number;
    readonly viewsCountTotal: number;
}
export const ARTICLE_FETCH_FAILURE = 'WIKI_ARTICLE_FETCH_FAILURE';
export interface ArticleFetchFailureAction extends APIFailureAction {
    readonly type: typeof ARTICLE_FETCH_FAILURE;
    readonly articleSlug: string;
    readonly categorySlug: string;
}

export const articleFetchTrigger =
    (articleSlug: string, categorySlug: string): ArticleFetchTriggerAction => ({
        type: ARTICLE_FETCH_TRIGGER, articleSlug, categorySlug,
    });
export const articleFetchApi = {
    request: (articleSlug: string, categorySlug: string): ArticleFetchRequestAction => ({
        type: ARTICLE_FETCH_REQUEST, articleSlug, categorySlug,
    }),
    success: (
        articleSlug: string,
        categorySlug: string,
        articleConceptPath: ArticleConceptPath[],
        viewsCountUnique: number,
        viewsCountTotal: number,
        response: ArticleFetchNormalizedResponse,
    ): ArticleFetchSuccessAction => ({
        type: ARTICLE_FETCH_SUCCESS,
        articleSlug,
        categorySlug,
        articleConceptPath,
        viewsCountUnique,
        viewsCountTotal,
        response,
    }),
    failure: (articleSlug: string, categorySlug: string, error: any): ArticleFetchFailureAction => ({
        type: ARTICLE_FETCH_FAILURE, articleSlug, categorySlug, error,
    }),
};

///////////////////////////////////////////////////////////////////
// Article load more review category's articles

export const ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_TRIGGER = 'ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_TRIGGER';
export interface ArticleLoadMoreReviewCategoryArticlesTriggerAction {
    readonly type: typeof ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_TRIGGER;
    readonly articleSlug: string;
    readonly categorySlug: string;
    readonly after: number;
}
export const ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_REQUEST = 'ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_REQUEST';
export interface ArticleLoadMoreReviewCategoryArticlesRequestAction extends APIRequestAction {
    readonly type: typeof ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_REQUEST;
    readonly articleSlug: string;
    readonly categorySlug: string;
    readonly after: number;
}
export interface ArticleLoadMoreReviewCategoryArticlesNormalizedResponse extends NormalizedResponse {
    readonly result: {
        article: number,
        moreReviewCategoryArticles: number[],
    };
}
export const ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_SUCCESS = 'ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_SUCCESS';
export interface ArticleLoadMoreReviewCategoryArticlesSuccessAction extends APISuccessAction<ArticleLoadMoreReviewCategoryArticlesNormalizedResponse> {
    readonly type: typeof ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_SUCCESS;
    readonly articleSlug: string;
    readonly categorySlug: string;
    readonly after: number;
}
export const ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_FAILURE = 'ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_FAILURE';
export interface ArticleLoadMoreReviewCategoryArticlesFailureAction extends APIFailureAction {
    readonly type: typeof ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_FAILURE;
    readonly articleSlug: string;
    readonly categorySlug: string;
    readonly after: number;
}

export const articleLoadMoreReviewCategoryArticlesTrigger =
    (articleSlug: string, categorySlug: string, after: number): ArticleLoadMoreReviewCategoryArticlesTriggerAction => ({
        type: ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_TRIGGER, articleSlug, categorySlug, after,
    });
export const articleLoadMoreReviewCategoryArticlesApi = {
    request: (articleSlug: string, categorySlug: string, after: number): ArticleLoadMoreReviewCategoryArticlesRequestAction => ({
        type: ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_REQUEST, articleSlug, categorySlug, after,
    }),
    success: (articleSlug: string, categorySlug: string, after: number, response: ArticleLoadMoreReviewCategoryArticlesNormalizedResponse): ArticleLoadMoreReviewCategoryArticlesSuccessAction => ({
        type: ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_SUCCESS, articleSlug, categorySlug, after, response,
    }),
    failure: (articleSlug: string, categorySlug: string, after: number, error: any): ArticleLoadMoreReviewCategoryArticlesFailureAction => ({
        type: ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_FAILURE, articleSlug, categorySlug, after, error,
    }),
};

// ///////////////////////////////////////////////////////////////////
// Article page view

export const ARTICLE_PAGE_VIEW = 'WIKI_ARTICLE_PAGE_VIEW';
export interface ArticlePageViewTriggerAction {
    readonly type: typeof ARTICLE_PAGE_VIEW;
    readonly categorySlug: string;
    readonly articleSlug: string;
}

export const articlePageViewTrigger =
    (categorySlug: string, articleSlug: string): ArticlePageViewTriggerAction => ({
        type: ARTICLE_PAGE_VIEW, categorySlug, articleSlug,
    });

///////////////////////////////////////////////////////////////////
// Article - save vendor reply on experience

export const ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_TRIGGER = 'WIKI_ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_TRIGGER';
export interface ArticleSaveVendorReplyOnExperienceTriggerAction {
    readonly type: typeof ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_TRIGGER;
    readonly experienceId: number;
    readonly values: VendorReplyOnExperienceFormData;
}
export const ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_REQUEST = 'WIKI_ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_REQUEST';
export interface ArticleSaveVendorReplyOnExperienceRequestAction extends APIRequestAction {
    readonly type: typeof ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_REQUEST;
    readonly experienceId: number;
    readonly values: VendorReplyOnExperienceFormData;
}
export interface ArticleModifyVendorReplyOnExperienceNormalizedResponse extends NormalizedResponse {
    readonly result: {
        experience: number,
    };
}
export const ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_SUCCESS = 'WIKI_ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_SUCCESS';
export interface ArticleSaveVendorReplyOnExperienceSuccessAction extends APISuccessAction<ArticleModifyVendorReplyOnExperienceNormalizedResponse> {
    readonly type: typeof ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_SUCCESS;
    readonly experienceId: number;
    readonly values: VendorReplyOnExperienceFormData;
}
export const ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_FAILURE = 'WIKI_ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_FAILURE';
export interface ArticleSaveVendorReplyOnExperienceFailureAction extends APIFailureAction {
    readonly type: typeof ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_FAILURE;
    readonly experienceId: number;
    readonly values: VendorReplyOnExperienceFormData;
}

export const articleSaveVendorReplyOnExperienceTrigger =
    (experienceId: number, values: VendorReplyOnExperienceFormData): ArticleSaveVendorReplyOnExperienceTriggerAction => ({
        type: ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_TRIGGER, experienceId, values,
    });
export const articleSaveVendorReplyOnExperienceApi = {
    request: (experienceId: number, values: VendorReplyOnExperienceFormData): ArticleSaveVendorReplyOnExperienceRequestAction => ({
        type: ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_REQUEST, experienceId, values,
    }),
    success: (
        experienceId: number,
        values: VendorReplyOnExperienceFormData,
        response: ArticleModifyVendorReplyOnExperienceNormalizedResponse,
    ): ArticleSaveVendorReplyOnExperienceSuccessAction => ({
        type: ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_SUCCESS,
        experienceId,
        values,
        response,
    }),
    failure: (experienceId: number, values: VendorReplyOnExperienceFormData, error: any): ArticleSaveVendorReplyOnExperienceFailureAction => ({
        type: ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_FAILURE, experienceId, values, error,
    }),
};

///////////////////////////////////////////////////////////////////
// Article - delete vendor reply on experience

export const ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_TRIGGER = 'WIKI_ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_TRIGGER';
export interface ArticleDeleteVendorReplyOnExperienceTriggerAction {
    readonly type: typeof ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_TRIGGER;
    readonly experienceId: number;
}
export const ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_REQUEST = 'WIKI_ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_REQUEST';
export interface ArticleDeleteVendorReplyOnExperienceRequestAction extends APIRequestAction {
    readonly type: typeof ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_REQUEST;
    readonly experienceId: number;
}
export const ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_SUCCESS = 'WIKI_ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_SUCCESS';
export interface ArticleDeleteVendorReplyOnExperienceSuccessAction extends APISuccessAction<ArticleModifyVendorReplyOnExperienceNormalizedResponse> {
    readonly type: typeof ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_SUCCESS;
    readonly experienceId: number;
}
export const ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_FAILURE = 'WIKI_ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_FAILURE';
export interface ArticleDeleteVendorReplyOnExperienceFailureAction extends APIFailureAction {
    readonly type: typeof ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_FAILURE;
    readonly experienceId: number;
}

export const articleDeleteVendorReplyOnExperienceTrigger =
    (experienceId: number): ArticleDeleteVendorReplyOnExperienceTriggerAction => ({
        type: ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_TRIGGER, experienceId,
    });
export const articleDeleteVendorReplyOnExperienceApi = {
    request: (experienceId: number): ArticleDeleteVendorReplyOnExperienceRequestAction => ({
        type: ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_REQUEST, experienceId,
    }),
    success: (
        experienceId: number,
        response: ArticleModifyVendorReplyOnExperienceNormalizedResponse,
    ): ArticleDeleteVendorReplyOnExperienceSuccessAction => ({
        type: ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_SUCCESS,
        experienceId,
        response,
    }),
    failure: (experienceId: number, error: any): ArticleDeleteVendorReplyOnExperienceFailureAction => ({
        type: ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_FAILURE, experienceId, error,
    }),
};

///////////////////////////////////////////////////////////////////
// Set or clear the article's ambassador brand reference

export const ARTICLE_SET_AMBASSADOR_BRAND_TRIGGER = 'WIKI_ARTICLE_SET_AMBASSADOR_BRAND_TRIGGER';
export interface ArticleSetAmbassadorBrandTriggerAction {
    readonly type: typeof ARTICLE_SET_AMBASSADOR_BRAND_TRIGGER;
    readonly articleId: number;
    readonly setAmbassadorBrand: boolean;
}
export const ARTICLE_SET_AMBASSADOR_BRAND_REQUEST = 'WIKI_ARTICLE_SET_AMBASSADOR_BRAND_REQUEST';
export interface ArticleSetAmbassadorBrandRequestAction extends APIRequestAction {
    readonly type: typeof ARTICLE_SET_AMBASSADOR_BRAND_REQUEST;
    readonly articleId: number;
    readonly setAmbassadorBrand: boolean;
}
export interface ArticleSetAmbassadorBrandNormalizedResponse extends NormalizedResponse {
    readonly result: {
        article: number;
    };
}
export const ARTICLE_SET_AMBASSADOR_BRAND_SUCCESS = 'WIKI_ARTICLE_SET_AMBASSADOR_BRAND_SUCCESS';
export interface ArticleSetAmbassadorBrandSuccessAction extends APISuccessAction<ArticleSetAmbassadorBrandNormalizedResponse> {
    readonly type: typeof ARTICLE_SET_AMBASSADOR_BRAND_SUCCESS;
    readonly articleId: number;
    readonly setAmbassadorBrand: boolean;
}
export const ARTICLE_SET_AMBASSADOR_BRAND_FAILURE = 'WIKI_ARTICLE_SET_AMBASSADOR_BRAND_FAILURE';
export interface ArticleSetAmbassadorBrandFailureAction extends APIFailureAction {
    readonly type: typeof ARTICLE_SET_AMBASSADOR_BRAND_FAILURE;
    readonly articleId: number;
    readonly setAmbassadorBrand: boolean;
}

export const articleSetAmbassadorBrandTrigger =
    (articleId: number, setAmbassadorBrand: boolean): ArticleSetAmbassadorBrandTriggerAction => ({
        type: ARTICLE_SET_AMBASSADOR_BRAND_TRIGGER, articleId, setAmbassadorBrand,
    });
export const articleSetAmbassadorBrandApi = {
    request: (articleId: number, setAmbassadorBrand: boolean): ArticleSetAmbassadorBrandRequestAction => ({
        type: ARTICLE_SET_AMBASSADOR_BRAND_REQUEST, articleId, setAmbassadorBrand,
    }),
    success: (
        articleId: number,
        setAmbassadorBrand: boolean,
        response: ArticleSetAmbassadorBrandNormalizedResponse,
    ): ArticleSetAmbassadorBrandSuccessAction => ({
        type: ARTICLE_SET_AMBASSADOR_BRAND_SUCCESS,
        articleId,
        setAmbassadorBrand,
        response,
    }),
    failure: (articleId: number, setAmbassadorBrand: boolean, error: any): ArticleSetAmbassadorBrandFailureAction => ({
        type: ARTICLE_SET_AMBASSADOR_BRAND_FAILURE, articleId, setAmbassadorBrand, error,
    }),
};
