import cx from 'classnames';
import {
    ARTICLE_EXPERIENCE_SEND,
    BLOGS_ADD_NEW_COMMENT_PLACEHOLDER,
    FORMS_DEFAULT_REQUIRED_FIELD_ERROR,
} from 'mk/autogenerated/translations/VendorReplyOnExperienceForm.62d412c6abb14ef9e72332dfe55d4bd2'
import { SubmitButton } from 'mk2/components/forms/SubmitButton';
import { TextareaField } from 'mk2/components/forms/TextareaField';
import { BtnType } from 'mk2/components/Btn';
import { Form } from 'mk2/components/Form';
import { mkReduxForm, MKReduxFormInjectedProps } from 'mk2/decorators/mkReduxForm';
import React from 'react';
import { FormErrors } from 'redux-form';
import styles from './VendorReplyOnExperienceForm.mscss';

export const vendorReplyOnExperienceFormName = (experienceId: number) => `VENDOR_REPLY_ON_EXPERIENCE_FORM_${experienceId}`;

export interface VendorReplyOnExperienceFormData {
    message: string;
}

interface OwnProps {
    className?: string;
    experienceId: number;
    onSave(experienceId: number, values: VendorReplyOnExperienceFormData);
}

type Props = OwnProps;

class VendorReplyOnExperienceForm extends React.Component<Props & MKReduxFormInjectedProps<VendorReplyOnExperienceFormData, Props>> {
    public render() {
        const { className, handleSubmit, form } = this.props;

        return (
            <Form className={cx(styles.VendorReplyOnExperienceForm, className)} onSubmit={handleSubmit}>
                <div className={styles.VendorReplyOnExperienceForm__textarea}>
                    <TextareaField
                        name="message"
                        rows={1}
                        maxRows={7}
                        placeholder={(
                            // kvoli ios, musime vykrelist ako placeholder={} a nie ako hint={},
                            // aby bol kurzor dobre zaindentovany
                            BLOGS_ADD_NEW_COMMENT_PLACEHOLDER
                        )}
                        bottomBorder={false}
                        isCompact
                    />
                </div>

                <div className={styles.VendorReplyOnExperienceForm__actionButtons}>
                    <SubmitButton
                        label={ARTICLE_EXPERIENCE_SEND}
                        formName={form}
                        btnType={BtnType.Moderator}
                    />
                </div>
            </Form>
        );
    }
}

function validate(values: VendorReplyOnExperienceFormData): FormErrors<VendorReplyOnExperienceFormData> {
    const errors: FormErrors<VendorReplyOnExperienceFormData> = {};
    if (!values.message) {
        errors.message = FORMS_DEFAULT_REQUIRED_FIELD_ERROR;
    }
    return errors;
}

export const VendorReplyOnExperienceFormFactory = (formName: string) => mkReduxForm<VendorReplyOnExperienceFormData, Props>({
    formName,
    onValidate: validate,
    onSave: (values, props) => props.onSave(props.experienceId, values),
})(VendorReplyOnExperienceForm);
