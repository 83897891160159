import cx from 'classnames';
import { Location } from 'history';
import { wikiArticleUrl } from 'mk2/apps/wiki/utils';
import { LinkListItem } from 'mk2/components/LinkListItem';
import { Panel } from 'mk2/components/Panel';
import { TrackClick } from 'mk2/components/TrackClick';
import { ArticleLinkDataEntity } from 'mk2/schemas';
import React from 'react';
import styles from './WikiArticles.mscss';

interface OwnProps {
    className?: string;
    articlesData: ArticleLinkDataEntity[];
    isMobile: boolean;
    location: Location;
}

type Props = OwnProps;

export class WikiArticles extends React.PureComponent<Props> {

    public render() {
        const { className, articlesData, isMobile, location } = this.props;

        const twoCols = !isMobile;

        return (
            <Panel className={cx(styles.WikiArticles, className)}>
                <ul
                    className={cx(
                        styles.WikiArticles__list,
                        twoCols ? styles[`WikiArticles__list--twoCols`] : styles[`WikiArticles__list--oneCol`],
                    )}
                >
                    {articlesData.map((article, idx) => (
                        <TrackClick
                            key={article.id}
                            name="wiki_where_next_clicked"
                            props={{
                                from: `${location.pathname}${location.search}`,
                                to: wikiArticleUrl(article),
                                article_id: article.id,
                            }}
                        >
                            <LinkListItem
                                className={cx(
                                    styles.WikiArticles__item,
                                    // prvy item v prvom stlpci ma margin-top: -5px, to iste
                                    // potrebujeme nastavit aj prvemu itemu v druhom stlpci
                                    // idx === 0 && styles['WikiArticles__item--killTopMargin'], uz robi <LinkListItem>
                                    twoCols && idx === 1 && styles['WikiArticles__item--killTopMargin'],
                                    // zrus spodny margin, aby bol rovnako vysoky okraj na vrchu aj spodku panela
                                    idx === articlesData.length - 1 && styles['WikiArticles__item--killBottomMargin'],
                                    twoCols && idx === articlesData.length - 2 && styles['WikiArticles__item--killBottomMargin'],
                                    twoCols && (idx % 2 === 0
                                        ? styles['WikiArticles__item--col1']
                                        : styles['WikiArticles__item--col2']
                                    ),
                                )}
                                key={article.id}
                                to={wikiArticleUrl(article)}
                                trimLongTitle={false}
                                fontSize={16}
                                title={(
                                    <>
                                        {article.title}

                                        {article.experiencesCount > 0 &&
                                            <span className={styles.WikiArticles__titleSuperscript}>
                                                {article.experiencesCount}
                                            </span>
                                        }
                                    </>
                                )}
                            />
                        </TrackClick>
                    ))}
                </ul>
            </Panel>
        );
    }

}
