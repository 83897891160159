import {
    users_api_pregnancy_calculator_load_url,
    users_api_pregnancy_calculator_submit_url,
} from 'mk/urls/functions';
import { GroupMemberSchema, GroupSchema } from 'mk2/apps/blogs/schemas';
import {
    pregnancyCalculatorLoadApi,
    pregnancyCalculatorSubmitApi,
    PregnancyCalculatorLoadAction,
    PregnancyCalculatorLoadNormalizedResponse,
    PregnancyCalculatorSubmitAction,
    PregnancyCalculatorSubmitNormalizedResponse,
    PREGNANCY_CALCULATOR_LOAD, PREGNANCY_CALCULATOR_SUBMIT,
} from 'mk2/containers/PregnancyCalculator/PregnancyCalculator.actions';
import { PREGNANCY_CALCULATOR_FORM_NAME } from 'mk2/containers/PregnancyCalculator/PregnancyCalculatorForm';
import {
    handleXHRGetErrorSaga,
    normalizeError,
    XHRAction,
    XHRFormError,
    XHRGetError,
} from 'mk2/helpers/api';
import { mergeDenormalized } from 'mk2/helpers/denormalized';
import { handleXHRFormErrorSaga, handleXHRFormSuccessSaga } from 'mk2/helpers/form';
import { scrollElementTo } from 'mk2/helpers/scrolling';
import { getLogger } from 'mk2/logger';
import { ContentItemSchema } from 'mk2/schemas';
import { getRoutingLocation } from 'mk2/selectors';
import { normalize } from 'normalizr';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

const logger = getLogger('users/PregnancyCalculator.sagas');

interface ApiResponseLoad {
    body: {
        entities: PregnancyCalculatorLoadNormalizedResponse['entities'],
        result: PregnancyCalculatorLoadNormalizedResponse['result'],
    };
}

function* load({ xhr }: PregnancyCalculatorLoadAction & XHRAction) {
    yield put(pregnancyCalculatorLoadApi.request());
    try {
        const location = yield select(getRoutingLocation);
        const response: ApiResponseLoad = yield call(() =>
            xhr.get(users_api_pregnancy_calculator_load_url(), {
                // previous url (from standard window.document.referrer)
                pageRef: (typeof window !== 'undefined') ? window.document.referrer : '',

                // previous url (within pwa from react router)
                pwaRef: location.state?.UNSAFE_mkGoBackUrl || '',
            }),
        );
        yield put(pregnancyCalculatorLoadApi.success(
            {
                entities: response.body.entities,
                result: response.body.result,
            },
        ));
    } catch (error) {
        yield handleXHRGetErrorSaga(error as XHRGetError, logger);
        yield put(pregnancyCalculatorLoadApi.failure(normalizeError(error)));
    }
}

interface ApiResponseSubmit {
    body: {
        week: number;
        day: number;
        dueDate: string;
        group: number;
        groupMember: number;
        nextWeek: number;
        entities: PregnancyCalculatorSubmitNormalizedResponse['entities'];
        result: PregnancyCalculatorSubmitNormalizedResponse['result'];
    };
}

function* submit({ values, xhr }: PregnancyCalculatorSubmitAction & XHRAction) {
    yield put(pregnancyCalculatorSubmitApi.request(values));

    try {
        const location = yield select(getRoutingLocation);
        const response: ApiResponseSubmit = yield call(() =>
            xhr.post(users_api_pregnancy_calculator_submit_url(), {
                values,

                // previous url (from standard window.document.referrer)
                pageRef: (typeof window !== 'undefined') ? window.document.referrer : '',

                // previous url (within pwa from react router)
                pwaRef: location.state?.UNSAFE_mkGoBackUrl || '',
            }),
        );

        const { entities, result, ...restResponse } = response.body;
        const normalizedResponse = normalize({
            group: response.body.group,
            groupMember: response.body.groupMember,
        }, {
            group: GroupSchema,
            groupMember: GroupMemberSchema,
        });

        const mergedResponse = mergeDenormalized(normalizedResponse, {
            entities,
            result,
        });

        yield handleXHRFormSuccessSaga(PREGNANCY_CALCULATOR_FORM_NAME, null, null, values);
        yield put(pregnancyCalculatorSubmitApi.success(
            mergedResponse,
            values,
            response.body.week,
            response.body.day,
            response.body.dueDate,
        ));
        setTimeout(() => {
            scrollElementTo('#pregnancy-calculator-results-table', 'window-top', 150, true);
        }, 100);
    } catch (error) {
        yield handleXHRFormErrorSaga(PREGNANCY_CALCULATOR_FORM_NAME, error as XHRFormError, logger);
        yield put(pregnancyCalculatorSubmitApi.failure(values, normalizeError(error)));
    }
}

export default function* root() {
    yield all([takeLatest(PREGNANCY_CALCULATOR_LOAD, load)]);
    yield all([takeLatest(PREGNANCY_CALCULATOR_SUBMIT, submit)]);
}
