import cx from 'classnames';
import { isAnonymousUser } from 'mk/bazaar/common/userUtils';
import assetUrl from 'mk/common/assetUrl';
import { blogsProfileUrl } from 'mk2/apps/blogs/urls';
import { ANONYMOUS_POST_HELP_LINK } from 'mk2/apps/forum/constants';
import { fixUsername } from 'mk2/apps/users/utils';
import { AvatarImg } from 'mk2/components/AvatarImg';
import { Img } from 'mk2/components/Img';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { url } from 'mk2/helpers/urls';
import { UserEntity } from 'mk2/schemas';
import React from 'react';
import styles from './UserChip.mscss';

interface OwnProps {
    user: UserEntity;
    showAvatar?: boolean;
    showUsername?: boolean;
    disabled?: boolean;
    className?: string;
    size?: 16 | 18 | 24 | 28 | 32 | 36 | 40 | 50 | 150;
    spaceBetween?: 4 | 10; // px between avatar and username
    alignOnBaseline?: boolean;
    boldUsername?: boolean;
    vertical?: boolean;
}

export class UserChip extends React.Component<OwnProps> {
    public static defaultProps: Partial<OwnProps> = {
        vertical: false,
        size: 50,
        spaceBetween: 10,
        showAvatar: true,
        showUsername: true,
        disabled: false,
        boldUsername: false,
    };

    public shouldComponentUpdate(n: OwnProps) {
        const p = this.props;

        return (
            n.user.username !== p.user.username ||
            n.disabled !== p.disabled ||
            n.className !== n.className ||
            // From AvatarImg
            n.user.avatar !== p.user.avatar ||
            n.user.avatarCampaign !== p.user.avatarCampaign ||
            n.user.activePlan !== p.user.activePlan
        );
    }

    public render() {
        const {
            user,
            disabled,
            className,
            showUsername,
            showAvatar,
            size,
            vertical,
            spaceBetween,
            boldUsername,
            alignOnBaseline,
        } = this.props;

        const isAnonUser = isAnonymousUser(user);
        const profileUrl: string = url(blogsProfileUrl, { username: user.username });

        return (
            <Link
                className={cx(
                    styles.UserChip,
                    disabled && styles['UserChip--disabled'],
                    vertical && styles['UserChip--vertical'],
                    alignOnBaseline && styles['UserChip--alignOnBaseline'],
                    className,
                )}
                to={isAnonUser ? ANONYMOUS_POST_HELP_LINK : profileUrl}
            >
                {showAvatar && (
                    isAnonUser ? (
                        <Img
                            className={cx(styles.UserChip__avatar, disabled && styles['UserChip__avatar--disabled'])}
                            src={`${assetUrl}img/avatars/user/zorro-avatar.png`}
                            width={size}
                            height={size}
                        />
                    ) : (
                        <AvatarImg
                            className={cx(styles.UserChip__avatar, disabled && styles['UserChip__avatar--disabled'])}
                            size={size}
                            user={user}
                        />
                    )
                )}

                {showUsername && (
                    <div
                        className={cx(
                            styles.UserChip__username,
                            showAvatar && styles[`UserChip__username--${vertical ? 'v' : 'h'}-indent-${spaceBetween}`],
                            boldUsername && styles['UserChip__username--bold'],
                        )}
                    >
                        {fixUsername(user.username)}
                    </div>
                )}
            </Link>
        );
    }
}

interface UserChipPlaceholderOwnProps {
    className?: string;
    size?: 16 | 18 | 24 | 28 | 32 | 36 | 40 | 50 | 150;
    vertical?: boolean;
    showAvatar?: boolean;
    showUsername?: boolean;
    disabled?: boolean;
    spaceBetween?: 4 | 10; // px between avatar and username
}

export const UserChipPlaceholder: React.StatelessComponent<UserChipPlaceholderOwnProps> = ({
    className,
    size = 50,
    vertical = false,
    showAvatar = true,
    showUsername = true,
    disabled = false,
    spaceBetween = 10,
}) => (
    <span
        className={cx(
            styles.UserChip,
            disabled && styles['UserChip--disabled'],
            vertical && styles['UserChip--vertical'],
            className,
        )}
    >
        {showAvatar && <LoadingPlaceholder width={size} height={size} />}

        {showUsername && (
            <div
                className={cx(
                    styles.UserChip__username,
                    showAvatar && styles[`UserChip__username--${vertical ? 'v' : 'h'}-indent-${spaceBetween}`],
                )}
            >
                <LoadingPlaceholder width={80} />
            </div>
        )}
    </span>
);
