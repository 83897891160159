// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const FORUM_POST_ADMINCLUB_MENU = "Adminclub";
export const PREGNANCY_NEWSLETTER = "T\u011bhotensk\u00fd newsletter";
export const PREGNANCY_NEWSLETTER_DESCRIPTION = "Ka\u017ed\u00fd t\u00fdden dostane\u0161 nejd\u016fle\u017eit\u011bj\u0161\u00ed info do e-mailu";
export const RELATED_DISCUSSIONS_IN_FORUM = "Souvisej\u00edc\u00ed diskuze ve f\u00f3ru";
export const SEO_TITLE_WIKI_article = "%(article)s";
export const WIKI_ARTICLE_ADD_NEW = "P\u0159idej nov\u00fd \u010dl\u00e1nek";
export const WIKI_ARTICLE_AMBASSADOR_BRAND_CLEAR = "Odpoj ambasadorskou zna\u010dku";
export const WIKI_ARTICLE_AMBASSADOR_BRAND_SET = "P\u0159ipoj ambasadorskou zna\u010dku";
export const WIKI_ARTICLE_EDIT = "Uprav";
export const WIKI_REPORT_MISTAKE_IN_ARTICLE = "Nahlas chybu v \u010dl\u00e1nku";
export const WIKI_WRITE_EXPERIENCE = "Napi\u0161 zku\u0161enost";
