import { LoadError } from 'mk2/components/errors/LoadError';
import { NotFoundError } from 'mk2/components/errors/NotFoundError';
import React from 'react';
import { StaticContext } from 'react-router';

interface OwnProps {
    errorMessage: string;
    staticContext: StaticContext;

    backButton?: React.ReactNode;

    onRetry?(): void;
}

export class Failure extends React.Component<OwnProps> {

    public render() {
        const { errorMessage, backButton, onRetry, staticContext } = this.props;

        // ak este nikto nenastavil http error status (napr. 403 - Forbidden z @authOnly,
        // alebo 410 - Gone z render()) tak nastav podla hodnoty v errorMessage
        if (errorMessage && errorMessage.toUpperCase() === 'NOT FOUND' && backButton) {
            if (staticContext && staticContext.statusCode === 200) {
                staticContext.statusCode = 404;
            }
            return <NotFoundError button={backButton} />;
        } else {
            if (staticContext && staticContext.statusCode === 200) {
                staticContext.statusCode = 500;
            }
            return <LoadError onRetry={onRetry} />;
        }
    }

}
