import { contestsPostDetailUrl } from 'mk2/apps/contests/urls';
import { counsellingsPostDetailUrl, counsellingsTopicUrl } from 'mk2/apps/counsellings/urls';
import { COUNSELLING_CATEGORY_ID } from 'mk2/apps/forum/constants';
import { PostEntity, TopicEntity } from 'mk2/apps/forum/schemas';
import { forumPostDetailUrl, forumTopicUrl } from 'mk2/apps/forum/urls';
import { isContestPost, isCounsellingPost } from 'mk2/apps/forum/utils';
import { url } from 'mk2/helpers/urls';

export const FORUM_POST_MENU_MORE = (id: number) => `forum-post-menu-more-${id}`;
export const FORUM_COMMENT_MENU_MORE = (id: number) => `forum-comment-menu-more-${id}`;

/**
 * Helper for generating post URLs -> because we have same logic for /ask-an-expert/ and /forum/ posts
 */
export const postDetailUrl = (post: PostEntity, ...rest) =>
    isContestPost(post)
        ? url(contestsPostDetailUrl, { postSlug: post.slug })
        : url(isCounsellingPost(post) ? counsellingsPostDetailUrl : forumPostDetailUrl, {
            topicSlug: post.topic.slug,
            postSlug: post.slug,
        }, ...rest);

/**
 * Helper for generating topic URLs -> because we have same logic for /ask-an-expert/ and /forum/ posts
 */
export function topicUrl(topic: TopicEntity, ...rest): string {
    const urlPattern = topic.category.id === COUNSELLING_CATEGORY_ID
         ? counsellingsTopicUrl
         : forumTopicUrl;

    return url(urlPattern, {topicSlug: topic.slug}, ...rest);
}
