import cx from 'classnames';
import { FormControl } from 'mk2/components/forms/FormControl';
import { autoFocusElement, AutoFocus } from 'mk2/helpers/form';
import React from 'react';
import { Field, WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';
import styles from './BirthDateField.mscss';

interface BirthDateInputPublicProps {
    autoFocus?: AutoFocus;
    disabled?: boolean;
}

type BirthDateInputProps = BirthDateInputPublicProps  & {
    input: WrappedFieldInputProps;
};

class BirthDateInput extends React.Component<BirthDateInputProps> {

    private inputElem: HTMLInputElement;

    public render() {
        const { input, disabled } = this.props;

        return (
            <input
                id={input.name}
                className={cx(styles.BirthDateInput, disabled && styles['BirthDateInput--disabled'])}
                type="date"

                {...input}
                ref={this.handleInputRef}
                disabled={disabled}
            />
        );
    }

    public componentDidMount() {
        autoFocusElement(this.inputElem, this.props.autoFocus, this.props.input.value);
    }

    private handleInputRef = (ref: HTMLInputElement) => {
        this.inputElem = ref;
    };
}

interface BirthDateControlPublicProps {
    label?: string;
    marginLeft?: boolean;
    bottomBorder?: boolean;
}

type BirthDateControlProps = BirthDateInputPublicProps & BirthDateControlPublicProps & WrappedFieldProps;

const BirthDateControl: React.StatelessComponent<BirthDateControlProps> = ({
    input, meta, label,                                                // WrappedFieldProps
    marginLeft, bottomBorder,                                          // BirthDateControlPublicProps
    ...bdInputProps                                                    // BirthDateInputPublicProps
}) => (
    <FormControl
        input={input}
        meta={meta}
        label={label}
        marginLeft={marginLeft}
        bottomBorder={bottomBorder}
        minimizedLabel={true /* always minimize, otherwise label overlaps with this field *//* tslint:disable-line */}
        disabled={bdInputProps.disabled}
    >
        <BirthDateInput
            input={input}
            {...bdInputProps}
        />
    </FormControl>
);

type BirthDateFieldProps = BirthDateInputPublicProps & BirthDateControlPublicProps & {
    // see https://redux-form.com/7.3.0/docs/api/field.md/#props-you-can-pass-to-code-field-code-
    name: string;
};

export const BirthDateField: React.StatelessComponent<BirthDateFieldProps> = (props) => (
    <Field {...props} component={BirthDateControl} />
);
