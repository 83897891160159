import cx from 'classnames';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import React from 'react';
import styles from './PanelHeading.mscss';

interface OwnProps {
    className?: string;
    title: string | React.ReactNode;
    logo?: React.ReactNode;
    url?: string;

    onClick?(event: React.MouseEvent<HTMLAnchorElement>);
}

type Props = OwnProps;

export const PanelHeading: React.FunctionComponent<Props> = ({ className, title, url, onClick, logo }) => {
    const content = (
        <h4 className={styles.PanelHeading__title}>
            {title}
        </h4>
    );

    return (
        <div className={cx(styles.PanelHeading, className)}>
            {url ? (
                <Link to={url} onClick={onClick} className={styles.PanelHeading__link}>
                    {content}
                </Link>
            ) : content}
            {logo}
        </div>
    );
};

PanelHeading.displayName = 'PanelHeading';

export const PanelHeadingPlaceholder: React.FunctionComponent<{}> = () => {
    return (
        <div className={styles.PanelHeading}>
            <h4 className={styles.PanelHeading__title}>
                <LoadingPlaceholder width={150} />
            </h4>
        </div>
    );
};
