import { faCamera, faTimes } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import {
    ABOUT_ME,
    ADD_AVATAR,
    AVATAR_PHOTO_RULES_LINE1,
    AVATAR_PHOTO_RULES_LINE2,
    AVATAR_PHOTO_RULES_LINE3,
    AVATAR_PHOTO_RULES_LINE4,
    AVATAR_PHOTO_RULES_TITLE,
    BOY,
    CHANGE_AVATAR,
    CHILD_NAME,
    DELETE_AVATAR,
    DO_NOT_PLAN_IN_CLOSE_FUTURE,
    DO_NOT_WANT_TO_TELL,
    ENTER_BIRTH_DATE,
    FEMALE,
    GIRL,
    IT_WILL_BE_SURPRISE,
    MALE,
    OR_POSSIBLE_VARIANTS_IF_YOU_ARE_NOT_SURE,
    PREGNANCY_STATE,
    PREGNANT,
    PREGNANT_WITH,
    REMOVE_PHOTO,
    RESIDENCE_CITY,
    RESIDENCE_COUNTY,
    SETTINGS_BIRTH_DATE_WE_ONLY_SHOW_AGE,
    SETTINGS_DUE_DATE,
    SETTINGS_DUE_DATE_INFO,
    SIGNUP_LOCATION_COUNTRY,
    TRIPLETS,
    TWINS_BOY_AND_GIRL,
    TWINS_BOYS,
    TWINS_GIRLS,
    TWINS_SURPRISE,
    WE_ARE_TRYING_TO_CONCEIVE,
    WE_DONT_KNOW,
} from 'mk/autogenerated/translations/ProfileForm.490f2e61260ea148e495c7d571df3f31'
import { countiesSelectOptions, getPlacesSelectOptionsForCounty } from 'mk/geo';
import { photoUrl } from 'mk/photo/photoUrl';
import AvatarBlockedBox from 'mk2/apps/settings/components/AvatarBlockedBox';
import { PregnancyState, PregnantWith, ProfileEntity, SexType } from 'mk2/apps/settings/schemas';
import { getGenericAvatar } from 'mk2/apps/users/utils';
import { DateSelectField } from 'mk2/components/forms/DateSelectField';
import { InputField } from 'mk2/components/forms/InputField';
import { PhotosFieldNew } from 'mk2/components/forms/PhotosFieldNew';
import { PhotoCropField } from 'mk2/components/forms/PhotoCropField';
import { RadioField, RadioOption } from 'mk2/components/forms/RadioField';
import { Option, SelectField } from 'mk2/components/forms/SelectField';
import { TextareaField } from 'mk2/components/forms/TextareaField';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Form } from 'mk2/components/Form';
import { Img } from 'mk2/components/Img';
import { FormPageType } from 'mk2/constants/enums';
import { PhotosUploadConfig } from 'mk2/containers/PhotosUpload/PhotosUpload';
import { mkReduxForm, MKReduxFormInjectedProps } from 'mk2/decorators/mkReduxForm';
import { cacheLast } from 'mk2/helpers/cache';
import { PhotoUploadStatus, SuccessfulPhotoUpload } from 'mk2/helpers/form.reducers';
import {
    entitiesWithPhotoPropToSuccessfulPhotoUploads,
} from 'mk2/helpers/photos';
import { AppState } from 'mk2/reducers';
import { PhotoEntity, PhotoSchema } from 'mk2/schemas';
import { getDenormalizedEntity } from 'mk2/selectors';
import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import styles from './ProfileForm.mscss';

export const SETTINGS_PROFILE_FORM_NAME = 'SETTINGS_PROFILE_FORM';
const MIN_AGE_ACCEPTED = 5;  // keep in sync settinx/forms.py
const DUE_YEARS_ACCEPTED = 2;  // keep in sync settinx/forms.py

const SEX_OPTIONS: RadioOption[] = [
    {value: SexType.Girl, label: FEMALE},
    {value: SexType.Boy, label: MALE},
];

const PREGNANCY_STATE_OPTIONS: Option[] = [
    {value: PregnancyState.DoNotTell, label: DO_NOT_WANT_TO_TELL},
    {value: PregnancyState.Pregnant, label: PREGNANT},
    {value: PregnancyState.Trying, label: WE_ARE_TRYING_TO_CONCEIVE},
    {value: PregnancyState.DoNotPlan, label: DO_NOT_PLAN_IN_CLOSE_FUTURE},
];

const PREGNANT_WITH_OPTIONS: Option[] = [
    {value: PregnantWith.WeDontKnow, label: WE_DONT_KNOW},
    {value: PregnantWith.ItWillBeSurprise, label: IT_WILL_BE_SURPRISE},
    {value: PregnantWith.Boy, label: BOY},
    {value: PregnantWith.Girl, label: GIRL},
    {value: PregnantWith.TwinsBoys, label: TWINS_BOYS},
    {value: PregnantWith.TwinsGirls, label: TWINS_GIRLS},
    {value: PregnantWith.TwinsBoyAndGirl, label: TWINS_BOY_AND_GIRL},
    {value: PregnantWith.TwinsSurprise, label: TWINS_SURPRISE},
    {value: PregnantWith.Triplets, label: TRIPLETS},
];

export interface ProfileFormData {
    photos: PhotoEntity[];
    sex: SexType;
    birthDate: string;
    locationCounty: string;
    place: string;
    pregnancyState: PregnancyState;
    dueDate: string;
    pregnantWith: PregnantWith;
    childName: string;
    about: string;
    avatarCrop: any;
}

const photosConfig: PhotosUploadConfig = {
    photoType: 'avatar',
    multiple: false,
    showPhotoCode: false,
};

interface OwnProps {
    isMobile: boolean;
    initialValues: ProfileFormData;
    currentValues: ProfileFormData;
    profile: ProfileEntity;

    onSave(values: ProfileFormData);
    onPrepareUploadPhotos(formName: string, config: PhotosUploadConfig, files: File[], processPhotoUploadOnFormPage: FormPageType, accessKey?: string);
    onRemovePhoto(formName: string);
}

interface DispatchProps {}

interface StateProps {
    showPregnancyDetails: boolean;
    uploadedFoto: PhotoEntity;
    locationCounty: string;
}

type Props = OwnProps & StateProps & DispatchProps;

export class ProfileFormComponent extends React.Component<Props & MKReduxFormInjectedProps<ProfileFormData, Props>> {
    public static initialValues(profile: ProfileEntity): ProfileFormData {
        const photos = entitiesWithPhotoPropToSuccessfulPhotoUploads(profile.photos || []);
        return {
            photos,
            sex: profile.sex,
            birthDate: profile.birthDate,
            locationCounty: (!profile.locationCounty && !!profile.place) ? '0' : profile.locationCounty?.id.toString(),
            place: profile.place?.id.toString(),
            pregnancyState: profile.pregnancyState,
            pregnantWith: profile.pregnantWith,
            dueDate: profile.dueDate,
            childName: profile.childName,
            about: profile.about,
            // avatarCrop: [xPosition, yPosition, width],
            avatarCrop: [0, 0, 100],
        };
    }

    private photosFieldRef: PhotosFieldNew;
    private cachePlacesSelectOption = cacheLast<Option[]>(true);

    public componentDidUpdate(prevProps) {
        const { currentFormPage } = this.props;
        if (prevProps.currentFormPage !== currentFormPage && currentFormPage === FormPageType.ChangeAvatar) {
            return this.handleOpenFileExplorer();
        }
    }

    public render() {
        const { showPregnancyDetails, handleSubmit, profile, uploadedFoto, locationCounty, currentValues } = this.props;

        const placesSelectOptions = this.cachePlacesSelectOption(
            (): Option[] => getPlacesSelectOptionsForCounty(locationCounty),
            locationCounty,
        );

        const photoFromCurrentValues = currentValues?.photos?.[0];
        const savedPhoto = photoFromCurrentValues?.code ? photoFromCurrentValues : null /* currently uploading */;

        return (
            <Form className={styles.ProfileForm} onSubmit={handleSubmit}>
                <div className={styles.ProfileForm__avatarFieldWrapper}>
                    <div className={styles.ProfileForm__avatarField}>
                        <PhotosFieldNew
                            key="photos"
                            name="photos"
                            config={photosConfig}
                            ref={this.handlePhotosFileInputFieldRef}
                            onUploadFiles={this.handleUploadFiles}
                        />
                        {uploadedFoto ?
                            (<div className={styles.ProfileForm__cropAvatar}>
                                <div className={styles.ProfileForm__cropWrapper}>
                                    <PhotoCropField
                                        name="avatarCrop"
                                        photo={uploadedFoto}
                                        marginLeft={false}
                                        bottomBorder={false}
                                    />
                                </div>
                                <Btn
                                    type={BtnType.Link}
                                    label={REMOVE_PHOTO}
                                    onClick={this.handleOnRemoveUploadedPhoto}
                                />
                                <div className={styles.ProfileForm__textWrapper}>
                                    <h3 className={cx(styles.ProfileForm__title, styles.ProfileForm__photoRulesTitle)}>{AVATAR_PHOTO_RULES_TITLE}</h3>
                                </div>
                                <ul className={styles.ProfileForm__photoRules}>
                                    <li>{AVATAR_PHOTO_RULES_LINE1}</li>
                                    <li>{AVATAR_PHOTO_RULES_LINE2}</li>
                                    <li>{AVATAR_PHOTO_RULES_LINE3}</li>
                                    <li>{AVATAR_PHOTO_RULES_LINE4}</li>
                                </ul>
                            </div>) : (
                            <>
                                <Img
                                    className={styles.ProfileForm__avatarField__img}
                                    src={savedPhoto
                                            ? photoUrl(savedPhoto, 's150x150')
                                            : getGenericAvatar(150, profile.sex)
                                    }
                                    width={100}
                                    height={100}
                                    onClick={this.handleOpenFileExplorer}
                                />
                                <div className={styles.ProfileForm__avatarField__btnsWrapper}>
                                    <Btn
                                        type={BtnType.Link}
                                        label={savedPhoto ? CHANGE_AVATAR : ADD_AVATAR}
                                        icon={faCamera}
                                        onClick={this.handleOpenFileExplorer}
                                    />
                                    {savedPhoto &&
                                        <Btn
                                            type={BtnType.Link}
                                            label={DELETE_AVATAR}
                                            icon={faTimes}
                                            onClick={this.handleOnRemoveExistingPhoto}
                                            className={styles.ProfileForm__avatarField__delBtn}
                                            classNames={{
                                                icon: styles.ProfileForm__avatarField__delIcon,
                                            }}
                                        />
                                    }
                                </div>
                            </>
                            )
                        }
                    </div>
                    {profile.blockedAvatar && savedPhoto && <AvatarBlockedBox profile={profile}/>}
                </div>
                <RadioField
                    name="sex"
                    options={SEX_OPTIONS}
                    horizontal
                />
                <DateSelectField
                    name="birthDate"
                    label={ENTER_BIRTH_DATE}
                    yearFrom={1920}
                    yearTo={new Date().getFullYear() - MIN_AGE_ACCEPTED - 1}
                />
                <div className={styles.ProfileForm__info}>
                    {SETTINGS_BIRTH_DATE_WE_ONLY_SHOW_AGE}
                </div>
                <SelectField
                    name="locationCounty"
                    label={RESIDENCE_COUNTY}
                    options={countiesSelectOptions}
                    onChange={this.handleSelectCounty}
                />
                {locationCounty ? (
                    <SelectField
                        name="place"
                        options={placesSelectOptions}
                        label={
                            locationCounty === '0'
                                ? SIGNUP_LOCATION_COUNTRY
                                : RESIDENCE_CITY
                        }
                    />
                ) : null}
                <div className={showPregnancyDetails ? styles.ProfileForm__pregnancyDetails : null}>
                    <SelectField
                        label={PREGNANCY_STATE}
                        name="pregnancyState"
                        options={PREGNANCY_STATE_OPTIONS}
                    />
                    {showPregnancyDetails && (
                        <>
                            <DateSelectField
                                name="dueDate"
                                label={SETTINGS_DUE_DATE}
                                yearFrom={new Date().getFullYear()}
                                yearTo={new Date().getFullYear() + DUE_YEARS_ACCEPTED - 1}
                            />
                            <div className={styles.ProfileForm__info}>
                                {SETTINGS_DUE_DATE_INFO}
                            </div>
                            <SelectField
                                name="pregnantWith"
                                label={PREGNANT_WITH}
                                options={PREGNANT_WITH_OPTIONS}
                            />
                            <InputField name="childName" type="text" label={CHILD_NAME} />
                            <div className={styles.ProfileForm__info}>
                                {OR_POSSIBLE_VARIANTS_IF_YOU_ARE_NOT_SURE}
                            </div>
                        </>
                    )}
                </div>

                <TextareaField
                    name="about"
                    label={ABOUT_ME}
                    rows={1}
                    fillRemainingSpace
                    paddingRight
                    maxLength={499}
                />
            </Form>
        );
    }

    private handleSelectCounty = (event) => {
        const { change, untouch } = this.props;
        change('place', '');
        untouch('place');
    };

    private handlePhotosFileInputFieldRef = (ref: PhotosFieldNew) => {
        this.photosFieldRef = ref;
    };

    private handleUploadFiles = (config: PhotosUploadConfig, files: File[]) => {
        const { onPrepareUploadPhotos } = this.props;
        onPrepareUploadPhotos(SETTINGS_PROFILE_FORM_NAME, config, files, FormPageType.Main);
    };

    private handleOpenFileExplorer = () => {
        this.photosFieldRef.openFileExplorer();
    };

    private handleOnRemoveUploadedPhoto = () => {
        const { onRemovePhoto } = this.props;
        onRemovePhoto(SETTINGS_PROFILE_FORM_NAME);
    };

    private handleOnRemoveExistingPhoto = () => {
        const { change } = this.props;
        change('photos', []);
    };
}

const formSelector = formValueSelector(SETTINGS_PROFILE_FORM_NAME);
function mapStateToProps(state: AppState, ownProp: OwnProps) {
    const showPregnancyDetails: boolean = formSelector(state, 'pregnancyState') === PregnancyState.Pregnant;
    const currentPhotos = formSelector(state, 'photos') as any[] || [];
    const uploadedFoto: PhotoEntity =
        (currentPhotos && currentPhotos.length > 0 && currentPhotos[0].status === PhotoUploadStatus.SUCCESS)
            ? getDenormalizedEntity<PhotoEntity>(state, PhotoSchema, (currentPhotos[0] as SuccessfulPhotoUpload).photoId)
            : null;
    return {
        uploadedFoto,
        showPregnancyDetails,
        locationCounty: formSelector(state, 'locationCounty'),
    };
}

const ConnectedProfileFormComponent = connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    undefined,
)(ProfileFormComponent);

export const ProfileForm = mkReduxForm<ProfileFormData, OwnProps>({
    formName: SETTINGS_PROFILE_FORM_NAME,
    onValidate: () => ({/* nothing to do, always valid */}),
    onSave: (values, props) => props.onSave(values),
})(ConnectedProfileFormComponent);
