import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { count_PEOPLE_THINK_IT_IS_USEFUL, DELETE_REVIEW_REPLY, USEFUL } from 'mk/autogenerated/translations/ExperienceFooter.3c866d8b8823f8361837dfb033130fda'
import { blogsProfileUrl } from 'mk2/apps/blogs/urls';
import { UserChip } from 'mk2/apps/forum/components/UserChip';
import { Btn,  BtnSidePadding, BtnType } from 'mk2/components/Btn';
import { Link } from 'mk2/components/Link';
import Like, { LikeType } from 'mk2/containers/Like/Like';
import { url } from 'mk2/helpers/urls';
import { ExperienceEntity } from 'mk2/schemas';
import { interpolate } from 'mk2/services/i18n';
import React from 'react';
import styles from './ExperienceFooter.mscss';

interface OwnProps {
    experience: ExperienceEntity;
    isAdmin: boolean;
    onDeleteVendorReply?(experienceId: number);
}

type Props = OwnProps;

export default class ExperienceFooter extends React.Component<Props> {

    public render() {
        const { experience, isAdmin, onDeleteVendorReply } = this.props;

        return (
            <div className={styles.ExperienceFooter}>
                <div className={styles.ExperienceFooter__likes}>
                    <div className={styles.ExperienceFooter__likes__likesCount}>
                        {experience.likeable.likesCount > 0 && interpolate(count_PEOPLE_THINK_IT_IS_USEFUL, {count: experience.likeable.likesCount})}
                    </div>
                    <Like
                        id={experience.id}
                        entity="wiki.experience"
                        type={LikeType.IconLabel}
                        label={USEFUL}
                    />
                </div>
                {experience.vendorReplyMessage && experience.vendorReplyUser && (
                    <div className={styles.ExperienceFooter__vendorReply}>
                        <div className={styles.ExperienceFooter__vendorReply__icon}>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                        <div className={styles.ExperienceFooter__vendorReply__reply}>
                            <div className={styles.ExperienceFooter__vendorReply__reply__meta}>
                                <div className={styles.ExperienceFooter__vendorReply__reply__meta__avatar}>
                                    <UserChip
                                        size={24}
                                        user={experience.vendorReplyUser}
                                        showUsername={false}
                                    />
                                </div>
                                <Link to={url(blogsProfileUrl, { username: experience.vendorReplyUser.username })}>
                                    <div className={styles.ExperienceFooter__vendorReply__reply__meta__username}>
                                        {experience.vendorReplyVendor.name || experience.vendorReplyUser.username}
                                    </div>
                                </Link>
                            </div>
                            <div className={styles.ExperienceFooter__vendorReply__reply__message}>
                                {experience.vendorReplyMessage}
                            </div>
                            {onDeleteVendorReply && (experience.article.canVendorReplyOnExperiences || isAdmin) && (
                                <div className={styles.ExperienceFooter__vendorReply__reply__actions}>
                                    <Btn
                                        label={DELETE_REVIEW_REPLY}
                                        onClick={this.handleDeleteVendorReply}
                                        type={
                                            experience.article.canVendorReplyOnExperiences
                                                ? BtnType.ModeratorTextLight
                                                : BtnType.AdminTextLight
                                        }
                                        sidePadding={BtnSidePadding.None}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private handleDeleteVendorReply = () => {
        const { experience, onDeleteVendorReply } = this.props;
        onDeleteVendorReply(experience.id);
    };
}
