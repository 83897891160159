import Loadable from 'mk2/helpers/Loadable';
import { Modal } from 'react-overlays';

export const ModalLoadable = Loadable<Modal['props'], any>({
    loader: () => import('react-overlays' /* webpackChunkName: "react-overlays" */).then((module) => {
        return module.Modal;
    }),
    modules: ['react-overlays'],
    webpack: () => [ require.resolveWeak('react-overlays') ],
});
