import { faStar as farStar } from '@fortawesome/pro-regular-svg-icons';
import { faStar as fasStar, faStarHalfAlt as fasStarHalfAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React from 'react';
import styles from './StarsRating.mscss';

interface OwnProps {
    starsCount: number;
    canShowHalf?: boolean;
    label?: string;
    className?: string;
    classNames?: {  // classNames for inner components
        stars?: string;
        label?: string;
    };
}

export class StarsRating extends React.Component<OwnProps> {
    public render() {
        const {starsCount, canShowHalf, label, className, classNames} = this.props;

        const showHalf = canShowHalf && (starsCount % 1) > 0.1;
        const emptyGreyStar = <FontAwesomeIcon
            icon={farStar}
            className={styles.StarsRating__starsWrapper__icon}
        />;
        const emptyStar = <FontAwesomeIcon
            icon={farStar}
            className={cx(
                styles.StarsRating__starsWrapper__icon,
                styles['StarsRating__starsWrapper__icon--gold'],
            )}
        />;
        const filledStar = <FontAwesomeIcon
            icon={fasStar}
            className={cx(
                styles.StarsRating__starsWrapper__icon,
                styles['StarsRating__starsWrapper__icon--gold'],
            )}
        />;
        const halfStar = <FontAwesomeIcon
            icon={fasStarHalfAlt}
            className={cx(
                styles.StarsRating__starsWrapper__icon,
                styles['StarsRating__starsWrapper__icon--gold'],
            )}
        />;

        return (
            <div className={cx(styles.StarsRating, className)}>
                {label ?
                    <div className={cx(styles.StarsRating__label, classNames && classNames.label)}>
                        {label}
                    </div> : null
                }
                <div className={cx(styles.StarsRating__starsWrapper, classNames && classNames.stars)}>
                    {(starsCount < 0.9 && !showHalf) || starsCount === 0
                        ? <>
                            {emptyGreyStar}
                            {emptyGreyStar}
                            {emptyGreyStar}
                            {emptyGreyStar}
                            {emptyGreyStar}
                        </>
                        : <>
                            {starsCount >= 0.75 ? filledStar : showHalf && starsCount > 0 ? halfStar : emptyStar}
                            {starsCount >= 1.75 ? filledStar : showHalf && starsCount > 1.24 ? halfStar : emptyStar}
                            {starsCount >= 2.75 ? filledStar : showHalf && starsCount > 2.24 ? halfStar : emptyStar}
                            {starsCount >= 3.75 ? filledStar : showHalf && starsCount > 3.24 ? halfStar : emptyStar}
                            {starsCount >= 4.75 ? filledStar : showHalf && starsCount > 4.24 ? halfStar : emptyStar}
                        </>}
                </div>
            </div>
        );
    }
}
