import React from 'react';
import { findDOMNode } from 'react-dom';

export interface OwnProps {
    clamp: number | string;
    useNativeClamp?: boolean;
    ellipsis?: string;
    splitOnChars?: string[];

    onClamp?();
}

type Props = OwnProps;

export class Clamp extends React.Component<Props> {

    public static defaultProps: Partial<OwnProps> = {
        useNativeClamp: true,
        ellipsis: '...',
        splitOnChars: ['.', '-', '–', '—', ' '], // Split on sentences (periods), hypens, en-dashes, em-dashes, and words (spaces)
    };

    private element: HTMLElement;

    private chunks;
    private lastChunk;
    private splitOnChars;
    private splitChar;

    public constructor(props) {
        super(props);

        this.splitOnChars = this.props.splitOnChars.slice(0);
        this.splitChar = this.splitOnChars[0];
    }

    public componentDidMount() {
        window.addEventListener('resize', this.update, false);
        this.run();
    }

    public componentDidUpdate() {
        this.run();
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.update, false);
    }

    public render() {
        const { children } = this.props;

        return React.Children.only(children);
    }

    private run() {
        if (process.env.NODE_ENV === 'test') {
            // Do not run in test environment
            return;
        }

        const node = findDOMNode(this);
        if (typeof node === 'string') {
            return;
        }

        this.element = node as HTMLElement;

        // We have element
        const supportsNativeClamp = typeof (this.element.style as any).webkitLineClamp !== 'undefined';

        const { clamp, useNativeClamp, onClamp } = this.props;
        let clampValue: number;
        if (clamp === 'auto') {
            clampValue = this.getMaxLines();
        } else if (typeof clamp !== 'number') {
            clampValue = this.getMaxLines(parseInt(clamp, 10));
        } else {
            clampValue = clamp;
        }

        const height = this.getMaxHeight(clampValue);
        if (supportsNativeClamp && useNativeClamp && height < this.element.clientHeight) {
            const sty = this.element.style;
            sty.overflow = 'hidden';
            sty.textOverflow = 'ellipsis';
            sty.webkitBoxOrient = 'vertical';
            sty.display = '-webkit-box';
            (sty as any).webkitLineClamp = clampValue;
            // sty.height = `${height}px`;
            if (onClamp) {
                onClamp();
            }
        } else if (height < this.element.clientHeight) {
            this.truncate(this.getLastChild(this.element), height);
            if (onClamp) {
                onClamp();
            }
        }
    }

    private getMaxLines(height?: number) {
        const availableHeight = height || this.element.clientHeight;
        const lineHeight = this.getLineHeight();
        return Math.max(Math.floor(availableHeight / lineHeight), 0);
    }

    private getLineHeight() {
        const computed = window.getComputedStyle(this.element);
        const lineHeight = computed.getPropertyValue('line-height');
        if (lineHeight === 'normal') {
            // Normal line heights vary from browser to browser. The spec recommends
            // a value between 1.0 and 1.2 of the font size.
            return parseFloat(computed.getPropertyValue('font-size')) * 1.2;
        }
        return parseFloat(lineHeight);
    }

    private getMaxHeight(clamp: number) {
        const lineHeight = this.getLineHeight();
        return lineHeight * (clamp + .1); // Add some space for letters below line height
    }

    private getLastChild(element: Node) {
        if (!element.lastChild) {
            return;
        }

        if (element.lastChild.childNodes && element.lastChild.childNodes.length > 0) {
            return this.getLastChild(Array.prototype.slice.call(element.childNodes).pop());
        } else if (!element.lastChild.nodeValue || element.lastChild.nodeValue === '' || element.lastChild.nodeValue === this.props.ellipsis) {
            element.lastChild.parentNode.removeChild(element.lastChild);
            return this.getLastChild(element);
        } else {
            return element.lastChild;
        }
    }

    private truncate(target: Node, maxHeight: number) {
        if (!target || !maxHeight) {
            return;
        }

        const { ellipsis } = this.props;

        const nodeValue = target.nodeValue.replace(ellipsis, '');
        if (!this.chunks) {
            this.splitChar = this.splitOnChars.length > 0 ? this.splitOnChars.shift() : '';
            this.chunks = nodeValue.split(this.splitChar);
        }

        if (this.chunks.length > 1) {
            this.lastChunk = this.chunks.pop();
            this.applyEllipsis(target, this.chunks.join(this.splitChar));
        } else {
            this.chunks = null;
        }

        if (this.chunks) {
            if (this.element.clientHeight <= maxHeight) {
                if (this.splitOnChars.length >= 0 && this.splitChar !== '') {
                    this.applyEllipsis(target, this.chunks.join(this.splitChar) + this.splitChar + this.lastChunk);
                    this.chunks = null;
                } else {
                    return this.element.innerHTML;
                }
            }
        } else {
            if (this.splitChar === '') {
                this.applyEllipsis(target, '');
                target = this.getLastChild(this.element);
                this.reset();
            }
        }

        return this.truncate(target, maxHeight);
    }

    private reset() {
        this.splitOnChars = this.props.splitOnChars.slice(0);
        this.splitChar = this.splitOnChars[0];
        this.chunks = null;
        this.lastChunk = null;
    }

    private applyEllipsis(target: Node, content: string) {
        target.nodeValue = content + this.props.ellipsis;
    }

    private update = () => {
        this.forceUpdate();
    };
}
