import { faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React from 'react';
import styles from './Checkbox.mscss';

interface OwnProps {
    className?: string;
    label: React.ReactNode;
}

type Props = OwnProps & Omit<React.HTMLProps<HTMLInputElement>, 'label'>;

export const Checkbox: React.FunctionComponent<Props> = ({ className, label, checked, id, name, ...rest }) => {
    const htmlId = id || `checkbox-${name}`;
    return (
        <label htmlFor={htmlId} className={cx(styles.Checkbox, className)}>
            <FontAwesomeIcon
                icon={checked ? faCheckSquare : faSquare}
                className={cx(styles.Checkbox__icon, checked && styles['Checkbox__icon--checked'])}
            />
            <input id={htmlId} className={styles.Checkbox__input} type="checkbox" checked={checked} {...rest} />
            {label}
        </label>
    );
};

Checkbox.displayName = 'Checkbox';
