import { WIKI_CATEGORIES_WITH_NEWSLETTER } from 'mk2/containers/PregnancyNewsletter/PregnancyNewsletter.constants';
import { WikiArticleEntity } from 'mk2/schemas';

export const WIKI_MENU_MORE = 'wiki-more';

const ANCHOR_HEADINGS__ARTICLE_SLUGS = [
    'ako-ziskat-benefity-zdravotnej-poistovne',
    'ako-ziskat-vyhody-zdravotnej-poistovne',
    'union-zdravotna-poistovna',
];
const ANCHOR_HEADINGS__ARTICLE_CATEGORY_SLUGS = ['ine', 'jine'];

export const hasAnchorHeadings = (article: WikiArticleEntity) => {
    return (
        ANCHOR_HEADINGS__ARTICLE_SLUGS.includes(article.slug) ||
        ANCHOR_HEADINGS__ARTICLE_CATEGORY_SLUGS.includes(article.category.slug)
    );
};

export const hasPregnancyNewsletter = (categorySlug: string) => {
    return WIKI_CATEGORIES_WITH_NEWSLETTER.includes(categorySlug);
};
