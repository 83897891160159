import {
    FORMS_DEFAULT_REQUIRED_FIELD_ERROR,
    LAST_PERIOD_START,
    PREGNANCY_CALCULATOR_FORM_SUBMIT,
    PREGNANCY_CALCULATOR_FORM_TITLE,
    SIGNUP_DUE_DATE,
} from 'mk/autogenerated/translations/PregnancyCalculatorForm.6f74a925fad9adbd01af8f5b06915eb6'
import assetUrl from 'mk/common/assetUrl';
import { BirthDateField } from 'mk2/components/forms/BirthDateField';
import { RadioField } from 'mk2/components/forms/RadioField';
import { SubmitButton } from 'mk2/components/forms/SubmitButton';
import { BtnType } from 'mk2/components/Btn';
import { Form } from 'mk2/components/Form';
import { Img } from 'mk2/components/Img';
import { mkReduxForm, MKReduxFormInjectedProps } from 'mk2/decorators/mkReduxForm';
import React from 'react';
import { FormErrors } from 'redux-form';
import styles from './PregnancyCalculatorForm.mscss';

export const PREGNANCY_CALCULATOR_FORM_NAME = 'PREGNANCY_CALCULATOR_FORM';

export interface FormValues {
    inputDateType: string;
    inputDate: string;
}

interface OwnProps {
    className?: string;
    initialValues: FormValues;
    currentValues: FormValues;
    enableReinitialize: boolean;
    onSave(values: FormValues);
}

type Props = OwnProps;

const options = [
    { value: 'last-period-start', label: LAST_PERIOD_START },
    { value: 'due-date', label: SIGNUP_DUE_DATE },
];

const fly = assetUrl + 'img/pregnancy-calculator/fly.svg';

class PregnancyCalculatorForm extends React.Component<Props & MKReduxFormInjectedProps<FormValues, Props>> {
    public render() {
        const { handleSubmit, form } = this.props;
        return (
            <Form onSubmit={handleSubmit} className={styles.PregnancyCalculatorForm}>
                <div className={styles.PregnancyCalculatorForm__inner}>
                    <h3 className={styles.PregnancyCalculatorForm__formTitle}>{PREGNANCY_CALCULATOR_FORM_TITLE}</h3>
                    <div className={styles.PregnancyCalculatorForm__formStyleHack01}>
                        <RadioField
                            name="inputDateType"
                            options={options}
                            marginLeft={false}
                            bottomBorder={false}
                        />
                    </div>

                    <Img
                        src={fly}
                        lazy={false}
                        width={30}
                        height={36}
                        className={styles.PregnancyCalculatorForm__fly}
                    />
                    <div className={styles.PregnancyCalculatorForm__formStyleHack02}>
                        <div className={styles.PregnancyCalculatorForm__formStyleHack02__curtain} />
                        <BirthDateField
                            name="inputDate"
                            marginLeft={false}
                        />
                    </div>
                    <div className={styles.PregnancyCalculatorForm__submit}>
                        <SubmitButton
                            label={PREGNANCY_CALCULATOR_FORM_SUBMIT}
                            formName={form}
                            btnType={BtnType.BlueBig}
                        />
                    </div>
                </div>
            </Form>
        );
    }
}

function validate(values: FormValues): FormErrors<FormValues> {
    const errors: FormErrors<FormValues> = {};
    if (!values.inputDateType) {
        errors.inputDateType = FORMS_DEFAULT_REQUIRED_FIELD_ERROR;
    }
    if (!values.inputDate) {
        errors.inputDate = FORMS_DEFAULT_REQUIRED_FIELD_ERROR;
    }
    return errors;
}

export default mkReduxForm<FormValues, Props>({
    formName: PREGNANCY_CALCULATOR_FORM_NAME,
    onValidate: validate,
    onSave: (values, props) => props.onSave(values),
})(PregnancyCalculatorForm);
