import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocationDescriptor } from 'history';
import { Link } from 'mk2/components/Link';
import React from 'react';

interface OwnProps {
    link: LocationDescriptor;
    visibleText: string;
    count: number;
}

type Props = OwnProps;

const WikiLink = React.memo<Props>(({ link, visibleText, count }) => (
    <Link to={link}>
        {visibleText}
        {!!count && (
            <sup>
                {count}
                <FontAwesomeIcon icon={faComment} />
            </sup>
        )}
    </Link>
));

WikiLink.displayName = 'WikiLink';

export default WikiLink;
