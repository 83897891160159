// the same as PREGNANCY_WIKI_CATEGORIES in apps.wiki.api
export const WIKI_CATEGORIES_WITH_NEWSLETTER = [
    // SK CATEGORIES     // CZ CATEGORIES
    'snazime-sa',        'snazime-se',
    'som-tehotna',       'jsem-tehotna',
    'vyvoj-dietata',     'vyvoj-ditete',
    'rodim',             'rodim',
    'zdravie',           'zdravi',
    'dojcenie',          'kojeni',
];

// the same as PREGNANCY_FORUM_TOPICS in apps.forum.api
export const FORUM_TOPICS_WITH_NEWSLETTER = [
    // SK TOPICS              // CZ TOPICS'
    'som-tehotna',            'jsem-tehotna',
    '1-trimester',            '1-trimestr',
    '2-trimester',            '2-trimestr',
    '3-trimester',            '3-trimestr',
    'choroby-a-komplikacie',  'zdravotni-komplikace-v-tehotenstvi',
    'som-iny-clovek',         'jsem-jiny-clovek',
    'tehotenske-oblecenie',   'tehotenske-obleceni',
    'porodnice-a-doktori',    'porodnice-a-doktori',
    'porod',                  'porod',
    'aktivity-pre-tehulky',   'aktivity-pro-tehulky',
    'o-tehotenstve',          'o-tehotenstvi',
    'spolocny-termin-miesto', 'spolocny-termin',
];
