import cx from 'classnames';
import React from 'react';
import styles from './LoadingPlaceholder.mscss';

interface OwnProps {
    className?: string;
    width: number | string;
    height?: number | string;
    style?: any;
    children?: React.ReactChild;
}

export const LoadingPlaceholder: React.FunctionComponent<OwnProps> = ({ className, width, height, style, children }) =>
    height && height !== '100%' ? (
        <div
            className={cx(className, styles.LoadingPlaceholderParent)}
            style={{ width: typeof width === 'string' && width.endsWith('%') ? width : undefined }}
        >
            <div className={styles.LoadingPlaceholder} style={{ ...style, width, height }} children={children} />
        </div>
    ) : (
        <div className={cx(className, styles.LoadingPlaceholder)} style={{ ...style, width, height }} />
    );

LoadingPlaceholder.displayName = 'LoadingPlaceholder';
