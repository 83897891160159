import {
    ProfileFetchFailureAction,
    ProfileFetchRequestAction,
    ProfileFetchSuccessAction,
    PROFILE_FETCH_FAILURE,
    PROFILE_FETCH_REQUEST,
    PROFILE_FETCH_SUCCESS,
} from 'mk2/apps/settings/containers/Profile/Profile.actions';
import { tupdate } from 'mk2/helpers/types';
import { errorMessage } from 'mk2/reducers/errorMessage';
import { loadingState, LoadingState } from 'mk2/reducers/loadingState';
import { Reducer } from 'redux';

export interface ProfileState {
    id: number;
    loadingState: LoadingState;
    errorMessage: string;
}

export const initialProfileState: ProfileState = {
    id: null,
    loadingState: LoadingState.INIT,
    errorMessage: null,
};

export interface ProfilesState {
    [username: string]: ProfileState;
}

const initialProfilesState: ProfilesState = {};

declare type ProfilesReducerAction =
    ProfileFetchRequestAction
    | ProfileFetchSuccessAction
    | ProfileFetchFailureAction;

const profilesReducer: Reducer<ProfilesState> = (state = initialProfilesState, action: ProfilesReducerAction) => {
    switch (action.type) {
        case PROFILE_FETCH_REQUEST:
        case PROFILE_FETCH_FAILURE: {
            const username = action.username;
            return tupdate(state, {
                [username]: tupdate(state[username] || initialProfileState, {
                    loadingState: loadingState(undefined, action),
                    errorMessage: errorMessage(undefined, action),
                }),
            });
        }

        case PROFILE_FETCH_SUCCESS: {
            const username = action.username;
            return tupdate(state, {
                [username]: tupdate(state[username] || initialProfileState, {
                    id: action.response.result.profile,
                    loadingState: loadingState(undefined, action),
                    errorMessage: errorMessage(undefined, action),
                }),
            });
        }

        default:
            return state;
    }
};

export default profilesReducer;
