import { faThumbsUp as farThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { faThumbsUp as fasThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { getLikeIsLiked, getLikeLikesCount, AppState } from 'mk2/reducers';
import React from 'react';
import { connect } from 'react-redux';
import { likeToggle } from './Like.actions';
import styles from './Like.mscss';

export enum LikeType {
    IconCount,         // empty thumb up if not liked and full thumb up if liked
    IconLabel,         // empty thumb up no matter of liked status - liked text will be blue
    Label,             // text that is always blue
    CountIconLabel,    // empty thumb up if not liked and full thumb up if liked, icon has larger size
}

interface OwnProps {
    /** Custom className for element - e.g. to give it some margin */
    className?: string;

    /** Entity name in the database */
    entity: string;

    /** Entity id in the database */
    id: number;

    /* visual type of like button */
    type: LikeType;

    /** Disable button */
    disabled?: boolean;

    /** If given it likes count will not be displayed */
    label?: string;

    children?(props: {
        isLiked: boolean;
        count: number;
        onLikeToggle(event: React.MouseEvent<HTMLElement>): void;
    }): JSX.Element;
}

interface StateProps {
    isLiked: boolean;
    likesCount: number;
}

interface DispatchProps {
    onLikeToggle();
}

export class Like extends React.Component<StateProps & OwnProps & DispatchProps> {

    private static icons = {
        [LikeType.IconCount]: [farThumbsUp, fasThumbsUp],
        [LikeType.IconLabel]: [farThumbsUp, farThumbsUp],
        [LikeType.Label]: [null, null],
        [LikeType.CountIconLabel]: [farThumbsUp, fasThumbsUp],
    };

    public render() {
        const { className, type, isLiked, likesCount, disabled, label, children } = this.props;

        if (children) {
            // Render prop pattern - the most customizable
            return children({
                isLiked,
                count: likesCount,
                onLikeToggle: this.onLikeToggle,
            });
        }

        const faIcon = isLiked ? Like.icons[type][1] : Like.icons[type][0];

        return (
            <button
                type="button"
                className={cx(
                    styles.Like, className,
                    styles[`Like--${LikeType[type]}`],
                    styles[`Like--${isLiked ? '' : 'un'}liked`],
                )}
                onClick={this.onLikeToggle}
                disabled={disabled}
            >
                {type === LikeType.IconCount && (
                    <React.Fragment>
                        <FontAwesomeIcon icon={faIcon} className={styles.Like__icon} />
                        {`${likesCount}`}
                    </React.Fragment>
                )}
                {type === LikeType.IconLabel && (
                    <React.Fragment>
                        <FontAwesomeIcon icon={faIcon} className={styles.Like__icon} />
                        {label}
                    </React.Fragment>
                )}
                {type === LikeType.Label && (
                    <React.Fragment>
                        {label}
                    </React.Fragment>
                )}
                {type === LikeType.CountIconLabel && (
                    <React.Fragment>
                        {`${likesCount}`}
                        <FontAwesomeIcon
                            icon={faIcon}
                            className={cx(styles.Like__icon, styles[`Like__icon--${LikeType[type]}`])}
                        />
                        {label}
                    </React.Fragment>
                )}
            </button>
        );
    }

    private onLikeToggle = (event: React.MouseEvent<HTMLButtonElement | HTMLElement>) => {
        event.preventDefault();
        this.props.onLikeToggle();
    };
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    return {
        isLiked: getLikeIsLiked(state, ownProps.entity, ownProps.id),
        likesCount: getLikeLikesCount(state, ownProps.entity, ownProps.id),
    };
}

function mapDispatchToProps(dispatch, ownProps: OwnProps): DispatchProps {
    return {
        onLikeToggle: () => dispatch(likeToggle(ownProps.entity, ownProps.id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Like);
