import cx from 'classnames';
import { Clamp, OwnProps as ClampOwnProps } from 'mk2/components/Clamp';
import React from 'react';
import styles from './ExpandableClamp.mscss';

interface OwnProps extends ClampOwnProps {
    expandLabel: React.ReactNode;

    onExpand?();
}

type Props = OwnProps;

interface State {
    expanded: boolean;
}

export class ExpandableClamp extends React.Component<Props> {

    public state: State = {
        expanded: null,
    };

    public render() {
        const { expanded } = this.state;
        const { expandLabel, children, ...rest } = this.props;

        if (expanded) {
            return React.Children.only(children);
        }

        return (
            <>
                <Clamp {...rest} onClamp={this.onClamp}>
                    {React.Children.only(children)}
                </Clamp>
                {expanded === false && (
                    <div className={cx(styles.ExpandableClamp__label)} onClick={this.onExpand}>
                        {expandLabel}
                    </div>
                )}
            </>
        );
    }

    private onClamp = () => {
        if (this.state.expanded === null) {
            this.setState({ expanded: false });
        }
    };

    private onExpand = (event?: React.MouseEvent<HTMLDivElement>) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({ expanded: true });
        if (this.props.onExpand) {
            this.props.onExpand();
        }
    };

}
