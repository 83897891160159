import cx from 'classnames';
import { supportsEmoji } from 'mk2/helpers/detects';
import React from 'react';
import styles from './Emojify.mscss';

interface OwnProps {
    className?: string;
    size?: number;
    force?: boolean; // Force emojione
}

interface State {
    emojione?: any; // emojione npm library
}

type Props = OwnProps;

interface ChildProps {
    children?: React.ReactNode;
    dangerouslySetInnerHTML?: {
        __html: string;
    };
}

export class Emojify extends React.Component<Props, State> {

    public static defaultProps = {
        size: 20,
        force: false,
    };

    private mounted = false;

    constructor(props) {
        super(props);

        this.state = {};
    }

    public componentDidMount() {
        this.mounted = true;
        if (!supportsEmoji() || this.props.force) {
            import('emojione' /* webpackChunkName: "emojione" */).then((emojione) => {
                if (this.mounted) {
                    this.setState({ emojione });
                }
            });
        }
    }

    public componentWillUnmount() {
        this.mounted = false;
    }

    public render() {
        const { className, children } = this.props;

        return (
            <span className={cx(styles.Emojify, className)}>
                {this.state.emojione && React.Children.count(children)
                    ? this.traverse(children)
                    : children}
            </span>
        );
    }

    private traverse = (children: React.ReactNode) => {
        const { emojione } = this.state;

        return React.Children.map(children, (child) => {
            if (React.isValidElement<ChildProps>(child)) {
                const newProps: ChildProps = child.props.dangerouslySetInnerHTML
                    ? { dangerouslySetInnerHTML: { __html: emojione.toImage(child.props.dangerouslySetInnerHTML.__html) } }
                    : {};

                return React.cloneElement(child, newProps, this.traverse(child.props.children));
            }

            if (typeof child === 'string') {
                return this.emojify(child);
            }

            return child;
        });
    };

    private emojify = (text: string) => {
        const { size } = this.props;
        const { emojione } = this.state;

        const mappedUnicode = emojione.mapUnicodeToShort();
        const emojiList = emojione.emojioneList;

        return text.split(getRegExp(emojione)).filter(Boolean).map((uchar, idx, parts) => {
            if (!uchar || !(uchar in emojione.jsEscapeMap)) {
                return uchar;
            }

            const unicode = emojione.jsEscapeMap[uchar];
            const short = mappedUnicode[unicode];
            const fname = emojiList[short].fname;

            const alt = (emojione.unicodeAlt) ? emojione.convert(unicode.toUpperCase()) : short;

            return (
                // tslint:disable-next-line:jsx-ban-elements
                <img
                    key={idx}
                    className="emojione"
                    alt={alt}
                    src={`${emojione.imagePathPNG}${fname}.png${emojione.cacheBustParam}`}
                    width={size}
                    height={size}
                />
            );
        });
    };
}

const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
const reHasRegExpChar = RegExp(reRegExpChar.source);
const escapeRegExp = (s) =>
    (s && reHasRegExpChar.test(s)) ? s.replace(reRegExpChar, '\\$&') : s;

let _re;
function getRegExp(emojione) {
    if (_re) {
        return _re;
    }

    const unicodes = Object.keys(emojione.emojioneList)
        // Take only last
        .map((shortcode) => emojione.emojioneList[shortcode].unicode[emojione.emojioneList[shortcode].unicode.length - 1])
        .sort((a, b) => b.length - a.length)
        .map((code) => code.split('-').map(emojione.convert).join(''))
        .map(escapeRegExp)
        .join('|');

    return _re = RegExp(`(${unicodes})`);
}
