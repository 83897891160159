import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { upperCaseFirst } from 'mk/bazaar/common/filters';
import { photoUrl } from 'mk/photo/photoUrl';
import { Emojify } from 'mk2/components/Emojify';
import { PhotoEntity } from 'mk2/schemas';
import React from 'react';
import styles from './Cover.mscss';

const bestVersion = (photo: PhotoEntity) => {
    if (photo.versions.includes('s1600x1600')) {
        return 's1600x1600';
    }

    if (photo.versions.includes('s742xn')) {
        return 's742xn';
    }

    return 's1600x1600';
};

interface OwnProps {
    className?: string;
    photo?: PhotoEntity;
    version?: string;
    height?: 220 | 250;
    title?: string;
    icon?: IconLookup;
    subtitle?: React.ReactNode;
    backgroundSize?: 'contain' | 'cover';
}

type Props = OwnProps;

export const Cover: React.StatelessComponent<Props> = ({
    className,
    photo,
    version,
    height= 220,
    title,
    icon,
    subtitle ,
    backgroundSize = 'cover',
}) => (
    <div
        className={cx(
            styles.Cover,
            {
                220: styles.Cover__height220,
                250: styles.Cover__height250,
            }[height],
            className,
        )}
    >
        {photo &&
            <div
                className={cx(
                    styles.Cover__photo,
                    Boolean(title || subtitle) && styles.Cover__withMask,
                    styles[`Cover__photo--${backgroundSize}`],
                )}
                style={{
                    backgroundImage: `url('${photoUrl(photo, version ?? bestVersion(photo))}')`,
                }}
            />
        }
        {!photo &&
            <FontAwesomeIcon icon={faCamera} className={styles.Cover__placeholder} />
        }
        <div className={styles.Cover__text}>
            {title && (
                <h1 className={styles.Cover__title}>
                    {icon && <FontAwesomeIcon icon={icon} className={styles.Cover__title__icon} />}
                    <Emojify size={24}>
                        {upperCaseFirst(title)}
                    </Emojify>
                </h1>
            )}
            {subtitle && (
                <div className={styles.Cover__subtitle}>
                    {subtitle}
                </div>
            )}
        </div>

    </div>
);

Cover.displayName = 'Cover';
