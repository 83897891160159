import cx from 'classnames';
import { CANCEL } from 'mk/autogenerated/translations/MenuModal.0544e24462b439ce14972b144eee1014'
import { MenuGroup } from 'mk2/components/menu/MenuGroup';
import { MenuItem } from 'mk2/components/menu/MenuItem';
import { Modal } from 'mk2/components/Modal';
import React from 'react';
import styles from './MenuModal.mscss';

interface OwnProps {
    className?: string;
    show: boolean;
    onClose: () => void;
}

type Props = OwnProps;

interface ChildProps {
    className: string;
    itemClassName?: string;
}

export class MenuModal extends React.Component<Props> {

    public shouldComponentUpdate(nextProps: OwnProps & { children?: React.ReactNode[] }) {
        return this.props.className !== nextProps.className
            || this.props.show !== nextProps.show
            || this.props.children !== nextProps.children;
    }

    public render() {
        const { className, show, onClose, children } = this.props;

        if (!show) {
            return null;
        }

        return (
            <Modal show={show} onHide={onClose}>
                <div className={cx(styles.MenuModal, className)} onClick={onClose}>
                    <div className={styles.MenuModal__children}>
                        {React.Children.map(children, (child) => {
                            if (React.isValidElement<ChildProps>(child)) {
                                const displayName = typeof child.type === 'string' ? child.type : (child.type as any).displayName;
                                const newProps: ChildProps = {
                                    className: cx(child.props.className, styles[`MenuModal__${displayName}`]),
                                };
                                if (displayName === 'MenuGroup' /* child.type === MenuGroup */) { // @see https://github.com/gaearon/react-hot-loader/issues/304
                                    newProps.itemClassName = styles[`MenuModal__MenuGroup__MenuItem`];
                                }
                                return React.cloneElement(child, newProps);
                            } else {
                                return child;
                            }
                        })}
                    </div>

                    <MenuGroup className={cx(styles.MenuModal__cancel)}>
                        <MenuItem
                            label={CANCEL}
                            onClick={onClose}
                        />
                    </MenuGroup>
                </div>
            </Modal>
        );
    }

}
