import { reactFilter, TextLike } from 'mk/bazaar/common/filters/utils';
import React from 'react';

const MD_BOLD_RE = /\*\*(.*?)\*\*/;

export const boldize = (text: TextLike, remove: boolean = false) => reactFilter(text, (s: string): React.ReactChild[] => {
    const ret = [];
    const matcher = new RegExp(MD_BOLD_RE, 'g');
    let from = 0;
    while (true) {
        const match = matcher.exec(s);
        if (match) {
            const matchLen = match[0].length;
            const content = match[1];

            const to = matcher.lastIndex - matchLen;

            // copy text before this link
            ret.push(s.substring(from, to));

            // insert bold content
            if (remove) {
                ret.push(content);
            } else {
                ret.push(<b>{content}</b>);
            }

            from = matcher.lastIndex;
        } else {
            // copy text after last link
            ret.push(s.substring(from));
            break;
        }
    }
    return ret;
});
