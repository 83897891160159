import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { REVIEW_VERIFIED } from 'mk/autogenerated/translations/ExperienceHeader.826b84230f9076bf02fd4e5669f2af9f'
import { blogsProfileUrl } from 'mk2/apps/blogs/urls';
import { faqVerifiedReviewUrl } from 'mk2/apps/faq/urls';
import { AvatarImg } from 'mk2/components/AvatarImg';
import { Dot } from 'mk2/components/Dot';
import { Link } from 'mk2/components/Link';
import { timeAt } from 'mk2/helpers/timeformat';
import { url } from 'mk2/helpers/urls';
import { ExperienceEntity, ExperienceVerificationStatus, StrollerUserReviewStatus } from 'mk2/schemas';
import React from 'react';
import styles from './ExperienceHeader.mscss';

interface OwnProps {
    className?: string;
    experience: ExperienceEntity;
}

type Props = OwnProps;

export default class ExperienceHeader extends React.Component<Props> {

    public render() {
        const { className, experience} = this.props;

        return (
            <div className={cx(styles.ExperienceHeader, className)}>
                <AvatarImg
                    size={18}
                    user={experience.authorUser}
                    href={url(blogsProfileUrl, { username: experience.authorUser.username })}
                />

                <Link
                    className={styles.ExperienceHeader__author}
                    to={url(blogsProfileUrl, { username: experience.authorUser.username })}
                >
                    {experience.authorUser.username}
                </Link>

                <Dot spaces={1} className={styles.ExperienceHeader__dot} />
                <span key="created">
                    {timeAt(experience.created)}
                </span>
                {experience.verificationStatus === ExperienceVerificationStatus.VERIFIED &&
                        <span>
                            <Link to={faqVerifiedReviewUrl} className={styles.ExperienceHeader__verified}>
                                <FontAwesomeIcon icon={faBadgeCheck} /> {REVIEW_VERIFIED}
                            </Link>
                        </span>
                    }
            </div>
        );
    }
}
