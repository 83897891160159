// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const ABOUT_ME = "P\u00e1r slov o tob\u011b";
export const ADD_AVATAR = "P\u0159idej profilovou fotku";
export const AVATAR_PHOTO_RULES_LINE1 = "poru\u0161uj\u00ed z\u00e1kony";
export const AVATAR_PHOTO_RULES_LINE2 = "jsou neslu\u0161n\u00e9";
export const AVATAR_PHOTO_RULES_LINE3 = "maj\u00ed politick\u00fd \u010di n\u00e1bo\u017eensk\u00fd obsah";
export const AVATAR_PHOTO_RULES_LINE4 = "obsahuj\u00ed reklamu";
export const AVATAR_PHOTO_RULES_TITLE = "Pros\u00edm, vyhni se obr\u00e1zk\u016fm, kter\u00e9:";
export const BOY = "Chlape\u010dek";
export const CHANGE_AVATAR = "Zm\u011b\u0148 profilovou fotku";
export const CHILD_NAME = "Bude se jmenovat";
export const DELETE_AVATAR = "Vyma\u017e profilovou fotku";
export const DO_NOT_PLAN_IN_CLOSE_FUTURE = "V bl\u00edzk\u00e9 budoucnosti miminko nepl\u00e1nuji";
export const DO_NOT_WANT_TO_TELL = "Ne\u0159eknu :-)";
export const ENTER_BIRTH_DATE = "Datum narozen\u00ed";
export const FEMALE = "\u017eena";
export const GIRL = "Hol\u010di\u010dka";
export const IT_WILL_BE_SURPRISE = "Nech\u00e1me se p\u0159ekvapit";
export const MALE = "mu\u017e";
export const OR_POSSIBLE_VARIANTS_IF_YOU_ARE_NOT_SURE = "(nebo mo\u017en\u00e9 varianty, pokud je\u0161t\u011b nev\u00ed\u0161 na 100%)";
export const PREGNANCY_STATE = "\u010cek\u00e1\u0161 miminko?";
export const PREGNANT = "Ano!";
export const PREGNANT_WITH = "Bude to";
export const REMOVE_PHOTO = "Zru\u0161 fotku";
export const RESIDENCE_CITY = "Bydli\u0161t\u011b - m\u011bsto / obec";
export const RESIDENCE_COUNTY = "Bydli\u0161t\u011b - okres";
export const SETTINGS_BIRTH_DATE_WE_ONLY_SHOW_AGE = "Datum narozen\u00ed nen\u00ed nikde zve\u0159ejn\u011bn\u00fd, vypo\u010d\u00edt\u00e1me podle n\u011bj tv\u016fj v\u011bk. V\u011bk je zve\u0159ejn\u011bn\u00fd na profilu.";
export const SETTINGS_DUE_DATE = "Term\u00edn - p\u0159edpokl\u00e1dan\u00e9 datum porodu";
export const SETTINGS_DUE_DATE_INFO = "(1x t\u00fddn\u011b ti po\u0161leme informace o tv\u00e9m t\u011bhotenstv\u00ed na e-mail a z\u00edsk\u00e1\u0161 p\u0159\u00edstup do t\u011bhotensk\u00e9 skupiny. Na tv\u00e9m profilu se prvn\u00ed 3 m\u011bs\u00edce od p\u0159ibli\u017en\u00e9ho za\u010d\u00e1tku t\u011bhotenstv\u00ed nezobrazuje informace, \u017ee jsi t\u011bhotn\u00e1)";
export const SIGNUP_LOCATION_COUNTRY = "Bydli\u0161t\u011b - st\u00e1t";
export const TRIPLETS = "Troj\u010data";
export const TWINS_BOY_AND_GIRL = "Dvoj\u010data: chlapec a d\u011bv\u010de";
export const TWINS_BOYS = "Dvoj\u010data: chlapci";
export const TWINS_GIRLS = "Dvoj\u010data: hol\u010di\u010dky";
export const TWINS_SURPRISE = "Dvoj\u010data: nezn\u00e1me pohlav\u00ed";
export const WE_ARE_TRYING_TO_CONCEIVE = "Ne, ale sna\u017e\u00edme se...";
export const WE_DONT_KNOW = "Zat\u00edm nev\u00edme";
