import fromPairs from 'lodash-es/fromPairs';
import {
    lo_TO_up,
    number_DAYS,
    number_WEEKS,
    GROUPS_JOIN_SAME_DATE_DOMAIN_GROUP_name,
    PREGNANCY_AVERAGE_FETAL_LENGTH,
    PREGNANCY_AVERAGE_FETAL_WEIGHT,
    PREGNANCY_CALCULATOR_READ_MORE_ABOUT_week_IN_WIKI,
    PREGNANCY_CALCULATOR_SUBSCRIBE_PREGNANCY_NEWSLETTER,
    PREGNANCY_CALCULATOR_SUBSCRIBE_PREGNANCY_NEWSLETTER_TEXT,
    PREGNANCY_DAYS_TILL_DUE_DATE,
    PREGNANCY_DUE_DATE_RANGE,
    PREGNANCY_NEWSLETTER_week_NEXT_WEEK,
    PREGNANCY_NEWSLETTER_EXPERIMENT_TEXT_ENDING,
    PREGNANCY_WEEK,
    PREGNANCY_WEEK_DAY_MEDICAL,
    SIGNUP_AND_JOIN_GROUP, SIGNUP_DUE_DATE, TRIMESTER,
} from 'mk/autogenerated/translations/PregnancyCalculator.824d048b16f72c884be35006900befab'
import assetUrl from 'mk/common/assetUrl';
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { groupsGroupUrl } from 'mk2/apps/groups/urls';
import { signupUrl } from 'mk2/apps/users/urls';
import WikiLink from 'mk2/apps/wiki/components/WikiLink';
import { Btn } from 'mk2/components/Btn';
import { Img } from 'mk2/components/Img';
import { Interpolate } from 'mk2/components/Interpolate';
import { LoadingSwitch } from 'mk2/containers/LoadingSwitch/LoadingSwitch';
import {
    pregnancyCalculatorLoad,
    pregnancyCalculatorSubmit,
} from 'mk2/containers/PregnancyCalculator/PregnancyCalculator.actions';
import reducer from 'mk2/containers/PregnancyCalculator/PregnancyCalculator.reducers';
import saga from 'mk2/containers/PregnancyCalculator/PregnancyCalculator.sagas';
import { getPregnancyCalculatorState } from 'mk2/containers/PregnancyCalculator/PregnancyCalculator.selectors';
import PregnancyCalculatorForm, {
    FormValues,
    PREGNANCY_CALCULATOR_FORM_NAME,
} from 'mk2/containers/PregnancyCalculator/PregnancyCalculatorForm';
import PregnancyNewsletter from 'mk2/containers/PregnancyNewsletter/PregnancyNewsletter';
import registerInStore from 'mk2/decorators/registerInStore';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { url } from 'mk2/helpers/urls';
import { getRequestUser, AppState } from 'mk2/reducers';
import { LoadingState } from 'mk2/reducers/loadingState';
import {
    ContentItemEntity,
    ContentItemSchema,
    GroupEntity,
    GroupMemberEntity,
    GroupMemberSchema,
    GroupSchema,
    UserEntity,
} from 'mk2/schemas';
import { getDenormalizedEntity } from 'mk2/selectors';
import { interpolate } from 'mk2/services/i18n';
import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import styles from './PregnancyCalculator.mscss';

const hearts = assetUrl + 'img/pregnancy-calculator/hearts.svg';

export enum PregnancyCalculatorStepTwoAction {
    PREGNANCY_NEWSLETTER = 'nl',
    BUTTON_TO_SIGNUP_WITH_SAME_DATE_GROUP_INVITATION = 'btn',
}

export const SAME_DATE_GROUP_INVITATION_CONTENT_ITEM_KEY = 'sdg';

interface OwnProps {
    action: PregnancyCalculatorStepTwoAction;
}

export interface StateProps {
    requestUser: UserEntity;
    loadingState: LoadingState;
    csvContent: ContentItemEntity;
    currentValues: FormValues;
    initialValues: FormValues;
    week: number;
    day: number;
    dueDate: string;
    group: GroupEntity;
    groupMember: GroupMemberEntity;
    nextWeek: ContentItemEntity;
}

export interface DispatchProps {
    onLoad();
    onSubmit(values: FormValues);
}

type Props = OwnProps & StateProps & DispatchProps;

class PregnancyCalculator extends React.Component<Props> {

    public render() {
        const { csvContent, loadingState, onLoad } = this.props;

        return (
            <div className={styles.PregnancyCalculator}>
                <LoadingSwitch
                    loadingState={loadingState}
                    onLoad={onLoad}
                    onRenderInit={this.renderInitSuccess}
                    onRenderSuccess={this.renderInitSuccess}
                    hasData={Boolean(csvContent)}
                />
            </div>
        );
    }


    private renderResults = (
        dueDate = '—',
        pregnancyWeek = '—',
        pregnancyWeekDayMedical = '—',
        trimester = '—',
        pregnancyDaysTillDueDate = '—',
        pregnancyDueDateRange = '—',
        pregnancyAverageFetalLength = '—',
        pregnancyAverageFetalWeight = '—',
        wikiLink = null,
    ) => {
        const { week, nextWeek, dueDate: dueDateStr } = this.props;
        const nextWeekContent = (!nextWeek || !nextWeek.content) ? null : nextWeek.content.split(/\r\n|\n|\r/);
        return (
            <>
                <table className={styles.PregnancyCalculator__resultsTable} id="pregnancy-calculator-results-table">
                    <tbody>
                        <tr>
                            <td>{SIGNUP_DUE_DATE}</td>
                            <td>{dueDate}</td>
                        </tr>
                        <tr>
                            <td>{PREGNANCY_WEEK}</td>
                            <td>
                                {wikiLink
                                    ? <WikiLink
                                        link={wikiLink}
                                        visibleText={pregnancyWeek}
                                        count={null}
                                    />
                                    : pregnancyWeek
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>{PREGNANCY_WEEK_DAY_MEDICAL}</td>
                            <td>{pregnancyWeekDayMedical}</td>
                        </tr>
                        <tr>
                            <td>{TRIMESTER}</td>
                            <td>{trimester}</td>
                        </tr>
                        <tr>
                            <td>{PREGNANCY_DAYS_TILL_DUE_DATE}</td>
                            <td>{pregnancyDaysTillDueDate}</td>
                        </tr>
                        <tr>
                            <td>{PREGNANCY_DUE_DATE_RANGE}</td>
                            <td>{pregnancyDueDateRange}</td>
                        </tr>
                        <tr>
                            <td>{PREGNANCY_AVERAGE_FETAL_LENGTH}</td>
                            <td>{pregnancyAverageFetalLength}</td>
                        </tr>
                        <tr>
                            <td>{PREGNANCY_AVERAGE_FETAL_WEIGHT}</td>
                            <td>{pregnancyAverageFetalWeight}</td>
                        </tr>
                    </tbody>
                </table>
                {week && 1 <= week && week <= 40 && (
                    <>
                        {week && 5 <= week && week <= 40 && (
                        <div>
                            <Img
                                width={600}
                                height={370}
                                src={`${assetUrl}img/pregnancy-calculator/${ACTIVE_SERVER_ID}/${week}.png`}
                                className={styles.PregnancyCalculator__image}
                            />
                        </div>)}
                        {wikiLink && (
                            <div className={styles.PregnancyCalculator__wikiLink}>
                                <WikiLink
                                    link={wikiLink}
                                    visibleText={interpolate(PREGNANCY_CALCULATOR_READ_MORE_ABOUT_week_IN_WIKI, { week })}
                                    count={null}
                                />
                            </div>
                        )}
                        {nextWeekContent ?
                            (week && 1 <= week && week <= 40 && (
                                <div className={styles.PregnancyCalculator__nextWeek}>
                                    <div className={styles.PregnancyCalculator__nextWeekHeader}>
                                        <Interpolate
                                            i18nKey={PREGNANCY_NEWSLETTER_week_NEXT_WEEK}
                                            week={week + 1}
                                        />
                                    </div>
                                    <ul className={styles.PregnancyCalculator__nextWeekList}>
                                        {nextWeekContent.map((line, index) => (
                                            <li
                                                key={index}
                                            >
                                                {line}
                                            </li>
                                          ))}
                                    </ul>
                                    <div className={styles.PregnancyCalculator__nextWeekEnding}>
                                        {PREGNANCY_NEWSLETTER_EXPERIMENT_TEXT_ENDING}
                                    </div>
                                </div>
                            )) : null
                        }
                        <h2 className={styles.PregnancyCalculator__newsletterDiv__title}>
                            {PREGNANCY_CALCULATOR_SUBSCRIBE_PREGNANCY_NEWSLETTER}
                        </h2>
                        <p className={styles.PregnancyCalculator__newsletterDiv__text}>
                            {PREGNANCY_CALCULATOR_SUBSCRIBE_PREGNANCY_NEWSLETTER_TEXT}
                        </p>
                        <PregnancyNewsletter
                            initialPregnancyWeek={week}
                            initialPregnancyDueDate={dueDateStr}
                            formNameKey={'pregnancy-calculator'}
                            noPregnancyCalculatorLink
                            extraEventProps={{
                                subscribe_source: 'pregnancy_calculator',
                            }}
                        />
                    </>
                )}
            </>
        );

    };

    private renderInitSuccess = () => {
        const {
            action, currentValues, initialValues, onSubmit, week, day, dueDate: dueDateStr, csvContent, group,
        } = this.props;
        let renderedResults;
        if (dueDateStr && csvContent) {
            const dueDate = new Date(dueDateStr);
            const todayDate = new Date();
            todayDate.setUTCHours(0, 0, 0, 0);
            const pregnancyDaysTillDueDate = Math.max(
                Math.ceil((dueDate.getTime() - todayDate.getTime()) / (1000 * 60 * 60 * 24)),
                0,
            );
            const trimester = pregnancyDaysTillDueDate < (3 * 30) ? 3 : pregnancyDaysTillDueDate < (6 * 30) ? 2 : 1;
            const pregnancyDueDateRangeFrom = new Date(dueDate.getTime());
            pregnancyDueDateRangeFrom.setDate(pregnancyDueDateRangeFrom.getDate() - 14);
            const pregnancyDueDateRangeTo = new Date(dueDate.getTime());
            pregnancyDueDateRangeTo.setDate(pregnancyDueDateRangeTo.getDate() + 14);

            const csvData = fromPairs(csvContent.content.split(/\r\n|\n|\r/).map(line => {
                const items = line.split(';');
                return [items[0], items];
            }));
            const pregnancyAverageFetalLength = csvData[week]?.[2];
            const pregnancyAverageFetalWeight = csvData[week]?.[3];
            const wikiLink = csvData[week]?.[4];

            renderedResults = this.renderResults(
                String(dueDate.toLocaleDateString('sk-SK')),
                interpolate(number_WEEKS, { number: `${week}.` }),
                `${week - 1}+${day}`,
                `${trimester}. ${TRIMESTER.toLowerCase()}`,
                interpolate(number_DAYS, {
                    number: Math.floor(pregnancyDaysTillDueDate),
                    count: Math.floor(pregnancyDaysTillDueDate),
                }),
                interpolate(lo_TO_up, {
                    lo: pregnancyDueDateRangeFrom.toLocaleDateString('sk-SK'),
                    up: pregnancyDueDateRangeTo.toLocaleDateString('sk-SK'),
                }),
                pregnancyAverageFetalLength,
                pregnancyAverageFetalWeight,
                wikiLink,
            );
        } else {
            renderedResults = this.renderResults();
        }

        const showForm = (
            action === PregnancyCalculatorStepTwoAction.PREGNANCY_NEWSLETTER ||
            action === PregnancyCalculatorStepTwoAction.BUTTON_TO_SIGNUP_WITH_SAME_DATE_GROUP_INVITATION && !group
        );

        return (
            <div className={styles.PregnancyCalculator__inner}>
                {showForm && (
                    <PregnancyCalculatorForm
                        initialValues={initialValues}
                        currentValues={currentValues}
                        enableReinitialize
                        onSave={onSubmit}
                    />
                )}
                {action === PregnancyCalculatorStepTwoAction.PREGNANCY_NEWSLETTER && (
                    <>
                        <Img
                            src={hearts}
                            lazy={false}
                            width={50}
                            height={50}
                            className={styles.PregnancyCalculator__hearts}
                        />
                        {renderedResults}
                    </>
                )}
                {action === PregnancyCalculatorStepTwoAction.BUTTON_TO_SIGNUP_WITH_SAME_DATE_GROUP_INVITATION &&
                    group && (
                    <div className={styles.PregnancyCalculator__blueBox}>
                        <h3>{interpolate(GROUPS_JOIN_SAME_DATE_DOMAIN_GROUP_name, {name: group.name})} </h3>
                        <div className={styles.PregnancyCalculator__blueBox__signupAndJoinGroup}>
                            <Btn
                                label={SIGNUP_AND_JOIN_GROUP}
                                link={url(signupUrl, {}, {
                                    ci: SAME_DATE_GROUP_INVITATION_CONTENT_ITEM_KEY,
                                    next: url(groupsGroupUrl, { groupId: group.id }),
                                    group_name: group.name,
                                    dueDate: dueDateStr,
                                    pregnancy_week: week,
                                })}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    };
}


function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    const substate = getPregnancyCalculatorState(state);
    return {
        requestUser: getRequestUser(state),
        loadingState: substate.loadingState,
        csvContent: getDenormalizedEntity<ContentItemEntity>(state, ContentItemSchema, substate.csvContentId),
        currentValues: (getFormValues(PREGNANCY_CALCULATOR_FORM_NAME)(state) as FormValues) || substate.initialValues,
        initialValues: substate.initialValues,
        week: substate.week,
        day: substate.day,
        dueDate: substate.dueDate,
        group: getDenormalizedEntity<GroupEntity>(state, GroupSchema, substate.groupId),
        groupMember: getDenormalizedEntity<GroupMemberEntity>(state, GroupMemberSchema, substate.groupMemberId),
        nextWeek: getDenormalizedEntity<ContentItemEntity>(state, ContentItemSchema, substate.nextWeek),
    };
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {
    onLoad: pregnancyCalculatorLoad,
    onSubmit: pregnancyCalculatorSubmit,
};

export default registerInStore<OwnProps>((store) => {
    store.injectSaga('containers.wiki.pregnancyCalculator', saga);
    store.injectReducer('containers.wiki.pregnancyCalculator', reducer);
})(connect(mapStateToProps, mapDispatchToProps)(PregnancyCalculator));
