import React from 'react';

interface OwnProps {
    items?: React.ReactNode[];
    separator: React.ReactNode;
    className?: string;
}

/*
 * Inserts seprator between elements
 * Usage:
 * <Breadcrumb className='class' separator=' - '>
 *      <span>A New Hope</span>
 *      <span>The Empire Strikes Back</span>
 *      <span>Return of the Jedi</span>
 * </Breadcrumb>
 * or
 * <Breadcrumb className='class' separator=' - ' items={[
 *      <span>The Phantom Menace</span>,
 *      <span>Attack of the Clones</span>,
 *      <span>Revenge of the Sith</span>
 * ]}/>
 *
 */
export default class Breadcrumb extends React.Component<OwnProps> {
    public static defaultProps = {
        className: 'breadcrumb',
    };

    public render() {
        const items = this.props.items || this.props.children;
        if (!items) {
            return null;
        }

        const itemsArray = !Array.isArray(items) ? [ items ] : items;

        return (
            <div className={this.props.className}>
                {itemsArray.filter((item) => Boolean(item)).map((item, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && (
                            React.isValidElement(this.props.separator)
                                ? this.props.separator
                                : <span className="sep">{this.props.separator}</span>
                        )}
                        {item}
                    </React.Fragment>
                ))}
            </div>
        );
    }
}
