import { faImage, faMinusCircle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { counter_PHOTOS, READ_WHOLE_EXPERIENCE, READ_WHOLE_REVIEW } from 'mk/autogenerated/translations/ExperienceBody.9609bac5fff9ae0b095f33a091c5564e'
import { boldize, linebreaksbr, mentionize, upperCaseFirst, urlize } from 'mk/bazaar/common/filters';
import { MK_TESTING_USERNAME } from 'mk2/apps/blogs/constants';
import { wikiArticleExperiencePhotoUrl } from 'mk2/apps/wiki/urls';
import { wikiArticleUrl } from 'mk2/apps/wiki/utils';
import { Clamp } from 'mk2/components/Clamp';
import { Emojify } from 'mk2/components/Emojify';
import { ExpandableClamp } from 'mk2/components/ExpandableClamp';
import { Link } from 'mk2/components/Link';
import { PhotosRow } from 'mk2/components/PhotosRow';
import { url } from 'mk2/helpers/urls';
import { ExperienceEntity, PhotoEntity } from 'mk2/schemas';
import { interpolate } from 'mk2/services/i18n';
import React from 'react';
import styles from './ExperienceBody.mscss'; // tslint:disable-line

const MAX_WIDTH = 584;

interface OwnProps {
    experience: ExperienceEntity;
    showCompact?: boolean;
}

type Props = OwnProps;

export default class ExperienceBody extends React.Component<Props> {
    public render() {
        const { experience, showCompact } = this.props;

        // prepare experience message body
        let bodyChunks: React.ReactChild[] = [experience.text];
        bodyChunks = linebreaksbr(upperCaseFirst(mentionize(urlize(bodyChunks, 50))));
        if (experience.authorUser.username === MK_TESTING_USERNAME) {
            bodyChunks = boldize(bodyChunks);
        }

        return (
            <div className={styles.ExperienceBody}>
                {!showCompact ? (
                    <>
                        <div className={styles.ExperienceBody__text}>
                            <Emojify size={17}>{bodyChunks}</Emojify>
                        </div>
                        {experience.photos?.length > 0 && (
                            <>
                                {experience.photos?.length > 1 && (
                                    <div className={styles.ExperienceBody__photocount}>
                                        (
                                        {interpolate(counter_PHOTOS, {
                                            count: experience.photos.length,
                                            counter: experience.photos.length,
                                        })}
                                        )
                                    </div>
                                )}
                                <PhotosRow
                                    className={styles.ExperienceBody__photos}
                                    photoVersion="s738x738"
                                    maxWidth={MAX_WIDTH}
                                    photos={experience.photos.map((p) => p.photofile)}
                                    linkTo={this.galleryLinkTo}
                                />
                            </>
                        )}
                        {experience.article.experiencesPositivesNegativesAllowed &&
                            experience.positives &&
                            experience.positives.length > 0 && (
                                <ul className={styles.ExperienceBody__positivesList}>
                                    {experience.positives.map((point, index) => {
                                        return (
                                            <li className={styles.ExperienceBody__positivesList__listItem} key={index}>
                                                <div
                                                    className={
                                                        styles.ExperienceBody__positivesList__listItem__iconContainer
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                </div>
                                                {point}
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        {experience.article.experiencesPositivesNegativesAllowed &&
                            experience.negatives &&
                            experience.negatives.length > 0 && (
                                <ul className={styles.ExperienceBody__negativesList}>
                                    {experience.negatives.map((point, index) => {
                                        return (
                                            <li className={styles.ExperienceBody__negativesList__listItem} key={index}>
                                                <div
                                                    className={
                                                        styles.ExperienceBody__negativesList__listItem__iconContainer
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faMinusCircle} />
                                                </div>
                                                {point}
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                    </>
                ) : (
                    <>
                        {showCompact ? (
                            <Clamp clamp={3}>
                                <div className={styles.ExperienceBody__text}>
                                    <Emojify size={17}>{bodyChunks}</Emojify>
                                </div>
                            </Clamp>
                        ) : (
                            <ExpandableClamp expandLabel={READ_WHOLE_EXPERIENCE} clamp={3}>
                                <div className={styles.ExperienceBody__text}>
                                    <Emojify size={17}>{bodyChunks}</Emojify>
                                </div>
                            </ExpandableClamp>
                        )}
                        <div className={styles.ExperienceBody__badges}>
                            {experience.photos?.length > 0 && (
                                <div className={styles.ExperienceBody__badges__photosBadge}>
                                    <FontAwesomeIcon icon={faImage} />
                                    <span className={styles.ExperienceBody__badges__count}>
                                        {experience.photos?.length}
                                    </span>
                                </div>
                            )}
                            {experience.article.experiencesPositivesNegativesAllowed &&
                                experience.positives?.length > 0 && (
                                    <div className={styles.ExperienceBody__badges__positivesBadge}>
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                        <span className={styles.ExperienceBody__badges__count}>
                                            {experience.positives.length}
                                        </span>
                                    </div>
                                )}
                            {experience.article.experiencesPositivesNegativesAllowed &&
                                experience.negatives?.length > 0 && (
                                    <div className={styles.ExperienceBody__badges__negativesBadge}>
                                        <FontAwesomeIcon icon={faMinusCircle} />
                                        <span className={styles.ExperienceBody__badges__count}>
                                            {experience.negatives.length}
                                        </span>
                                    </div>
                                )}
                            {showCompact && !experience.deleted && (
                                <Link
                                    className={styles.ExperienceBody__badges__readMore}
                                    to={wikiArticleUrl(experience.article, `experience-${experience.id}`)}
                                >
                                    {READ_WHOLE_REVIEW}
                                </Link>
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    }

    private galleryLinkTo = (photofile: PhotoEntity) => {
        const { experience } = this.props;
        return !experience.deleted ? url(wikiArticleExperiencePhotoUrl, {
            categorySlug: experience.article.category.slug,
            articleSlug: experience.article.slug,
            experienceId: experience.id,
            photoId: photofile.id,
        }) : null;
    };
}
