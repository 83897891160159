import cx from 'classnames';
import { BtnType } from 'mk2/components/Btn';
import ActionButton, { OwnProps as ActionButtonProps } from 'mk2/containers/ActionButton/ActionButton';
import React from 'react';
import styles from './MenuItem.mscss';

interface OwnProps extends ActionButtonProps {
    label: React.ReactNode;
    isAdminUI?: boolean;
}

export class MenuItem extends React.PureComponent<OwnProps> {

    public static displayName = 'MenuItem';

    public render() {
        const { children, className, isAdminUI, ...rest } = this.props;

        return (
            <div className={cx(styles.MenuItem, className)}>
                <ActionButton
                    className={styles.MenuItem__Btn}
                    {...rest}
                    type={isAdminUI ? BtnType.MenuAdmin : BtnType.Menu}
                />
                {children}
            </div>
        );
    }

}
