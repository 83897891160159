import { Btn,  OwnProps as BtnProps } from 'mk2/components/Btn';
import { ActionButtonState } from 'mk2/containers/ActionButton/ActionButton.reducers';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { getActionButtonState, AppState } from 'mk2/reducers';
import { LoadingState } from 'mk2/reducers/loadingState';
import React from 'react';
import { connect } from 'react-redux';

export interface OwnProps extends BtnProps {
    triggerAction?: string;
    specificId?: number;
    showSpinnerWhenBusy?: boolean;
}

interface StateProps {
    isSubmitting: boolean;
}

interface DispatchProps {
}

type Props = OwnProps & StateProps & DispatchProps;

class ActionButton extends React.Component<Props> {

    public render() {
        const {
            triggerAction, specificId, isSubmitting, showSpinnerWhenBusy, ...rest
        } = this.props;

        return (
            <Btn
                hasSpinner={showSpinnerWhenBusy && isSubmitting}
                disabled={isSubmitting}
                {...rest}
            />
        );
    }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    const { triggerAction, specificId } = ownProps;
    if (!triggerAction) {
        return {
            isSubmitting: false,
        };
    }

    const buttonId = (specificId ? specificId : '') + triggerAction.substring(0, triggerAction.lastIndexOf('_'));
    const actionButtonState: ActionButtonState = getActionButtonState(state, buttonId);
    return {
        isSubmitting: actionButtonState.loadingState === LoadingState.LOADING,
    };
}

const mapDispatchToProps: MapDispatchToPropsObject<DispatchProps> = {};

export default connect(mapStateToProps, mapDispatchToProps)(ActionButton);
