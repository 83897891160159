import hoistNonReactStatics from 'hoist-non-react-statics';
import { AppState } from 'mk2/reducers';
import { Store } from 'mk2/store/configureStore';
import React from 'react';
import { useStore } from 'react-redux';

export default function registerInStore<TProps>(register: (store: Store<AppState>) => void) {
    return (Component: React.ComponentType<TProps>): React.ComponentType<TProps> => {
        class RegisterInStoreComponentWithStore extends React.Component<TProps & { store: Store<AppState> }> {
            public static displayName = `RegisterInStore(${Component.displayName || Component.name || 'Component'})`;

            public constructor(props, context) {
                super(props, context);
                register(props.store);
            }

            public render() {
                return <Component {...this.props} />;
            }
        }

        hoistNonReactStatics(RegisterInStoreComponentWithStore, Component);

        const RegisterInStoreComponent: React.FunctionComponent<TProps> = (props) => {
            const store: Store<AppState> = useStore() as any;
            return <RegisterInStoreComponentWithStore {...props} store={store} />;
        };

        hoistNonReactStatics(RegisterInStoreComponent, RegisterInStoreComponentWithStore);

        return RegisterInStoreComponent;
    };
}
