import cx from 'classnames';
import React from 'react';
import styles from './MenuGroup.mscss';

interface OwnProps {
    className?: string;
    itemClassName?: string;
    align?: 'left' | 'center' | 'right';
    maxHeight?: number;
}

export class MenuGroup extends React.Component<OwnProps> {

    public static displayName = 'MenuGroup';

    public render() {
        const { className, itemClassName, children, align, maxHeight } = this.props;

        return (
            <div className={cx(styles.MenuGroup, className, maxHeight && styles['MenuGroup--max-height'])} style={{ maxHeight: `${maxHeight}px`}}>
                {React.Children.map(children, (child) => {
                    // child.type === MenuItem will not work when using react-hot-loader
                    // https://github.com/gaearon/react-hot-loader/issues/304
                    if (React.isValidElement<{ align: string, className: string }>(child)) {
                        const displayName = typeof child.type === 'string' ? child.type : (child.type as any).displayName;
                        return React.cloneElement(child, {
                            align: child.props.align || align,
                            className: cx(child.props.className, itemClassName, styles[`MenuGroup__${displayName}`]),
                        });
                    } else {
                        return child;
                    }
                })}
            </div>
        );
    }

}
