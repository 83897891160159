import cx from 'classnames';
import { ImgVarWidth } from 'mk2/components/ImgVarWidth';
import { Link } from 'mk2/components/Link';
import { PhotoEntity } from 'mk2/schemas';
import React, { useMemo } from 'react';
import styles from './PhotosRow.mscss';

const MARGIN = 5; // right margin of photos

interface OwnProps {
    className?: string;
    photos: PhotoEntity[];
    maxWidth?: number;
    height?: number;
    photoVersion: string;
    linkTo?(photo: PhotoEntity): string;
}

type Props = OwnProps;

export const PhotosRow: React.FunctionComponent<Props> = React.memo(({
    className,
    maxWidth,
    photoVersion,
    height = 140,
    photos,
    linkTo,
}) => {
    // list of photos to display based on maxWidth (on mobile may be more photos hidden via @media styles)
    const photosToDisplay: PhotoEntity[] = useMemo(() => {
        if (!maxWidth) {
            return photos;
        } else {
            let totalWidth = 0;
            totalWidth -= MARGIN; // removing margin of last image
            return photos.reduce<PhotoEntity[]>((acc, cur) => {
                const ratio = cur.width / cur.height;
                const destinationWidth = height * ratio + MARGIN;
                if (totalWidth < maxWidth) {
                    acc.push(cur);
                    totalWidth += destinationWidth;
                    return acc;
                } else {
                    return acc;
                }
            }, []);
        }
    }, [maxWidth, photos]);

    const renderImg = (photo: PhotoEntity) => {
        return (
            <ImgVarWidth
                className={styles.PhotosRow__photo__image}
                key={photo.id}
                height={height}
                photo={photo}
                photoVersion={photoVersion}
            />
        );
    };
    return (
        <div className={cx(styles.PhotosRow, className)}>
            {photosToDisplay.map((photo) => {
                const link = linkTo?.(photo);
                return link ? (
                    <Link className={styles.PhotosRow__photo} key={photo.id} to={link}>
                        {renderImg(photo)}
                    </Link>
                ) : (
                    <span className={styles.PhotosRow__photo} key={photo.id}>
                        {renderImg(photo)}
                    </span>
                );
            })}
            <span className={styles.PhotosRow__mask} />
        </div>
    );
});

PhotosRow.displayName = 'PhotosRow';
