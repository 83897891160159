import { faCircle, faDotCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React from 'react';
import styles from './Radio.mscss';

interface OwnProps {
    className?: string;
    label: React.ReactNode;
    disabled?: boolean;
}

type Props = OwnProps & Omit<React.HTMLProps<HTMLInputElement>, 'label'>;

export const Radio: React.FunctionComponent<Props> = ({
    className, label, checked, id, name, disabled, ...rest
}) => {
    const htmlId = id || `radio-${name}`;

    return (
        <label
            className={cx(
                styles.Radio,
                className,
                disabled && styles['Radio--disabled'],
            )}
            htmlFor={htmlId}
        >
            <FontAwesomeIcon
                icon={checked ? faDotCircle : faCircle}
                className={cx(styles.Radio__icon, checked && styles['Radio__icon--checked'])}
            />
            <input
                id={htmlId}
                className={styles.Radio__input}
                type="radio"
                checked={checked}
                disabled={!!disabled}
                {...rest}
            />
            {label}
        </label>
    );
};

Radio.displayName = 'Radio';
