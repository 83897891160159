import { NewExperienceFormData } from 'mk2/apps/wiki/containers/ArticleAddExperience/ArticleAddExperiencePage';
import { Action } from 'redux';

import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';

// API
export interface ArticleAddExperienceApiAction extends Action {
    readonly articleSlug: string;
    readonly categorySlug: string;
}

export const ARTICLE_ADD_EXPERIENCE_REQUEST = 'ARTICLE_ADD_EXPERIENCE_REQUEST';

export interface ArticleAddExperienceRequestAction extends ArticleAddExperienceApiAction, APIRequestAction {
    readonly type: typeof ARTICLE_ADD_EXPERIENCE_REQUEST;
}

export const ARTICLE_ADD_EXPERIENCE_SUCCESS = 'ARTICLE_ADD_EXPERIENCE_SUCCESS';

export interface ArticleAddExperienceSuccessAction extends ArticleAddExperienceApiAction, APISuccessAction {
    readonly type: typeof ARTICLE_ADD_EXPERIENCE_SUCCESS;
}

export const ARTICLE_ADD_EXPERIENCE_FAILURE = 'ARTICLE_ADD_EXPERIENCE_FAILURE';

export interface ArticleAddExperienceFailureAction extends ArticleAddExperienceApiAction, APIFailureAction {
    readonly type: typeof ARTICLE_ADD_EXPERIENCE_FAILURE;
}

export const articleAddExperienceApi = {
    request: (articleSlug: string, categorySlug: string): ArticleAddExperienceRequestAction => ({
        type: ARTICLE_ADD_EXPERIENCE_REQUEST, articleSlug, categorySlug,
    }),
    success: (articleSlug: string, categorySlug: string, response: any): ArticleAddExperienceSuccessAction => ({
        type: ARTICLE_ADD_EXPERIENCE_SUCCESS, articleSlug, categorySlug, response,
    }),
    failure: (articleSlug: string, categorySlug: string, error: any): ArticleAddExperienceFailureAction => ({
        type: ARTICLE_ADD_EXPERIENCE_FAILURE, articleSlug, categorySlug, error,
    }),
};

// Triggers
// ArticleAddExperience submit
export const ARTICLE_ADD_EXPERIENCE_SUBMIT = 'ARTICLE_ADD_EXPERIENCE_SUBMIT';

export interface ArticleAddExperienceSubmitAction extends ArticleAddExperienceApiAction {
    readonly type: typeof ARTICLE_ADD_EXPERIENCE_SUBMIT;
    readonly values: NewExperienceFormData;
    readonly categorySlug: string;
    readonly gotoUrl: string;
}

export const articleAddExperienceSubmit = (articleSlug, categorySlug, values, gotoUrl): ArticleAddExperienceSubmitAction => ({
    type: ARTICLE_ADD_EXPERIENCE_SUBMIT, articleSlug, categorySlug, values, gotoUrl,
});
