import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    number_WEEKS,
    EMAIL,
    FORMS_DEFAULT_REQUIRED_FIELD_ERROR,
    NEWSLETTER_SUBSCRIBE,
    PREGNANCY_NEWSLETTER_NOTE_FIRST_WEEKS,
    SIGNUP_DUE_DATE,
    WHAT_WEEK_OF_PREGNANCY_ARE_YOU,
    YOUR_REGISTRATION_EMAIL,
} from 'mk/autogenerated/translations/PregnancyNewsletterForm.27cf9ec86909a4c1428fca6a316d4b1a'
import { isAuthenticated } from 'mk/bazaar/common/userUtils';
import { BirthDateField } from 'mk2/components/forms/BirthDateField';
import { InputField } from 'mk2/components/forms/InputField';
import { Option, SelectField } from 'mk2/components/forms/SelectField';
import { SubmitButton } from 'mk2/components/forms/SubmitButton';
import { BtnType } from 'mk2/components/Btn';
import { Form } from 'mk2/components/Form';
import { mkReduxForm, MKReduxFormInjectedProps } from 'mk2/decorators/mkReduxForm';
import { UserEntity } from 'mk2/schemas';
import { interpolate } from 'mk2/services/i18n';
import React from 'react';
import { FormErrors } from 'redux-form';
import styles from './PregnancyNewsletterForm.mscss';

export interface PregnancyNewsletterFormData {
    pregnancyDueDate: string;
    pregnancyWeek: number;
    email: string;
}

interface OwnProps {
    user: UserEntity;
    initialValues: PregnancyNewsletterFormData;
    currentValues: PregnancyNewsletterFormData;
    enableReinitialize: boolean;
    onSave(values: PregnancyNewsletterFormData);
}

type Props = OwnProps;

export const PREGNANCY_WEEKS: Option[] = Array.from(Array(42).keys()).map((i) => {
    return { value: i + 1, label: interpolate(number_WEEKS, {count: 1, number: `${i + 1}.`}) };
});

class PregnancyNewsletterFormComponent extends React.Component<Props & MKReduxFormInjectedProps<PregnancyNewsletterFormData, Props>> {

    public static validate(values: PregnancyNewsletterFormData, props: Props): FormErrors<PregnancyNewsletterFormData> {
        const { user } = props;
        const showPregnancyDueDate = isAuthenticated(user);
        const showPregnancyWeek = !showPregnancyDueDate;

        const errors: FormErrors<PregnancyNewsletterFormData> = {};
        if (showPregnancyDueDate && !values.pregnancyDueDate) {
            errors.pregnancyDueDate = FORMS_DEFAULT_REQUIRED_FIELD_ERROR;
        }
        if (showPregnancyWeek && !values.pregnancyWeek) {
            errors.pregnancyWeek = FORMS_DEFAULT_REQUIRED_FIELD_ERROR;
        }
        if (!values.email) {
            errors.email = FORMS_DEFAULT_REQUIRED_FIELD_ERROR;
        }
        return errors;
    }

    public render() {
        const { currentValues, handleSubmit, user, form } = this.props;
        const showPregnancyDueDate = isAuthenticated(user);
        const showPregnancyWeek = !showPregnancyDueDate;

        let note;
        if (currentValues?.pregnancyWeek >= 1 && currentValues?.pregnancyWeek <= 4) {
            note = PREGNANCY_NEWSLETTER_NOTE_FIRST_WEEKS;
        }
        return (
            <Form className={styles.PregnancyNewsletterForm} onSubmit={handleSubmit}>
                    <div className={styles.PregnancyNewsletterForm__field}>
                        <InputField
                            name="email"
                            type="email"
                            label={isAuthenticated(user) ? YOUR_REGISTRATION_EMAIL : EMAIL}
                            marginLeft={false}
                            bottomBorder={false}
                        />
                    </div>
                    {showPregnancyWeek &&
                        <div className={styles.PregnancyNewsletterForm__field}>
                            <SelectField
                                label={WHAT_WEEK_OF_PREGNANCY_ARE_YOU}
                                name="pregnancyWeek"
                                options={PREGNANCY_WEEKS}
                                marginLeft={false}
                                bottomBorder={false}
                            />
                        </div>
                    }
                    {showPregnancyDueDate &&
                        <div className={styles.PregnancyNewsletterForm__field}>
                            <BirthDateField
                                name="pregnancyDueDate"
                                label={SIGNUP_DUE_DATE}
                                marginLeft={false}
                                bottomBorder={false}
                            />
                        </div>
                    }

                {note && (
                    <div className={styles.PregnancyNewsletterForm__note}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <div className={styles.PregnancyNewsletterForm__note__text}>
                            {note}
                        </div>
                    </div>
                )}
                <div className={styles.PregnancyNewsletterForm__submitButton}>
                    <SubmitButton
                        label={NEWSLETTER_SUBSCRIBE}
                        formName={form}
                        btnType={BtnType.Blue}
                    />
                </div>
            </Form>
        );
    }
}

export const generateFormName = (formNameKey: string): string => `PREGNANCY_NEWSLETTER_FORM_${formNameKey}`;

export const PregnancyNewsletterFormFactory = (formName: string) => mkReduxForm<PregnancyNewsletterFormData, Props>({
    formName,
    onValidate: (values, props) => PregnancyNewsletterFormComponent.validate(values, props),
    onSave: (values, props) => props.onSave(values),
})(PregnancyNewsletterFormComponent);
