import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import {
    BACK_TO_HOMEPAGE,
    PREGNANCY_NEWSLETTER,
    PREGNANCY_NEWSLETTER_DESCRIPTION,
    RELATED_DISCUSSIONS_IN_FORUM,
    WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_title,
    WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_ANY_REVIEWABLE,
    WIKI_EXP_AVERAGE_SCORE,
    WIKI_EXPERIENCES_WITH_title,
    WIKI_SHARE_YOUR_EXPERIENCE_AND_HELP_OTHERS,
    WIKI_WHERE_NEXT,
    WIKI_WRITE_YOUR_EXPERIENCE,
} from 'mk/autogenerated/translations/Article.df49464b4006f5403ae569ffd458ee3c'
import { isAuthenticated } from 'mk/bazaar/common/userUtils';
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { renderCover } from 'mk2/apps/blogs/containers/Article/Article.helpers';
import { MorePosts } from 'mk2/apps/forum/components/MorePosts';
import { WikiArticles } from 'mk2/apps/home/components/WikiArticles';
import { homepageUrl } from 'mk2/apps/home/urls';
import { strollersPrefix } from 'mk2/apps/strollers/urls';
import { loginUrl } from 'mk2/apps/users/urls';
import ArticleBody from 'mk2/apps/wiki/components/ArticleBody';
import Experience from 'mk2/apps/wiki/components/Experience';
import { hasPregnancyNewsletter } from 'mk2/apps/wiki/containers/Article/Article.constants';
import { Props } from 'mk2/apps/wiki/containers/Article/ArticlePage';
import { isReviewHomepageArticle } from 'mk2/apps/wiki/helpers';
import { getWikiArticleUrl, wikiAddExperienceUrl } from 'mk2/apps/wiki/urls';
import { Failure } from 'mk2/components/errors/Failure';
import { BreadcrumbSiteStructure } from 'mk2/components/BreadcrumbSiteStructure';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { StarsRating } from 'mk2/components/StarsRating';
import { TrackClick } from 'mk2/components/TrackClick';
import { LoadingSwitch } from 'mk2/containers/LoadingSwitch/LoadingSwitch';
import PregnancyNewsletter from 'mk2/containers/PregnancyNewsletter/PregnancyNewsletter';
import { cacheLast } from 'mk2/helpers/cache';
import { scrollElementTo } from 'mk2/helpers/scrolling';
import { url } from 'mk2/helpers/urls';
import { isSuccess, LoadingState } from 'mk2/reducers/loadingState';
import { ArticleConceptPath } from 'mk2/schemas';
import { interpolate } from 'mk2/services/i18n';
import React from 'react';
import styles from './Article.mscss';

interface OwnState {
    collapsedBreadcrumbs: boolean;
    articleSlug: string;
}

class Article extends React.Component<Props, OwnState> {

    public static getDerivedStateFromProps(props: Props, state: OwnState) {
        if (props.articleSlug !== state.articleSlug) {
            return {
                collapsedBreadcrumbs: true,
            };
        }

        return null;
    }

    public static returnLinkForItem(item) {
        return (
            <Link key={item.id} to={getWikiArticleUrl(item.categorySlug, item.articleSlug)}>
                {item.title}
            </Link>
        );
    }

    public static getBreadcrumbLinks(articleConceptPath: ArticleConceptPath[], showAlsoThisArticle: boolean = false) {
        const breadcrumbLinks = [];
        if (articleConceptPath.length > 1) {
            articleConceptPath.slice(0, -1).forEach((concept) => {
                breadcrumbLinks.push(Article.returnLinkForItem(concept));
            });
        }
        if (showAlsoThisArticle) {
            breadcrumbLinks.push(<>{articleConceptPath.slice(-1)[0].title}</>);
        }
        return breadcrumbLinks;
    }

    public static getCollapsedBreadcrumbLinks(
        articleConceptPath: ArticleConceptPath[],
        onClick,
        showAlsoThisArticle: boolean = false,
    ) {
        const breadcrumbLinks = [];
        const firstBreadcrumbItem = articleConceptPath[0];
        const lastBreadcrumbItem = !showAlsoThisArticle
            ? articleConceptPath[articleConceptPath.length - 2]
            : articleConceptPath[articleConceptPath.length - 1];

        const handleOnClick = () => {
            onClick({ collapsedBreadcrumbs: false });
        };
        breadcrumbLinks.push(Article.returnLinkForItem(firstBreadcrumbItem));
        breadcrumbLinks.push(
            <Link key="sep" to="#" onClick={handleOnClick}>
                {'...'}
            </Link>,
        );
        if (showAlsoThisArticle) {
            breadcrumbLinks.push(<>{lastBreadcrumbItem.title}</>);
        } else {
            breadcrumbLinks.push(Article.returnLinkForItem(lastBreadcrumbItem));
        }
        return breadcrumbLinks;
    }

    public static renderInit = (isMobile) => {
        const textWidths = isMobile
            ? ['100%', '100%', '100%', '60%'] // tak aby pocas loadingu bola viditelna reklama ;-)
            : ['100%', '100%', '100%', '100%', '100%', '100%', '100%', '60%'];

        return (
            <div className={styles.Article}>
                <LoadingPlaceholder className={styles.Article__titlePlaceholder} width="40%" height={35} />

                {textWidths.map((w, idx) => (
                    <LoadingPlaceholder key={idx} className={styles.Article__textPlaceholder} width={w} height={17} />
                ))}

                <LoadingPlaceholder
                    className={styles.Article__imgPlaceholder}
                    width="100%"
                    height={isMobile ? 150 : 300}
                />
            </div>
        );
    };

    public static renderFailure = (staticContext, errorMessage, loadFn) => {
        return (
            <Failure
                staticContext={staticContext}
                errorMessage={errorMessage}
                onRetry={loadFn}
                backButton={<Btn type={BtnType.Blue} label={BACK_TO_HOMEPAGE} icon={faArrowLeft} link={homepageUrl} />}
            />
        );
    };

    public state: OwnState = {
        collapsedBreadcrumbs: true,
        articleSlug: this.props.articleSlug,
    };

    private trackEventPropsCache = cacheLast();

    public componentDidMount() {
        const { articleSlug, categorySlug, experienceIdRef, loadingState, onPageView } = this.props;
        onPageView(categorySlug, articleSlug);

        // Scroll only if content is loaded
        if (experienceIdRef && isSuccess(loadingState)) {
            try {
                scrollElementTo(experienceIdRef, 'window-top');
            } catch (error) {
                // ignore, no need to show error page because of this
            }
        }
    }

    public componentDidUpdate(prevProps: Props) {
        const { articleSlug, categorySlug, experienceIdRef, loadingState, onPageView } = this.props;

        if (prevProps.match.params.articleSlug !== articleSlug) {
            onPageView(categorySlug, articleSlug);
        }

        if (
            experienceIdRef &&
            prevProps.loadingState === LoadingState.LOADING &&
            loadingState === LoadingState.SUCCESS
        ) {
            // Content is now loaded
            scrollElementTo(experienceIdRef, 'window-top');
        }
    }

    public render() {
        const { loadingState, article } = this.props;
        return (
            <LoadingSwitch
                loadingState={loadingState}
                onLoad={this.load}
                onRenderInit={this.renderInit}
                onRenderSuccess={this.renderLoadSuccess}
                onRenderFailure={this.renderFailure}
                hasData={!!article}
            />
        );
    }

    private load = () => {
        const { articleSlug, categorySlug, onLoad } = this.props;
        onLoad(articleSlug, categorySlug);
    };

    private renderInit = () => {
        const { isMobile } = this.props;
        return Article.renderInit(isMobile);
    };

    private renderFailure = () => {
        const { staticContext, errorMessage } = this.props;
        return Article.renderFailure(staticContext, errorMessage, this.load);
    };

    private renderLoadSuccess = () => {
        const {
            article,
            articleConceptPath,
            isMobile,
            location,
            onToggleMenuMore,
            relatedArticles,
            requestUser,
            requestUserPermissions,
            similarPosts,
            viewsCountUnique,
            viewsCountTotal,
            eshopProductsOfEshopNodes,
        } = this.props;

        const cover = renderCover(article, 'cover');

        const isAdmin = requestUserPermissions.indexOf('wiki.can_edit_wiki') >= 0;
        const addExpUrl = url(wikiAddExperienceUrl, {
            articleSlug: article.slug,
            categorySlug: article.category.slug,
        });

        return (
            <>
                <div className={styles.Article}>
                    {article.category.slug !== strollersPrefix && (
                        <BreadcrumbSiteStructure
                            className={styles.Article__breadcrumb}
                            items={
                                articleConceptPath.length > 4 && this.state.collapsedBreadcrumbs
                                    ? Article.getCollapsedBreadcrumbLinks(articleConceptPath, this.onClickSetState)
                                    : Article.getBreadcrumbLinks(articleConceptPath)
                            }
                        />
                    )}

                    {cover && <div className={styles.Article__cover}>{cover.rendered}</div>}

                    <ArticleBody
                        article={article}
                        location={location}
                        viewsCountUnique={viewsCountUnique}
                        viewsCountTotal={viewsCountTotal}
                        followLinks
                        isMobile={isMobile}
                        requestUser={requestUser}
                        requestUserPermissions={requestUserPermissions}
                        eshopProductsOfEshopNodes={eshopProductsOfEshopNodes}
                    />

                    {isMobile && hasPregnancyNewsletter(article.category.slug) && (
                        <div className={styles['Article__pregnancyNewsletter--blue']}>
                            <h2 className={styles['Article__pregnancyNewsletter--blue__header']}>
                                {PREGNANCY_NEWSLETTER}
                            </h2>
                            <p className={styles['Article__pregnancyNewsletter--blue__description']}>
                                {PREGNANCY_NEWSLETTER_DESCRIPTION}
                            </p>

                            <div
                                className={cx(
                                    styles.Article__pregnancyNewsletter__image,
                                    styles[`Article__pregnancyNewsletter__image--${ACTIVE_SERVER_ID}`],
                                )}
                            />
                            <PregnancyNewsletter
                                formNameKey={`article-${article.slug}`}
                                noBorder
                                extraEventProps={{
                                    subscribe_source: 'tehu_nl_widget_auto',
                                    subscribe_source_app: 'wiki',
                                    subscribe_source_detail: `wiki/${article.category.slug}/${article.slug}`,
                                }}
                            />
                        </div>
                    )}

                    {Boolean(similarPosts?.length > 0 && isMobile) && (
                        <MorePosts
                            key="similar"
                            className={styles.Article__similarPosts}
                            posts={similarPosts}
                            title={RELATED_DISCUSSIONS_IN_FORUM}
                            trackEventProps={article && this.trackEventPropsCache({
                                name: 'wiki_review_more_forum_posts_click',
                                props: {
                                    article_slug: article.slug,
                                    category_slug: article.category.slug,
                                },
                            }, article)}
                        />
                    )}
                </div>

                {article.category.slug !== strollersPrefix && relatedArticles.length > 0 && (
                    <>
                        <h2 className={styles.ArticleExperiences__sectionHeader}>{WIKI_WHERE_NEXT}</h2>
                        <WikiArticles
                            className={styles.Article__relatedArticles}
                            articlesData={relatedArticles}
                            isMobile={isMobile}
                            location={location}
                        />
                    </>
                )}

                {article.experiencesAllowed && (
                    <div id={'experiences'} className={styles.ArticleExperiences}>
                        <h2 className={styles.ArticleExperiences__sectionHeader}>
                            {interpolate(WIKI_EXPERIENCES_WITH_title, { title: article.instrumentalTitle })}
                        </h2>

                        {article.experiencesStarsAllowed && (
                            <StarsRating
                                starsCount={article.experiencesScore}
                                label={WIKI_EXP_AVERAGE_SCORE}
                                className={styles.ArticleExperiences__starsRating}
                            />
                        )}

                        <div className={styles.ArticleExperiences__addExperience}>
                            <div className={styles.ArticleExperiences__addExperience__header}>
                                {isReviewHomepageArticle(article.category.slug, article.slug)
                                    ? WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_ANY_REVIEWABLE
                                    : interpolate(WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_title, {
                                          title: article.instrumentalTitle,
                                      })}
                            </div>
                            <div className={styles.ArticleExperiences__addExperience__message}>
                                {WIKI_SHARE_YOUR_EXPERIENCE_AND_HELP_OTHERS}
                            </div>
                            <TrackClick
                                name="wiki_experience_add_clicked"
                                props={{
                                    article_id: article.id,
                                    category_slug: article.category.slug,
                                    article_slug: article.slug,
                                }}
                            >
                                <Btn
                                    className={styles.ArticleExperiences__addExperience__button}
                                    type={BtnType.Blue}
                                    icon={faComment}
                                    label={WIKI_WRITE_YOUR_EXPERIENCE}
                                    link={
                                        isAuthenticated(requestUser)
                                            ? addExpUrl
                                            : url(loginUrl, {},  {next: addExpUrl})
                                    }
                                />
                            </TrackClick>
                        </div>

                        {article.experiences.map((experience, id) => {
                            return (
                                <Experience
                                    key={id}
                                    experience={experience}
                                    isAdmin={isAdmin}
                                    isMobile={isMobile}
                                    onToggleMenuMore={onToggleMenuMore}
                                />
                            );
                        })}
                    </div>
                )}
            </>
        );
    };

    private onClickSetState = (state) => {
        this.setState(state);
    };
}

export default Article;
