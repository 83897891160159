import { BASE_URL } from 'mk/settings';
import { homepageUrl } from 'mk2/apps/home/urls';
import { getWikiArticleUrl } from 'mk2/apps/wiki/urls';
import { isCategoryArticle, wikiArticleUrl, wikiCategoryUrl } from 'mk2/apps/wiki/utils';
import { getFirstParagraphsPlaintext } from 'mk2/helpers/article_ast/utils';
import {
    getUrlOfCoverImageOrFirstImageOrFallback,
    organizationModrykonikWithoutContext,
} from 'mk2/helpers/structuredData';
import { YMDHMSIso } from 'mk2/helpers/timeformat';
import { fbMorykonikProfileUrl } from 'mk2/helpers/urls';
import { ArticleConceptPath, WikiArticleEntity, WikiCategoryEntity } from 'mk2/schemas';
import { Article, BreadcrumbList, ListItem, Product, WithContext } from 'schema-dts';

const breadcrumbCategory = (baseUrl: string, category: WikiCategoryEntity): ListItem => ({
    '@type': 'ListItem',
    'position': 1,
    'item': {
        '@id': baseUrl + wikiCategoryUrl(category),
        'name': category.name,
    },
});

const breadcrumbArticle = (baseUrl: string, article: WikiArticleEntity): ListItem => ({
    '@type': 'ListItem',
    'position': 2,
    'item': {
        '@id': baseUrl + wikiArticleUrl(article),
        'name': article.title,
    },
});

export function breadcrumbsWiki(baseUrl, article: WikiArticleEntity): WithContext<BreadcrumbList> {
    const items = [];

    items.push(breadcrumbCategory(baseUrl, article.category));

    if (!isCategoryArticle(article)) {
        items.push(breadcrumbArticle(baseUrl, article));
    }

    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': items,
    };
}

export function conceptsBreadcrumbsWiki(baseUrl, articleConceptPath: ArticleConceptPath[]): WithContext<BreadcrumbList> {
    const items: ListItem[] = articleConceptPath.map((concept, idx) => ({
            '@type': 'ListItem',
            'position': idx + 1,
            'item': {
                '@id': baseUrl + getWikiArticleUrl(concept.categorySlug, concept.articleSlug),
                'name': concept.title,
            },
        }));

    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': items,
    };
}

export function reviewArticleWiki(baseUrl: string, reviewArticle: WikiArticleEntity) {
    const reviewArticleUrl = wikiArticleUrl(reviewArticle);

    // according to https://developers.google.com/search/docs/data-types/article#amp
    const result: WithContext<Product> = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        '@id': baseUrl + reviewArticleUrl + '#Product',
        'name': reviewArticle.title,
        'image': getUrlOfCoverImageOrFirstImageOrFallback(reviewArticle.bodyAST, reviewArticle.photos),
        'description': getFirstParagraphsPlaintext(reviewArticle.bodyAST),
        'sku': reviewArticle.id.toString(),
        'url': baseUrl + reviewArticleUrl,

    };
    if (reviewArticle.experiences) {
        result.review = reviewArticle.experiences.map((experience) => {
            return {
                '@context': 'https://schema.org',
                '@type': 'Review',
                'author': {
                    '@type': 'Person',
                    'name': experience.authorUser.username,
                },
                'datePublished': experience.created,
                'reviewBody': experience.text,
                'reviewRating': {
                    '@type': 'Rating',
                    'bestRating': 5,
                    'worstRating': 1,
                    'ratingValue': experience.starsCount,
                },
            };
        });
        if ((reviewArticle.experiencesScore ?? false) !== false) {
            result.aggregateRating = {
                '@type': 'AggregateRating',
                'bestRating': 5,
                'worstRating': 1,
                'ratingValue': reviewArticle.experiencesScore,
                'reviewCount': reviewArticle.experiencesCount,
                'ratingCount': reviewArticle.experiencesCount,
            };
        }
    }
    return result;
}

export function articleWiki(baseUrl: string, article: WikiArticleEntity): WithContext<Article> {
    const createdOn: string = YMDHMSIso(article.createdAt);
    const modifiedOn: string = YMDHMSIso(article.modifiedAt);
    const articleUrl = wikiArticleUrl(article);

    // according to https://developers.google.com/search/docs/data-types/article#amp
    return {
        '@context': 'https://schema.org',
        '@type': 'Article',
        '@id': baseUrl + articleUrl,
        'headline': article.title,
        'description': getFirstParagraphsPlaintext(article.bodyAST),
        'image': getUrlOfCoverImageOrFirstImageOrFallback(article.bodyAST, article.photos),
        'datePublished': createdOn,
        'dateModified': modifiedOn ? modifiedOn : createdOn,
        'author': organizationModrykonikWithoutContext(false),
        'mainEntityOfPage': {
            '@type': 'WebPage',
            '@id': baseUrl + articleUrl,
        },
        'publisher': organizationModrykonikWithoutContext(false),
    };
}

export function articleWikiOG(baseUrl: string, article: WikiArticleEntity) {
    const createdOn: string = YMDHMSIso(article.createdAt);
    const modifiedOn: string = YMDHMSIso(article.modifiedAt);
    const articleUrl = wikiArticleUrl(article);

    // according to https://developers.facebook.com/docs/reference/opengraph/object-type/article/
    return {
        'og:type': 'article',
        'og:title': article.title,
        'og:description': getFirstParagraphsPlaintext(article.bodyAST),
        'og:image': getUrlOfCoverImageOrFirstImageOrFallback(article.bodyAST, article.photos),
        'og:url': baseUrl + articleUrl,

        'article:author': fbMorykonikProfileUrl(),
        'article:content_tier': 'free',
        'article:published_time': createdOn,
        'article:modified_time': modifiedOn ? modifiedOn : createdOn,
        'article:publisher': BASE_URL + homepageUrl,
    };
}
