import { NormalizedResponse } from 'mk2/helpers/api';

export function mergeDenormalized(
    denormalized1: NormalizedResponse,
    denormalized2: NormalizedResponse,
): NormalizedResponse {
    return {
        ...denormalized1,
        // Deep merge
        entities: Object.keys(denormalized2.entities).reduce((acc, cur) => {
            acc[cur] = {
                ...acc[cur],
                ...denormalized2.entities[cur],
            };
            return acc;
        }, denormalized1.entities),
        result: {
            ...denormalized1.result,
            ...denormalized2.result,
        },
    };
}

const isObject = (value) => {
  return !!(value && typeof value === 'object' && !Array.isArray(value));
};

/**
 * TODO: Remove when all entities of use cases (currently blog profile) will be normalized on server
 * A function which handle a main NormalizedResponse object which may contain in its
 * descendants a `MERGE_DENORMALIZED` field (of type NormalizedResponse) and merge
 * MERGE_DENORMALIZED.entities to the main NormalizedResponse object's entities, and unwrap
 * MERGE_DENORMALIZED.results, to its actual parent.
 * @param denormalized - the input (main) NormalizedResponse object
 * @param valueToCheck - on the top of call stack valueToCheck === denormalized
 * @return result - the input (main) NormalizedResponse object or the new (main) NormalizedResponse object with merged
 *                  entities and unwrapped results
 */
export function traverseAndMergeDenormalized(
    denormalized: NormalizedResponse,
    valueToCheck: any,
) {
    if (isObject(valueToCheck)) {
        const objectEntries = Object.entries(valueToCheck);
        for (let i = 0; i < objectEntries.length; i += 1) {
            const [objectKey, objectValue] = objectEntries[i];

            if (objectKey === 'MERGE_DENORMALIZED') {
                // merging `valueToCheck.MERGE_DENORMALIZED.entities` to a new `denormalized`
                const mergedDenormalized = mergeDenormalized(denormalized, {
                    entities: (objectValue as NormalizedResponse).entities,
                    result: denormalized.result,
                });
                // unwrap `valueToCheck.MERGE_DENORMALIZED.results`
                const mdEntries = Object.entries(valueToCheck.MERGE_DENORMALIZED.result);
                for (let j = 0; j < mdEntries.length; j += 1) {
                    const [objectKey2, objectValue2] = mdEntries[j];
                    valueToCheck[objectKey2] = objectValue2;
                }
                delete valueToCheck.MERGE_DENORMALIZED;
                return mergedDenormalized;
            }

            if (Array.isArray(objectValue)) {
                for (let k = 0; k < objectValue.length; k += 1) {
                    denormalized = traverseAndMergeDenormalized(denormalized, objectValue[k]);
                }
            }
            if (isObject(objectValue)) {
                denormalized = traverseAndMergeDenormalized(denormalized, objectValue);
            }
        }
    }
    return denormalized;
}
