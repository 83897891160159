import { faHourglass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { STROLLERS_NOT_APPROVED } from 'mk/autogenerated/translations/Experience.ee0718db5cfbe72cda1503e651a5ed9c'
import { upperCaseFirst } from 'mk/bazaar/common/filters';
import ExperienceBody from 'mk2/apps/wiki/components/ExperienceBody';
import ExperienceFooter from 'mk2/apps/wiki/components/ExperienceFooter';
import ExperienceHeader from 'mk2/apps/wiki/components/ExperienceHeader';
import ExperienceMenu from 'mk2/apps/wiki/components/ExperienceMenu';
import {
    vendorReplyOnExperienceFormName,
    VendorReplyOnExperienceFormData,
    VendorReplyOnExperienceFormFactory,
} from 'mk2/apps/wiki/components/VendorReplyOnExperienceForm';
import { getWikiArticleUrl } from 'mk2/apps/wiki/urls';
import { Dot } from 'mk2/components/Dot';
import { Emojify } from 'mk2/components/Emojify';
import { Link } from 'mk2/components/Link';
import { StarsRating } from 'mk2/components/StarsRating';
import { ExperienceEntity } from 'mk2/schemas';
import React from 'react';
import styles from './Experience.mscss';

interface OwnProps {
    experience: ExperienceEntity;
    isAdmin: boolean;
    isMobile: boolean;
    showCompact?: boolean;

    onDeleteVendorReply?(experienceId: number);
    onSaveVendorReply?(experienceId: number, values: VendorReplyOnExperienceFormData);
    onToggleMenuMore?(commentId: number);
}
export default class Experience extends React.Component<OwnProps> {

    public render() {
        const {
            experience, isAdmin, onDeleteVendorReply, onSaveVendorReply, onToggleMenuMore, showCompact,
        } = this.props;

        const VendorReplyOnExperienceForm = VendorReplyOnExperienceFormFactory(vendorReplyOnExperienceFormName(experience.id));

        return (
            <>
                <div
                    id={`experience-${experience.id}`}
                    className={styles.Experience}
                >
                    <div className={styles.Experience__wrapper}>
                        {showCompact &&
                            <h2 className={styles.Experience__wrapper__articleTitle}>
                                <Link to={getWikiArticleUrl(experience.article.category.categoryArticleSlug, experience.article.slug)}>
                                    {experience.article.title}
                                </Link>
                            </h2>
                        }

                        <ExperienceHeader
                            className={styles.Experience__wrapper__header}
                            experience={experience}
                        />

                        {isAdmin &&
                            <ExperienceMenu
                                className={styles.Experience__wrapper__dropdownMenu}
                                experience={experience}
                                onToggleMenuMore={onToggleMenuMore}
                            />
                        }

                        {experience.starsCount > 0 && experience.article.experiencesStarsAllowed &&
                            <div className={styles.Experience__starsRating}>
                                <StarsRating
                                    starsCount={experience.starsCount}
                                />
                                {experience.approved === false && !experience.deleted ? (
                                    <>
                                        <Dot spaces={1} />
                                        <div className={styles.Experience__starsRating__badge}>
                                            <FontAwesomeIcon icon={faHourglass} className={styles.Experience__starsRating__icon} />
                                            &nbsp;
                                            {STROLLERS_NOT_APPROVED}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        }

                        {experience.title &&
                            <h1
                                className={cx(
                                    styles.Experience__wrapper__experienceTitle,
                                    showCompact && styles['Experience__wrapper__experienceTitle--compact'],
                                )}
                            >
                                <Emojify>
                                    {upperCaseFirst(experience.title)}
                                </Emojify>
                            </h1>
                        }

                        <ExperienceBody
                            experience={experience}
                            showCompact={showCompact}
                        />
                    </div>

                    {!showCompact &&
                        <ExperienceFooter
                            experience={experience}
                            isAdmin={isAdmin}
                            onDeleteVendorReply={onDeleteVendorReply}
                        />
                    }

                    {experience.article.canVendorReplyOnExperiences && !experience.vendorReplyMessage && (
                        <div className={styles.Experience__vendorReplyForm}>
                            <VendorReplyOnExperienceForm
                                experienceId={experience.id}
                                onSave={onSaveVendorReply}
                            />
                        </div>
                    )}
                </div>
            </>
        );
    }
}
