import { ACTIVE_SERVER_ID } from 'mk/settings';

// keep in sync with python code in apps/users/utils.py
export const MODRYKONIK_USERNAME = {
    201: 'modrykonik',
    202: 'modrykonik',
}[ACTIVE_SERVER_ID];

// keep in sync with python code in apps/users/utils.py
export const PR_ARTICLES_USER = {
    201: 'pr_clanok',
    202: 'pr_clanek',
}[ACTIVE_SERVER_ID];

// keep in sync with python code in apps/users/utils.py
export const MAGAZINE_USER = {
    201: 'redakcia',
    202: 'redakce',
}[ACTIVE_SERVER_ID];

// keep in sync with python code in apps/users/utils.py
export const MK_TESTING_USERNAME = {
    201: 'konik_testuje',
    202: 'konik_testuje',
}[ACTIVE_SERVER_ID];

// pouziva sa v blogoch na pristup k privatnym postom bez loginu
export const AUTH_KEY_NAME = 'key';

export const GROUP_FEED_READ_TIMEOUT = 2 * 1000;

export enum CommentsDesign {
    Normal,      // blog feed + groups
    Legacy,      // django profile
}
