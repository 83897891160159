import { reactFilter, TextLike } from 'mk/bazaar/common/filters/utils';
import { Link } from 'mk2/components/Link';
import React from 'react';

const HASHIZE_RE = /(?:\s|^)#([a-zA-Z0-9.][a-zA-Z0-9._]+)/;

export const hashize = (text: TextLike, urlFn: (hash: string) => string) => reactFilter(text, (s: string): React.ReactChild[] => {
    const ret = [];
    const matcher = new RegExp(HASHIZE_RE, 'g');
    let from = 0;
    while (true) {
        // calling matcher repeatadly moves it through the whole string 's'
        const match = matcher.exec(s);
        if (match) {
            const hashText = match[1].toLowerCase();
            const to = matcher.lastIndex - hashText.length - 1;

            // copy text before current mention (empty string are removed by reactFilter)
            ret.push(s.substring(from, to));

            ret.push(<Link to={urlFn(hashText)}>#{hashText}</Link>);

            from = matcher.lastIndex;
        } else {
            // copy text after last mention
            ret.push(s.substring(from));
            break;
        }
    }

    return ret;
});
