import {
    ArticleFetchFailureAction,
    ArticleFetchRequestAction,
    ArticleFetchSuccessAction,
    ArticleLoadMoreReviewCategoryArticlesFailureAction,
    ArticleLoadMoreReviewCategoryArticlesRequestAction,
    ArticleLoadMoreReviewCategoryArticlesSuccessAction,
    ARTICLE_FETCH_FAILURE,
    ARTICLE_FETCH_REQUEST,
    ARTICLE_FETCH_SUCCESS,
    ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_FAILURE,
    ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_REQUEST,
    ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_SUCCESS,
} from 'mk2/apps/wiki/containers/Article/Article.actions';
import {
    ArticleAddExperienceSuccessAction,
    ARTICLE_ADD_EXPERIENCE_SUCCESS,
} from 'mk2/apps/wiki/containers/ArticleAddExperience/ArticleAddExperience.actions';
import { tupdate } from 'mk2/helpers/types';
import { errorMessage } from 'mk2/reducers/errorMessage';
import { loadingState, LoadingState } from 'mk2/reducers/loadingState';
import { ArticleConceptPath } from 'mk2/schemas';
import { Reducer } from 'redux';

export interface ReviewCategoriesStats {
    [key: string]: {
        productsCount: number;
        experiencesCount: number;
    };
}

export const initialArticleState: ArticleState = {
    articleId: null,
    viewsCountUnique: null,
    viewsCountTotal: null,
    relatedArticles: [],
    loadingState: LoadingState.INIT,
    errorMessage: null,
    moreReviewCategoryArticlesLoadingState: LoadingState.INIT,
    moreReviewCategoryArticlesErrorMessage: null,
    articleConceptPath: [],
    reviewCategoryArticlesCount: null,
    reviewCategoryArticlesIds: null,
    reviewCategoryArticlesExperiencesIds: null,
    reviewCategoriesIds: null,
    reviewCategoriesStats: null,
    similarPostIds: null,
    eshopProductsOfEshopNodes: null,
};

export interface ArticleState {
    articleId: number | null;
    viewsCountUnique: number;
    viewsCountTotal: number;
    relatedArticles: number[] | [];
    loadingState: LoadingState;
    errorMessage: string;
    moreReviewCategoryArticlesLoadingState: LoadingState;
    moreReviewCategoryArticlesErrorMessage: string;
    articleConceptPath: ArticleConceptPath[];
    reviewCategoryArticlesCount: number;
    reviewCategoryArticlesIds: number[];
    reviewCategoryArticlesExperiencesIds: number[];
    reviewCategoriesIds: number[];
    reviewCategoriesStats: ReviewCategoriesStats;
    similarPostIds: number[];
    eshopProductsOfEshopNodes: Record<string, number[]>;
}

export interface ArticlesState {
    [key: string]: ArticleState;
}

export const initialArticlesState: ArticlesState = {};

declare type ArticlesReducerAction =
    | ArticleFetchRequestAction
    | ArticleFetchSuccessAction
    | ArticleFetchFailureAction
    | ArticleLoadMoreReviewCategoryArticlesRequestAction
    | ArticleLoadMoreReviewCategoryArticlesSuccessAction
    | ArticleLoadMoreReviewCategoryArticlesFailureAction
    | ArticleAddExperienceSuccessAction;

export const articlesReducer: Reducer<ArticlesState> = (
    state = initialArticlesState,
    action: ArticlesReducerAction,
) => {
    const key = `${action.categorySlug}/${action.articleSlug}`;
    const articleState = state[key] ?? initialArticleState;
    switch (action.type) {
        case ARTICLE_FETCH_REQUEST:
        case ARTICLE_FETCH_FAILURE: {
            return tupdate(state, {
                [key]: tupdate(articleState, {
                    loadingState: loadingState(articleState.loadingState, action),
                    errorMessage: errorMessage(articleState.errorMessage, action),
                }),
            });
        }
        case ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_REQUEST:
        case ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_FAILURE: {
            return tupdate(state, {
                [key]: tupdate(articleState, {
                    moreReviewCategoryArticlesLoadingState: loadingState(articleState.moreReviewCategoryArticlesLoadingState, action),
                    moreReviewCategoryArticlesErrorMessage: errorMessage(articleState.moreReviewCategoryArticlesErrorMessage, action),
                }),
            });
        }

        case ARTICLE_FETCH_SUCCESS: {
            return tupdate(state, {
                [key]: tupdate(articleState, {
                    articleId: action.response.result.article,
                    viewsCountUnique: action.viewsCountUnique,
                    viewsCountTotal: action.viewsCountTotal,
                    relatedArticles: action.response.result.relatedArticles,
                    loadingState: loadingState(articleState.loadingState, action),
                    errorMessage: errorMessage(articleState.errorMessage, action),
                    articleConceptPath: action.articleConceptPath,
                    reviewCategoryArticlesCount: action.response.result.reviewCategoryArticlesCount,
                    reviewCategoryArticlesIds: action.response.result.reviewCategoryArticles,
                    reviewCategoryArticlesExperiencesIds: action.response.result.reviewCategoryArticlesExperiences,
                    reviewCategoriesIds: action.response.result.reviewCategories,
                    reviewCategoriesStats: action.response.result.reviewCategoriesStats,
                    similarPostIds: action.response.result.similarPosts,
                    eshopProductsOfEshopNodes: action.response.result.eshopProductsOfEshopNodes,
                }),
            });
        }

        case ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_SUCCESS: {
            return tupdate(state, {
                [key]: tupdate(articleState, {
                    reviewCategoryArticlesIds: [
                        ...state[key].reviewCategoryArticlesIds,
                        ...action.response.result.moreReviewCategoryArticles,
                    ],
                    moreReviewCategoryArticlesLoadingState: loadingState(articleState.moreReviewCategoryArticlesLoadingState, action),
                    moreReviewCategoryArticlesErrorMessage: errorMessage(articleState.moreReviewCategoryArticlesErrorMessage, action),
                }),
            });
        }

        case ARTICLE_ADD_EXPERIENCE_SUCCESS: {
            return tupdate(state, {
                [key]: tupdate(articleState, {
                    loadingState: LoadingState.INIT,
                }),
            });
        }

        default:
            return state;
    }
};

export default articlesReducer;
