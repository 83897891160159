import {
    DONE,
    WIKI_ARTICLE_AMBASSADOR_BRAND_CLEAR_CONFIRM,
    WIKI_ARTICLE_AMBASSADOR_BRAND_SET_CONFIRM,
} from 'mk/autogenerated/translations/Article.sagas.78809bf4a12dd21e79d1ce62848e8d32'
import {
    wiki_api_v2_article_opened_url,
    wiki_api_v2_delete_venor_reply_on_experience_url,
    wiki_api_v2_get_article_url,
    wiki_api_v2_load_more_of_review_category_url,
    wiki_api_v2_save_venor_reply_on_experience_url,
    wiki_api_v2_set_ambassador_brand_url,
} from 'mk/urls/functions';
import {
    articleDeleteVendorReplyOnExperienceApi,
    articleFetchApi,
    articleLoadMoreReviewCategoryArticlesApi,
    articleSaveVendorReplyOnExperienceApi,
    articleSetAmbassadorBrandApi,
    ArticleDeleteVendorReplyOnExperienceTriggerAction,
    ArticleFetchNormalizedResponse,
    ArticleFetchTriggerAction,
    ArticleLoadMoreReviewCategoryArticlesNormalizedResponse,
    ArticleLoadMoreReviewCategoryArticlesTriggerAction,
    ArticleModifyVendorReplyOnExperienceNormalizedResponse,
    ArticlePageViewTriggerAction,
    ArticleSaveVendorReplyOnExperienceTriggerAction,
    ArticleSetAmbassadorBrandNormalizedResponse,
    ArticleSetAmbassadorBrandTriggerAction,
    ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_TRIGGER,
    ARTICLE_FETCH_TRIGGER,
    ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_TRIGGER,
    ARTICLE_PAGE_VIEW,
    ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_TRIGGER,
    ARTICLE_SET_AMBASSADOR_BRAND_TRIGGER,
} from 'mk2/apps/wiki/containers/Article/Article.actions';
import { ReviewCategoriesStats } from 'mk2/apps/wiki/containers/Article/Article.reducers';
import {
    handleXHRGetErrorSaga, handleXHRPostErrorSaga,
    normalizeError,
    XHRAction,
    XHRGetError, XHRPostError,
} from 'mk2/helpers/api';
import { showSuccessToast } from 'mk2/helpers/toasts';
import { getLogger } from 'mk2/logger';
import {
    ArticleConceptPath,
    ArticleLinkDataEntity,
    ArticleLinkDataSchema,
} from 'mk2/schemas';
import { normalize } from 'normalizr';
import { all, call, put, takeLatest } from 'redux-saga/effects';

const logger = getLogger('wiki/Article.sagas');

interface ApiResponseFetchArticle {
    body: {
        entities: ArticleFetchNormalizedResponse['entities'];
        result: ArticleFetchNormalizedResponse['result'];
        viewsCountUnique: number;
        viewsCountTotal: number;
        articleConceptPath: ArticleConceptPath[];
        relatedArticles: ArticleLinkDataEntity[];
        reviewCategoryArticlesCount: number;
        reviewCategoriesStats: ReviewCategoriesStats;
    };
}

function* fetchArticle({ articleSlug, categorySlug, xhr }: ArticleFetchTriggerAction & XHRAction) {
    yield put(articleFetchApi.request(articleSlug, categorySlug));

    try {
        const response: ApiResponseFetchArticle = yield call(
            () => xhr.get(wiki_api_v2_get_article_url(), { articleSlug, categorySlug }),
        );

        // TODO: Denormalize related items on server
        const normalizedResponse: ArticleFetchNormalizedResponse = normalize({
            relatedArticles: response.body.relatedArticles,
            reviewCategoryArticlesCount: response.body.reviewCategoryArticlesCount,
            reviewCategoriesStats: response.body.reviewCategoriesStats,
        }, {
            relatedArticles: [ArticleLinkDataSchema],
        });

        // TODO: Remove when related items will be normalized on server
        const mergedResponse = {
            // Deep merge
            entities: Object.keys(response.body.entities).reduce((acc, cur) => {
                acc[cur] = {
                    ...acc[cur],
                    ...response.body.entities[cur],
                };
                return acc;
            }, normalizedResponse.entities),
            result: {
                ...normalizedResponse.result,
                ...response.body.result,
            },
        };

        yield put(articleFetchApi.success(
            articleSlug,
            categorySlug,
            response.body.articleConceptPath,
            response.body.viewsCountUnique,
            response.body.viewsCountTotal,
            mergedResponse,
        ));
    } catch (error) {
        yield handleXHRGetErrorSaga(error as XHRGetError, logger);
        yield put(articleFetchApi.failure(articleSlug, categorySlug, normalizeError(error)));
    }
}

interface ApiResponseLoadMoreReviewCategoryArticles {
    body: {
        entities: ArticleLoadMoreReviewCategoryArticlesNormalizedResponse['entities'],
        result: ArticleLoadMoreReviewCategoryArticlesNormalizedResponse['result'],
    };
}

function* loadMoreReviewCategoryArticles({ articleSlug, categorySlug, after, xhr }: ArticleLoadMoreReviewCategoryArticlesTriggerAction & XHRAction) {
    yield put(articleLoadMoreReviewCategoryArticlesApi.request(articleSlug, categorySlug, after));

    try {
        const response: ApiResponseLoadMoreReviewCategoryArticles = yield call(
            () => xhr.get(wiki_api_v2_load_more_of_review_category_url(), { articleSlug, categorySlug, after }),
        );

        yield put(articleLoadMoreReviewCategoryArticlesApi.success(
            articleSlug,
            categorySlug,
            after,
            {
                entities: response.body.entities,
                result: response.body.result,
            },
        ));
    } catch (error) {
        yield handleXHRGetErrorSaga(error as XHRGetError, logger);
        yield put(articleLoadMoreReviewCategoryArticlesApi.failure(articleSlug, categorySlug, after, normalizeError(error)));
    }
}

function* pageView({ categorySlug, articleSlug, xhr }: ArticlePageViewTriggerAction & XHRAction) {
    // TODO merge pageview reporting of wiki/blog articles to an isolated new component
    try {
        // making call only in browsers, cases without defined window are disallowed
        if (typeof window !== 'undefined') {
            yield call(() => xhr.post(wiki_api_v2_article_opened_url(), {
                categorySlug,
                articleSlug,
            }));
        }
    } catch (error) {
        logger.error(error);
    }
}

interface ApiResponseModifyVendorReplyOnExperience {
    body: {
        entities: ArticleModifyVendorReplyOnExperienceNormalizedResponse['entities'],
        result: ArticleModifyVendorReplyOnExperienceNormalizedResponse['result'],
    };
}

function* saveVendorReply({ experienceId, values, xhr }: ArticleSaveVendorReplyOnExperienceTriggerAction & XHRAction) {
    yield put(articleSaveVendorReplyOnExperienceApi.request(experienceId, values));

    try {
        const response: ApiResponseModifyVendorReplyOnExperience = yield call(
            () => xhr.post(wiki_api_v2_save_venor_reply_on_experience_url(experienceId), { values }),
        );

        yield put(articleSaveVendorReplyOnExperienceApi.success(
            experienceId,
            values,
            {
                entities: response.body.entities,
                result: response.body.result,
            },
        ));
    } catch (error) {
        yield handleXHRGetErrorSaga(error as XHRGetError, logger);
        yield put(articleSaveVendorReplyOnExperienceApi.failure(experienceId, values, normalizeError(error)));
    }
}

function* deleteVendorReply({ experienceId, xhr }: ArticleDeleteVendorReplyOnExperienceTriggerAction & XHRAction) {
    yield put(articleDeleteVendorReplyOnExperienceApi.request(experienceId));

    try {
        const response: ApiResponseModifyVendorReplyOnExperience = yield call(
            () => xhr.post(wiki_api_v2_delete_venor_reply_on_experience_url(experienceId)),
        );

        yield put(articleDeleteVendorReplyOnExperienceApi.success(
            experienceId,
            {
                entities: response.body.entities,
                result: response.body.result,
            },
        ));
    } catch (error) {
        yield handleXHRGetErrorSaga(error as XHRGetError, logger);
        yield put(articleDeleteVendorReplyOnExperienceApi.failure(experienceId, normalizeError(error)));
    }
}

interface ApiResponseSetOrClearAmbassadorBrand {
    body: {
        entities: ArticleSetAmbassadorBrandNormalizedResponse['entities'],
        result: ArticleSetAmbassadorBrandNormalizedResponse['result'],
    };
}

function* setOrClearAmbassadorBrand({ articleId, setAmbassadorBrand, xhr }: ArticleSetAmbassadorBrandTriggerAction & XHRAction) {
    const confirmation = window.confirm(setAmbassadorBrand
        ? WIKI_ARTICLE_AMBASSADOR_BRAND_SET_CONFIRM
        : WIKI_ARTICLE_AMBASSADOR_BRAND_CLEAR_CONFIRM,
    );

    if (!confirmation) {
        // User didn't confirm the action
        return;
    }

    yield put(articleSetAmbassadorBrandApi.request(articleId, setAmbassadorBrand));

    try {
        const response: ApiResponseSetOrClearAmbassadorBrand = yield call(
            () => xhr.post(wiki_api_v2_set_ambassador_brand_url(articleId), { setAmbassadorBrand }),
        );

        yield put(articleSetAmbassadorBrandApi.success(
            articleId, setAmbassadorBrand,
            {
                entities: response.body.entities,
                result: response.body.result,
            },
        ));
        yield showSuccessToast(DONE);
    } catch (error) {
        yield handleXHRPostErrorSaga(error as XHRPostError, logger);
        yield put(articleSetAmbassadorBrandApi.failure(articleId, setAmbassadorBrand, normalizeError(error)));
    }


}
export default function* root() {
    yield all([
        takeLatest(ARTICLE_FETCH_TRIGGER, fetchArticle),
        takeLatest(ARTICLE_LOAD_MORE_REVIEW_CATEGORY_ARTICLES_TRIGGER, loadMoreReviewCategoryArticles),
        takeLatest(ARTICLE_PAGE_VIEW, pageView),
        takeLatest(ARTICLE_SAVE_VENDOR_REPLY_ON_EXPERIENCE_TRIGGER, saveVendorReply),
        takeLatest(ARTICLE_DELETE_VENDOR_REPLY_ON_EXPERIENCE_TRIGGER, deleteVendorReply),
        takeLatest(ARTICLE_SET_AMBASSADOR_BRAND_TRIGGER, setOrClearAmbassadorBrand),
    ]);
}
