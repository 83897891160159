import { faExclamationTriangle, faSync } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { LOAD_ERROR_MESSAGE, LOAD_ERROR_RETRY } from 'mk/autogenerated/translations/LoadError.6c02654805582d21de8ad9c99948207c'
import { Btn, BtnType } from 'mk2/components/Btn';
import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './LoadError.mscss';

interface OwnProps {
    className?: string;
    message?: string;
    changeTitle?: boolean;
    onRetry();
}

export const LoadError: React.StatelessComponent<OwnProps> = ({ onRetry, message, changeTitle, className }) => {
    const title = message || LOAD_ERROR_MESSAGE;

    return (
        <div
            className={cx(styles.LoadError, className)}
        >
            { changeTitle && (
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
            )}
            <div className={styles.LoadError__icon}>
                <FontAwesomeIcon icon={faExclamationTriangle} />
            </div>
            <div className={styles.LoadError__message}>
                {title}
            </div>
            <div className={styles.LoadError__button}>
                <Btn
                    type={BtnType.Blue}
                    label={LOAD_ERROR_RETRY}
                    icon={faSync}
                    onClick={onRetry}
                />
            </div>
        </div>
    );
};

LoadError.defaultProps = {
    changeTitle: true,
};
