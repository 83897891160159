// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const BACK_TO_HOMEPAGE = "Zp\u011bt na hlavn\u00ed str\u00e1nku";
export const PREGNANCY_NEWSLETTER = "T\u011bhotensk\u00fd newsletter";
export const PREGNANCY_NEWSLETTER_DESCRIPTION = "Ka\u017ed\u00fd t\u00fdden dostane\u0161 nejd\u016fle\u017eit\u011bj\u0161\u00ed info do e-mailu";
export const RELATED_DISCUSSIONS_IN_FORUM = "Souvisej\u00edc\u00ed diskuze ve f\u00f3ru";
export const WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_ANY_REVIEWABLE = "M\u00e1\u0161 zku\u0161enost produktem, slu\u017ebou nebo m\u00edstem?";
export const WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_title = "M\u00e1\u0161 zku\u0161enost %(title)s?";
export const WIKI_EXP_AVERAGE_SCORE = "Pr\u016fm\u011brn\u00e9 hodnocen\u00ed:";
export const WIKI_EXPERIENCES_WITH_title = "Hodnocen\u00ed a zku\u0161enosti %(title)s";
export const WIKI_SHARE_YOUR_EXPERIENCE_AND_HELP_OTHERS = "Pod\u011bl se o ni a pomoz tak ostatn\u00edm mamink\u00e1m.";
export const WIKI_WHERE_NEXT = "Kam d\u00e1l?";
export const WIKI_WRITE_YOUR_EXPERIENCE = "Napi\u0161 svou zku\u0161enost";
