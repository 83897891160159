import cx from 'classnames';
import { Checkbox } from 'mk2/components/forms/Checkbox';
import { FormControl } from 'mk2/components/forms/FormControl';
import React from 'react';
import { Field, WrappedFieldInputProps, WrappedFieldProps } from 'redux-form';
import styles from './CheckboxField.mscss';

interface CheckboxInputPublicProps {
    className?: string;
    label: React.ReactNode;
    bottomBorder?: boolean;
    disabled?: boolean;
}

type CheckboxInputProps = CheckboxInputPublicProps & {
    input: WrappedFieldInputProps;
};

const CheckboxInput: React.FC<CheckboxInputProps> = ({ input, label, disabled, className }) => (
    <Checkbox className={cx(styles.CheckboxInput, className)} label={label} disabled={disabled} {...input} checked={input.value} />
);

CheckboxInput.displayName = 'CheckboxInput';

interface CheckboxControlPublicProps {
    marginLeft?: boolean;
    isAdminUI?: boolean;
}

type CheckboxControlProps = CheckboxInputPublicProps & CheckboxControlPublicProps & WrappedFieldProps;

const CheckboxControl: React.FC<CheckboxControlProps> = ({
    input,
    label,
    meta, // WrappedFieldProps
    marginLeft,
    bottomBorder = true,
    isAdminUI, // CheckboxControlPublicProps
    ...chkInputProps // CheckboxInputPublicProps
}) => {
    // Never display checkbox form group as active
    const newMeta = {
        ...meta,
        active: false,
    };

    return (
        <FormControl
            marginLeft={marginLeft}
            label={null /* label sa zobrazi vedla checkboxu, nie nad inputom ako je defaultne */}
            input={input}
            meta={newMeta}
            bottomBorder={bottomBorder}
            isAdminUI={isAdminUI}
            disabled={chkInputProps.disabled}
        >
            <CheckboxInput label={label || input.name} input={input} {...chkInputProps} />
        </FormControl>
    );
};

type CheckboxFieldProps = CheckboxInputPublicProps &
    CheckboxControlPublicProps & {
        // see https://redux-form.com/7.3.0/docs/api/field.md/#props-you-can-pass-to-code-field-code-
        name: string;
    };

export const CheckboxField: React.FC<CheckboxFieldProps> = (props) => <Field {...props} component={CheckboxControl} />;
