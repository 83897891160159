import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { LocationDescriptor } from 'history';
import {
    counter_RATINGS,
    PRODUCT_BOX_WRITE_REVIEW, STROLLER_BOX_ESHOP_BUTTON_LABEL, WIKI_WRITE_YOUR_FIRST_EXPERIENCE,
} from 'mk/autogenerated/translations/StrollerBox.c008791522d031c5d7d47cf46a292da5'
import { strollersReviewsUrl } from 'mk2/apps/strollers/urls';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Dot } from 'mk2/components/Dot';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { Panel } from 'mk2/components/Panel';
import { StarsRating } from 'mk2/components/StarsRating';
import { url } from 'mk2/helpers/urls';
import { interpolate } from 'mk2/services/i18n';
import React from 'react';
import styles from './StrollerBox.mscss';

interface OwnProps {
    className?: string;
    style?: React.CSSProperties;
    image: React.ReactNode;
    title: React.ReactNode;
    reviewsCount: number;
    score: number;
    scoreOf?: number;
    strollerSlug?: string;

    productsTo?: LocationDescriptor;
    isEshopProduct?: boolean;
    addReviewTo?: LocationDescriptor;
}

type Props = OwnProps;

export const StrollerBox: React.FunctionComponent<Props> = React.memo(({
    className,
    style,
    image,
    title,
    strollerSlug,
    reviewsCount,
    score,
    scoreOf = 5,
    productsTo,
    isEshopProduct,
    addReviewTo,
}) => {
    score = score ?? 0;
    return (
        <Panel className={cx(styles.StrollerBox, className)} style={style}>
            <div className={styles.StrollerBox__image}>{image}</div>
            <div className={styles.StrollerBox__content}>
                <div className={styles.StrollerBox__content__title}>{title}</div>
                <div className={styles.StrollerBox__content__score}>
                    <StarsRating
                        starsCount={
                            // <StarsRating> is using 5 stars
                            // so recalculate score if scoreOf is not 5
                            scoreOf !== 5 ? (score / scoreOf) * 5 : score
                        }
                        className={styles.StrollerBox__content__score__stars}
                        canShowHalf
                    />
                    <Dot spaces={1} className={styles.StrollerBox__content__score__dot} />
                    <span className={styles.StrollerBox__content__score__reviews}>
                       {strollerSlug ? (
                            <Link to={url(strollersReviewsUrl, { slug: strollerSlug }) }>
                                {interpolate(counter_RATINGS, { counter: reviewsCount, count: reviewsCount })}{' '}
                                <FontAwesomeIcon icon={faComment} />
                            </Link>
                       ) : (
                            <>
                                {interpolate(counter_RATINGS, { counter: reviewsCount, count: reviewsCount })}{' '}
                                <FontAwesomeIcon icon={faComment} />
                            </>
                       )}
                    </span>
                </div>
                <div className={styles.StrollerBox__content__review}>
                        <Link to={addReviewTo}>
                            {reviewsCount > 0 ? PRODUCT_BOX_WRITE_REVIEW : WIKI_WRITE_YOUR_FIRST_EXPERIENCE}
                        </Link>
                    </div>
                {isEshopProduct && (
                    <div className={styles.StrollerBox__content__button}>
                        <Btn
                            className={styles.StrollerBox__content__button__button}
                            link={productsTo}
                            type={BtnType.Blue}
                            label={STROLLER_BOX_ESHOP_BUTTON_LABEL}
                        />
                    </div>
                )}
            </div>
        </Panel>
    );
});

StrollerBox.displayName = 'StrollerBox';

interface PlaceholderOwnProps {
    className?: string;
    style?: React.CSSProperties;
}

export const StrollerBoxPlaceholder: React.FunctionComponent<PlaceholderOwnProps> = ({ className, style }) => (
    <Panel className={cx(styles.StrollerBox, className)} style={style}>
        <div className={styles.StrollerBox__image}>
            <LoadingPlaceholder width={64} height={64} />
        </div>
        <div className={styles.StrollerBox__content}>
            <div className={styles.StrollerBox__content__title}>
                <LoadingPlaceholder width={122} />
            </div>
            <div className={styles.StrollerBox__content__score}>
                <LoadingPlaceholder width={250} height={19} />
            </div>
        </div>
    </Panel>
);

StrollerBoxPlaceholder.displayName = 'StrollerBoxPlaceholder';
