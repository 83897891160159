import { FormValues } from 'mk2/containers/PregnancyCalculator/PregnancyCalculatorForm';
import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { Action } from 'redux';


// Load

export interface PregnancyCalculatorLoadApiAction extends Action {
}

export const PREGNANCY_CALCULATOR_LOAD_API_REQUEST = 'PREGNANCY_CALCULATOR_LOAD_API_REQUEST';
export interface PregnancyCalculatorLoadApiRequestAction extends PregnancyCalculatorLoadApiAction, APIRequestAction {
    readonly type: typeof PREGNANCY_CALCULATOR_LOAD_API_REQUEST;
}

export interface PregnancyCalculatorLoadNormalizedResponse extends NormalizedResponse {
    readonly result: {
        csvContent: number;
    };
}

export const PREGNANCY_CALCULATOR_LOAD_API_SUCCESS = 'PREGNANCY_CALCULATOR_LOAD_API_SUCCESS';
export interface PregnancyCalculatorLoadApiSuccessAction extends PregnancyCalculatorLoadApiAction, APISuccessAction<PregnancyCalculatorLoadNormalizedResponse> {
    readonly type: typeof PREGNANCY_CALCULATOR_LOAD_API_SUCCESS;
}

export const PREGNANCY_CALCULATOR_LOAD_API_FAILURE = 'PREGNANCY_CALCULATOR_LOAD_API_FAILURE';

export interface PregnancyCalculatorLoadApiFailureAction extends PregnancyCalculatorLoadApiAction, APIFailureAction {
    readonly type: typeof PREGNANCY_CALCULATOR_LOAD_API_FAILURE;
}

export const pregnancyCalculatorLoadApi = {
    request: (): PregnancyCalculatorLoadApiRequestAction => ({
        type: PREGNANCY_CALCULATOR_LOAD_API_REQUEST,
    }),
    success: (response: PregnancyCalculatorLoadNormalizedResponse): PregnancyCalculatorLoadApiSuccessAction => ({
        type: PREGNANCY_CALCULATOR_LOAD_API_SUCCESS,
        response,
    }),
    failure: (error: any): PregnancyCalculatorLoadApiFailureAction => ({
        type: PREGNANCY_CALCULATOR_LOAD_API_FAILURE,
        error,
    }),
};

export const PREGNANCY_CALCULATOR_LOAD = 'PREGNANCY_CALCULATOR_LOAD';

export interface PregnancyCalculatorLoadAction extends PregnancyCalculatorLoadApiAction {
    readonly type: typeof PREGNANCY_CALCULATOR_LOAD;
}

export const pregnancyCalculatorLoad = (): PregnancyCalculatorLoadAction => ({
    type: PREGNANCY_CALCULATOR_LOAD,
});


// Submit

export interface PregnancyCalculatorSubmitApiAction extends Action {
    readonly values: FormValues;
}

export const PREGNANCY_CALCULATOR_SUBMIT_API_REQUEST = 'PREGNANCY_CALCULATOR_SUBMIT_API_REQUEST';

export interface PregnancyCalculatorSubmitApiRequestAction extends PregnancyCalculatorSubmitApiAction, APIRequestAction {
    readonly type: typeof PREGNANCY_CALCULATOR_SUBMIT_API_REQUEST;
}

export const PREGNANCY_CALCULATOR_SUBMIT_API_SUCCESS = 'PREGNANCY_CALCULATOR_SUBMIT_API_SUCCESS';

export interface PregnancyCalculatorSubmitNormalizedResponse extends NormalizedResponse {
    readonly result: {
        group: number;
        groupMember: number;
        nextWeek: number;
    };
}

export interface PregnancyCalculatorSubmitApiSuccessAction extends APISuccessAction<PregnancyCalculatorSubmitNormalizedResponse>, PregnancyCalculatorSubmitApiAction {
    readonly type: typeof PREGNANCY_CALCULATOR_SUBMIT_API_SUCCESS;
    readonly week: number;
    readonly day: number;
    readonly dueDate: string;
}

export const PREGNANCY_CALCULATOR_SUBMIT_API_FAILURE = 'PREGNANCY_CALCULATOR_SUBMIT_API_FAILURE';

export interface PregnancyCalculatorSubmitApiFailureAction extends PregnancyCalculatorSubmitApiAction, APIFailureAction {
    readonly type: typeof PREGNANCY_CALCULATOR_SUBMIT_API_FAILURE;
}

export const pregnancyCalculatorSubmitApi = {
    request: (values: FormValues): PregnancyCalculatorSubmitApiRequestAction => ({
        type: PREGNANCY_CALCULATOR_SUBMIT_API_REQUEST,
        values,
    }),
    success: (response: PregnancyCalculatorSubmitNormalizedResponse, values: FormValues, week: number, day: number, dueDate: string): PregnancyCalculatorSubmitApiSuccessAction => ({
        type: PREGNANCY_CALCULATOR_SUBMIT_API_SUCCESS,
        response,
        values,
        week,
        day,
        dueDate,
    }),
    failure: (values: FormValues, error: any): PregnancyCalculatorSubmitApiFailureAction => ({
        type: PREGNANCY_CALCULATOR_SUBMIT_API_FAILURE,
        values,
        error,
    }),
};

export const PREGNANCY_CALCULATOR_SUBMIT = 'PREGNANCY_CALCULATOR_SUBMIT';

export interface PregnancyCalculatorSubmitAction extends PregnancyCalculatorSubmitApiAction {
    readonly type: typeof PREGNANCY_CALCULATOR_SUBMIT;
}

export const pregnancyCalculatorSubmit = (values: FormValues): PregnancyCalculatorSubmitAction => ({
    type: PREGNANCY_CALCULATOR_SUBMIT,
    values,
});
